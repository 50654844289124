import React from "react";
import PropTypes from "prop-types";
import ScreenTitle from "../ScreenTitle";

const Header = ({ title, isLoading, onRefresh, onAdd }) => (
  <header className="ui dividing header">
    <div className="ui two column grid">
      <ScreenTitle title={title} className="column" />
      <div className="right floated right aligned column">
        {!!onRefresh && (
          <button
            id="refresh"
            className={"ui button" + (isLoading ? " loading" : "")}
            onClick={onRefresh}
          >
            Refresh
          </button>
        )}
        {!!onAdd && (
          <button id="add" className="ui primary button" onClick={onAdd}>
            Add
          </button>
        )}
      </div>
    </div>
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onRefresh: PropTypes.func,
  onAdd: PropTypes.func
};

export default Header;
