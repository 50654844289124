import {
  GET_PASSWORD_RESET_REQUEST,
  GET_PASSWORD_RESET_SUCCESS,
  GET_PASSWORD_RESET_FAILURE,
  CLEAR_PASSWORD_RESET
} from "../actions/recovery";

const recovery = (state = { loading: false, passwordReset: null }, action) => {
  switch (action.type) {
    case GET_PASSWORD_RESET_REQUEST:
      return { ...state, loading: true };
    case GET_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordReset: action.response.data
      };
    case GET_PASSWORD_RESET_FAILURE:
      return { ...state, loading: false };
    case CLEAR_PASSWORD_RESET:
      return {
        ...state,
        passwordReset: null
      };
    default:
      return state;
  }
};

export default recovery;
