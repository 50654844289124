import config from "../config";

export const tsToAge = timestamp => {
  const currentTs = Date.now(),
    diff = Math.floor((currentTs - timestamp) / 1000),
    totalMinutes = diff / 60;
  let className = "",
    alert = false;
  if (diff <= 0) {
    return { age: "00:00", className, alert };
  }

  let hours = Math.floor(diff / 3600),
    minutes = Math.floor((diff % 3600) / 60);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  if (totalMinutes >= config.alertAge) {
    className = "negative";
    alert = true;
  } else if (totalMinutes >= config.redAge) {
    className = "negative";
  } else if (totalMinutes >= config.orangeAge) {
    className = "warning";
  } else if (totalMinutes >= config.yellowAge) {
    className = "";
  } else {
    className = "positive";
  }
  return { value: hours + ":" + minutes, className, alert };
};

export const utcToLocal = timeStr => {
  if ("string" !== typeof timeStr) {
    console.error("Invalid time string:", timeStr);
    return "- Invalid -";
  }
  let timestamp;
  if (timeStr.indexOf("Z") !== -1) {
    timestamp = Date.parse(timeStr);
  } else {
    timestamp = Date.parse(timeStr + " UTC");
  }
  if (isNaN(timestamp)) {
    return timeStr;
  }
  const date = new Date(timestamp);
  return (
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2) +
    " " +
    ("00" + date.getHours()).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    ":" +
    ("00" + date.getSeconds()).slice(-2)
  );
};

// desktop notification
export const notifyUser = (
  title,
  body,
  requireInteraction = false,
  onClose
) => {
  if (
    "undefined" !== typeof Notification &&
    "granted" === Notification.permission
  ) {
    console.info("Notification:", title, body, requireInteraction);
    const notice = new Notification(title, { body, requireInteraction });
    if (onClose) {
      notice.onclose = onClose;
    }
  }
};

// formatted agent info
export const agentInfo = agent => {
  let info = "";
  if (agent) {
    info += agent.firstName;
    if (agent.lastName) {
      if (info) {
        info += " ";
      }
      info += agent.lastName;
    }
    if (info) {
      info += ", ";
    }
    info += agent.email;
  }
  return info;
};

export const agentName = agent => {
  let name = "";
  if (agent) {
    if (agent.firstName) {
      name += agent.firstName;
    }
    if (agent.lastName) {
      if (name) {
        name += " ";
      }
      name += agent.lastName;
    }
    if (!name && agent.email) {
      name = agent.email;
    }
  }
  return name;
};

export const roundTo = (value, decimals) => {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};

// Check correct time format and split into components
export const timeConvert = time => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};
