import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as validations from "../../lib/validations";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem
} from "../../actions";

class Form extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired
  };

  model = "blacklist";

  componentWillMount = () => {
    const { resetStatusMessage, formResetError } = this.props;
    resetStatusMessage();
    formResetError();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, createItem } = this.props,
      ip = this.ipField.value.trim(),
      description = this.descriptionField.value.trim();
    let hasError = false;
    formResetError();
    if (!validations.publicIpv4(ip)) {
      formSetError("ip");
      hasError = true;
    }
    if (!hasError) {
      const data = { ip };
      if (description) {
        data.description = description;
      }
      createItem(this.model, data);
    }
    return false;
  };

  reset = () => {
    this.ipField.value = "";
    this.descriptionField.value = "";
  };

  render = () => {
    const { form, onCancel } = this.props;
    return (
      <div className="ui">
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.ip && <li>Enter a valid IP</li>}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div
            className={"required field" + (form.errorFields.ip ? " error" : "")}
          >
            <label htmlFor="pslt">IP address</label>
            <input
              type="text"
              tabIndex="10"
              name="ip"
              id="ip"
              autoComplete="off"
              placeholder="IPv4 address"
              ref={input => (this.ipField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              tabIndex="20"
              name="description"
              id="description"
              autoComplete="off"
              ref={input => (this.descriptionField = input)}
            />
          </div>
          <button
            id="cancelBlacklist"
            tabIndex="210"
            className="ui negative button"
            type="button"
            onClick={onCancel ? onCancel : this.reset}
          >
            Cancel
          </button>
          <button tabIndex="200" className="ui primary button" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    resetStatusMessage,
    formSetError,
    formResetError,
    createItem
  }
)(Form);
