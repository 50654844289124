export const getTermPercent = billingCycle => {
  let termPercent = 0;
  switch (billingCycle) {
    case 3:
      termPercent = 2;
      break;
    case 6:
      termPercent = 5;
      break;
    case 12:
      termPercent = 10;
      break;
    default:
      termPercent = 0;
      break;
  }
  return termPercent;
};
