import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isCouponApplicable from "./couponCheck";

const Coupon = ({ applyCoupon, license }) => {
  const { coupons, servers, billingCycle, current } = license,
    appliedCoupon = license.appliedCoupon,
    appliedCouponId = appliedCoupon ? appliedCoupon._id : "";
  if (!coupons || !coupons.length) {
    return null;
  }
  const availableCoupons = coupons.filter(c =>
    isCouponApplicable(c, servers, billingCycle, current ? "update" : "new")
  );
  if (!availableCoupons.length) {
    return null;
  }
  return (
    <table className="ui celled collapsing table">
      <thead>
        <tr>
          <th colSpan="4">Available coupons</th>
        </tr>
      </thead>
      <tbody>
        {availableCoupons.map(c => (
          <tr key={c._id}>
            <td>{c.description}</td>
            <td>{c.recurring ? "Recurring" : "One-time"}</td>
            <td>
              {c.percentage
                ? `${c.percentage} %`
                : c.amount
                  ? `$${c.amount} USD`
                  : "Invalid"}
            </td>
            <td>
              {appliedCouponId !== c._id && (
                <button
                  className="positive mini ui button"
                  onClick={e => {
                    e.preventDefault();
                    applyCoupon(c);
                  }}
                >
                  Apply
                </button>
              )}
              {appliedCouponId === c._id && (
                <button
                  className="negative mini ui button"
                  onClick={e => {
                    e.preventDefault();
                    applyCoupon(null);
                  }}
                >
                  Revoke
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Coupon.propTypes = {
  applyCoupon: PropTypes.func.isRequired,
  // Injected by React-Redux
  license: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  license: state.license
});

export default connect(mapStateToProps)(Coupon);
