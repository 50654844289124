import {
  FORM_RESET_ALL,
  FORM_SET_ERROR,
  FORM_RESET_ERROR,
  FORM_UNSET_ERROR,
  FORM_SET_DATA,
  FORM_RESET_DATA,
  FORM_UNSET_DATA,
  FORM_SET_INFO,
  FORM_TOGGLE_INFO,
  FORM_RESET_INFO,
  FORM_SET_PASSWORD_STRENGTH,
  FORM_RESET_PASSWORD_STRENGTH,
  FORM_CAPTCHA_SOLVED,
} from "../../actions/ui/form";

// Form state
const form = (
  state = {
    hasError: false,
    captcha: false,
    errorFields: {},
    dataFields: {},
    infoFields: {},
    passwordStrength: { score: -1 },
  },
  action
) => {
  const { type, field, message, value, score, strength } = action;
  switch (type) {
    case FORM_RESET_ALL:
      return {
        hasError: false,
        captcha: false,
        errorFields: {},
        dataFields: {},
        infoFields: {},
        passwordStrength: { score: -1 },
      };
    case FORM_TOGGLE_INFO:
      return {
        ...state,
        infoFields: {
          ...state.infoFields,
          [field]: !state.infoFields[field],
        },
      };
    case FORM_SET_INFO:
      return {
        ...state,
        infoFields: {
          ...state.infoFields,
          [field]: value,
        },
      };
    case FORM_RESET_INFO:
      return { ...state, infoFields: {} };
    case FORM_SET_ERROR:
      return {
        ...state,
        hasError: true,
        errorFields: {
          ...state.errorFields,
          [field]: message ? message : true,
        },
      };
    case FORM_RESET_ERROR:
      return { ...state, hasError: false, errorFields: {} };
    case FORM_UNSET_ERROR:
      const { [field]: omit, ...otherFields } = state.errorFields;
      return {
        ...state,
        hasError: !!Object.keys(otherFields).length,
        errorFields: otherFields,
      };
    case FORM_SET_DATA:
      return {
        ...state,
        dataFields: {
          ...state.dataFields,
          [field]: value,
        },
      };
    case FORM_RESET_DATA:
      return { ...state, dataFields: {} };
    case FORM_UNSET_DATA:
      const { [field]: omitField, ...otherDataFields } = state.dataFields;
      return {
        ...state,
        dataFields: otherDataFields,
      };
    case FORM_CAPTCHA_SOLVED:
      return { ...state, captcha: true };
    case FORM_SET_PASSWORD_STRENGTH:
      return {
        ...state,
        passwordStrength: {
          score,
          strength,
        },
      };
    case FORM_RESET_PASSWORD_STRENGTH:
      return {
        ...state,
        passwordStrength: { score: -1 },
      };
    default:
      return state;
  }
};

export default form;
