import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { roundTo } from "../../lib/utils";

const Invoice = ({ invoice, credit }) => (
  <table className="ui celled collapsing table">
    <thead>
      <tr>
        <th>ID</th>
        <th>{invoice._id}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Created</td>
        <td>{moment(invoice.created).format("YYYY-MM-DD hh:mm A")}</td>
      </tr>
      <tr>
        <td>Due date</td>
        <td>{moment(invoice.dueDate).format("MMM D, YYYY")}</td>
      </tr>
      {!!invoice.paymentDate && (
        <tr>
          <td>Payment date</td>
          <td>{moment(invoice.paymentDate).format("MMM D, YYYY")}</td>
        </tr>
      )}
      <tr>
        <td>
          <strong>Amount</strong>
        </td>
        <td>
          <strong>${invoice.amount} USD</strong>
        </td>
      </tr>
      {!!credit && (
        <tr>
          <td>Credit balance</td>
          <td>${credit} USD</td>
        </tr>
      )}
      {!!credit && (
        <tr>
          <td>
            <strong>Payment</strong>
          </td>
          <td>
            <strong>
              $
              {invoice.amount <= credit
                ? 0
                : roundTo(invoice.amount - credit, 2)}{" "}
              USD
            </strong>
          </td>
        </tr>
      )}
      <tr>
        <td>Status</td>
        <td className={"paid" === invoice.status ? "positive" : "negative"}>
          {invoice.status}
        </td>
      </tr>
    </tbody>
  </table>
);

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  credit: PropTypes.number
};

export default Invoice;
