import { CALL_SOCKET_API } from "../middleware/agent-api";

export const STRIPE_PAY_OPTS_READ_REQUEST = "STRIPE_PAY_OPTS_READ_REQUEST";
export const STRIPE_PAY_OPTS_READ_SUCCESS = "STRIPE_PAY_OPTS_READ_SUCCESS";
export const STRIPE_PAY_OPTS_READ_FAILURE = "STRIPE_PAY_OPTS_READ_FAILURE";
export const readStripePaymentOption = () => ({
  [CALL_SOCKET_API]: {
    types: [
      STRIPE_PAY_OPTS_READ_REQUEST,
      STRIPE_PAY_OPTS_READ_SUCCESS,
      STRIPE_PAY_OPTS_READ_FAILURE
    ],
    endpoint: "readStripePaymentOption",
    nonBlocking: true
  }
});

export const STRIPE_ADD_CARD_REQUEST = "STRIPE_ADD_CARD_REQUEST";
export const STRIPE_ADD_CARD_SUCCESS = "STRIPE_ADD_CARD_SUCCESS";
export const STRIPE_ADD_CARD_FAILURE = "STRIPE_ADD_CARD_FAILURE";
export const createStripePaymentOption = data => ({
  [CALL_SOCKET_API]: {
    types: [
      STRIPE_ADD_CARD_REQUEST,
      STRIPE_ADD_CARD_SUCCESS,
      STRIPE_ADD_CARD_FAILURE
    ],
    endpoint: "createStripePaymentOption",
    data
  }
});

export const RESET_NEW_CARD = "RESET_NEW_CARD";
export const resetNewCard = () => ({
  type: RESET_NEW_CARD
});

export const CARD_DELETE = "CARD_DELETE";
export const CARD_DELETE_CANCEL = "CARD_DELETE_CANCEL";
export const CARD_DELETE_REQUEST = "CARD_DELETE_REQUEST";
export const CARD_DELETE_SUCCESS = "CARD_DELETE_SUCCESS";
export const CARD_DELETE_FAILURE = "CARD_DELETE_FAILURE";
export const confirmDeleteCard = id => ({
  type: CARD_DELETE,
  id
});
export const cancelDeleteCard = () => ({
  type: CARD_DELETE_CANCEL
});
export const deleteCard = id => ({
  [CALL_SOCKET_API]: {
    types: [CARD_DELETE_REQUEST, CARD_DELETE_SUCCESS, CARD_DELETE_FAILURE],
    endpoint: "deleteStripePaymentOption",
    data: { document: { _id: id } }
  }
});

export const PROCESS_INVOICE_PAYMENT_REQUEST =
  "PROCESS_INVOICE_PAYMENT_REQUEST";
export const PROCESS_INVOICE_PAYMENT_SUCCESS =
  "PROCESS_INVOICE_PAYMENT_SUCCESS";
export const PROCESS_INVOICE_PAYMENT_FAILURE =
  "PROCESS_INVOICE_PAYMENT_FAILURE";
export const processInvoicePayment = data => ({
  [CALL_SOCKET_API]: {
    types: [
      PROCESS_INVOICE_PAYMENT_REQUEST,
      PROCESS_INVOICE_PAYMENT_SUCCESS,
      PROCESS_INVOICE_PAYMENT_FAILURE
    ],
    endpoint: "processInvoicePayment",
    data,
    successMessage: "Payment processed"
  }
});

export const PROCESS_CREDIT_PAYMENT_REQUEST = "PROCESS_CREDIT_PAYMENT_REQUEST";
export const PROCESS_CREDIT_PAYMENT_SUCCESS = "PROCESS_CREDIT_PAYMENT_SUCCESS";
export const PROCESS_CREDIT_PAYMENT_FAILURE = "PROCESS_CREDIT_PAYMENT_FAILURE";
export const processCreditPayment = data => ({
  [CALL_SOCKET_API]: {
    types: [
      PROCESS_CREDIT_PAYMENT_REQUEST,
      PROCESS_CREDIT_PAYMENT_SUCCESS,
      PROCESS_CREDIT_PAYMENT_FAILURE
    ],
    endpoint: "processCreditPayment",
    data,
    successMessage: "Added"
  }
});

export const PROCESS_REFUND_REQUEST = "PROCESS_REFUND_REQUEST";
export const PROCESS_REFUND_SUCCESS = "PROCESS_REFUND_SUCCESS";
export const PROCESS_REFUND_FAILURE = "PROCESS_REFUND_FAILURE";
export const processRefund = data => ({
  [CALL_SOCKET_API]: {
    types: [
      PROCESS_REFUND_REQUEST,
      PROCESS_REFUND_SUCCESS,
      PROCESS_REFUND_FAILURE
    ],
    endpoint: "processRefund",
    data,
    successMessage: "Refunded"
  }
});
