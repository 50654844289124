// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setStatusMessage } from "../../actions/ui/statusMessage";
import { getPasswordReset, clearPasswordReset } from "../../actions/recovery";
import { copyToClipboard } from "../../lib/browser";
import { ucFirst } from "../../lib/strings";
import SimpleHeader from "../../components/SimpleHeader";

class Recovery extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    recovery: PropTypes.object.isRequired,
    getPasswordReset: PropTypes.func.isRequired,
    clearPasswordReset: PropTypes.func.isRequired,
    setStatusMessage: PropTypes.func.isRequired,
  };

  componentWillMount = () => {
    const { history, match, clearPasswordReset } = this.props;
    if (!match.params.id) {
      history.push("/license");
    }
    clearPasswordReset();
  };

  copyToClipboard = (elemId) => {
    const result = copyToClipboard(elemId);
    if (result) {
      this.props.setStatusMessage(
        ucFirst(elemId) + " copied to clipboard",
        "success",
        5
      );
    } else {
      this.props.setStatusMessage(
        "Copy not supported or blocked. Press select and ctrl+c to copy",
        "error",
        5
      );
    }
  };

  render() {
    const { history, recovery, match, getPasswordReset } = this.props;
    return (
      <article className="thirteen wide column">
        <SimpleHeader title="Recovery" />
        <div className="ui">
          {!recovery.passwordReset && (
            <div className="ui info message">
              Click the <strong>Generate</strong> button to generate a command
              which can be executed on the gateway server to reset the password
              of the super admin user (the user created during Ezeelogin
              install).
            </div>
          )}
          {!!recovery.passwordReset && (
            <div className="ui segment">
              <p>
                Command:{" "}
                <strong id="command">
                  php /usr/local/ezlogin/ez_queryrunner.php 'UPDATE prefix_users
                  SET status=1, priv=0, password='\''
                  {recovery.passwordReset.newPasswordHash}'\'' WHERE id=1'
                </strong>
              </p>
              <p>
                Your new password will be:{" "}
                <strong id="password">
                  {recovery.passwordReset.newPassword}
                </strong>
              </p>
              <button
                id="copyCommand"
                className="ui button"
                type="button"
                onClick={() => this.copyToClipboard("command")}
              >
                Copy the command to clipboard
              </button>
              <button
                id="copyPassword"
                className="ui button"
                type="button"
                onClick={() => this.copyToClipboard("password")}
              >
                Copy the password to clipboard
              </button>
              <div className="ui info message">
                Note: After resetting the password, login to the Ezeelogin web
                panel and reset the password immediately to that of your choice.
                You may want to reset the security code as well to gain access
                to the backend if that is also lost and two-factor
                authentication has not been setup.
              </div>
            </div>
          )}
          <button
            id="return"
            className="ui button"
            type="button"
            onClick={() => history.push("/license")}
          >
            Return to license listing
          </button>
          {!recovery.passwordReset && (
            <button
              id="generate"
              className={
                "ui primary button" + (recovery.loading ? " loading" : "")
              }
              onClick={() => getPasswordReset(match.params.id)}
            >
              Generate
            </button>
          )}
        </div>
      </article>
    );
  }
}

const mapStateToProps = (state) => ({
  recovery: state.recovery,
});

export default withRouter(
  connect(mapStateToProps, {
    getPasswordReset,
    clearPasswordReset,
    setStatusMessage,
  })(Recovery)
);
