import { combineReducers } from "redux";
import loading from "./loading";
import statusMessage from "./statusMessage";
import searchBar from "./searchBar";
import form from "./form";
import progress from "./progress";
import autocomplete from "./autocomplete";
import avatarInput from "./avatarInput";
import login from "./login";
import tab from "./tab";

const uiReducer = combineReducers({
  loading,
  statusMessage,
  searchBar,
  form,
  progress,
  autocomplete,
  avatarInput,
  login,
  tab
});

export default uiReducer;
