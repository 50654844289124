import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import {
  formSetError,
  formResetError,
  formSetData,
  formResetAll,
  loginAgent,
  captchaSolved
} from "../../actions";
import * as validations from "../../lib/validations";
import config from "../../config";

class LoginForm extends Component {
  static propTypes = {
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetData: PropTypes.func.isRequired,
    formResetAll: PropTypes.func.isRequired,
    loginAgent: PropTypes.func.isRequired,
    captchaSolved: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { formResetAll } = this.props;
    formResetAll();
  };

  onCaptchaChange = response => {
    const { captchaSolved } = this.props;
    this.captcha = response;
    captchaSolved();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, loginAgent } = this.props,
      email = this.emailField.value.trim(),
      password = this.passwordField.value;
    let hasError = false;
    formResetError();
    if (config.reCaptchaSiteKey && !this.captcha) {
      formSetError("captcha");
      hasError = true;
    }
    if (!validations.email(email)) {
      formSetError("email");
      hasError = true;
    }
    if (!password) {
      formSetError("password");
      hasError = true;
    }
    if (!hasError) {
      const data = { email, password };
      if (this.captcha) {
        data.recaptchaResponse = this.captcha;
      }
      loginAgent(data);
    }
    return false;
  };

  render = () => {
    const { form } = this.props,
      submitEnabled = !config.reCaptchaSiteKey || form.captcha;
    return (
      <form className="ui form" onSubmit={this.submit}>
        <div
          className={
            "required field" + (form.errorFields.email ? " error" : "")
          }
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            tabIndex="10"
            name="email"
            id="email"
            placeholder="Email address"
            autoFocus={true}
            required={true}
            ref={input => (this.emailField = input)}
          />
        </div>
        <div
          className={
            "required field" + (form.errorFields.password ? " error" : "")
          }
        >
          <label htmlFor="password">Password</label>
          <input
            type="password"
            tabIndex="20"
            name="password"
            id="password"
            placeholder="Password"
            required={true}
            ref={input => (this.passwordField = input)}
          />
        </div>
        {config.reCaptchaSiteKey && (
          <ReCAPTCHA
            ref="recaptcha"
            sitekey={config.reCaptchaSiteKey}
            onChange={this.onCaptchaChange}
          />
        )}
        <button
          tabIndex="100"
          className={
            submitEnabled ? "ui primary button" : "ui primary disabled button"
          }
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    formSetError,
    formResetError,
    formSetData,
    formResetAll,
    loginAgent,
    captchaSolved
  }
)(LoginForm);
