import { TAB_SWITCH } from "../../actions/ui/tab";

// Updates UI tab state.
const tab = (state = "", action) => {
  switch (action.type) {
    case TAB_SWITCH:
      return action.tab;
    default:
      return state;
  }
};

export default tab;
