// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel, confirmDeleteItem } from "../../actions/listing";
import Listing from "../Listing";

class DndListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired,
    confirmDeleteItem: PropTypes.func.isRequired
  };

  model = "dnd";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, confirmDeleteItem } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        email: { title: "Email", sortable: true },
        requestIp: { title: "Request IP" },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true
        },
        actions: { title: "Actions", type: "actions" }
      },
      actions = [
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: id => {
            confirmDeleteItem(id);
          }
        }
      ];
    return (
      <Listing
        title="Do not disturb"
        model={this.model}
        mainField="email"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ email: 1 }}
        // search form component
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Email"
        getSimpleSearch={search => ({
          email: search
        })}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      setItemModel,
      confirmDeleteItem
    }
  )(DndListing)
);
