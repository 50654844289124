// cSpell:ignore normalizr
import { normalize } from "normalizr";
import * as schema from "../middleware/schema";
import {
  STRIPE_PAY_OPTS_READ_REQUEST,
  STRIPE_PAY_OPTS_READ_SUCCESS,
  STRIPE_ADD_CARD_REQUEST,
  STRIPE_ADD_CARD_SUCCESS,
  RESET_NEW_CARD,
  CARD_DELETE,
  CARD_DELETE_CANCEL,
  CARD_DELETE_SUCCESS,
} from "../actions";

const payment = (
  state = {
    loading: false,
    tab: "invoice",
    byId: {},
    cards: [],
    delete: null,
    newCard: null,
  },
  action
) => {
  switch (action.type) {
    case STRIPE_PAY_OPTS_READ_REQUEST:
    case STRIPE_ADD_CARD_REQUEST:
      return { ...state, loading: true };
    case STRIPE_PAY_OPTS_READ_SUCCESS:
      const normalizedData = normalize(action.response.data, schema.cards);
      return {
        ...state,
        loading: false,
        byId: normalizedData.entities.cards,
        cards: action.response.data,
      };
    case STRIPE_ADD_CARD_SUCCESS:
      const card = action.response.data;
      return {
        ...state,
        loading: false,
        newCard: card,
        byId: { ...state.byId, [card._id]: card },
        cards: [...state.cards, card],
      };
    case RESET_NEW_CARD:
      return { ...state, newCard: null };
    case CARD_DELETE:
      return {
        ...state,
        delete: action.id,
      };
    case CARD_DELETE_CANCEL:
      return {
        ...state,
        delete: null,
      };
    case CARD_DELETE_SUCCESS:
      const id = action.response.data._id,
        { [id]: omit, ...rest } = state.byId;
      return {
        ...state,
        byId: rest,
        cards: state.cards.filter((c) => c._id !== id),
        delete: null,
      };
    default:
      return state;
  }
};

export default payment;

export const getById = (state, id) =>
  state.byId && state.byId[id] ? state.byId[id] : null;
