import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class OrderSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const id = this.idField.value.trim(),
      ip = this.ipField.value.trim(),
      secondaryIp = this.secondaryIpField.value.trim(),
      servers = parseInt(this.serversField.value.trim(), 10),
      status = this.statusField.value,
      params = {};
    if (id) {
      params._id = id;
    }
    if (ip) {
      params.ip = ip;
    }
    if (secondaryIp) {
      params.secondaryIp = secondaryIp;
    }
    if (servers) {
      params.servers = servers;
    }
    if (status) {
      params.status = status;
    }
    if (this.customer) {
      params.customer = this.customer;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.idField.value = "";
    this.ipField.value = "";
    this.secondaryIpField.value = "";
    this.serversField.value = "";
    this.statusField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  render = () => {
    const { search, userGroup, getSuggestedItem } = this.props,
      isStaff = "admin" === userGroup || "staff" === userGroup;
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className={isStaff ? "six fields" : "five fields"}>
          <div className="field">
            <label htmlFor="id">ID</label>
            <input
              type="text"
              tabIndex="10"
              name="id"
              id="id"
              placeholder="Exact ID"
              defaultValue={search._id}
              ref={input => (this.idField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="ip">IP</label>
            <input
              type="text"
              tabIndex="20"
              name="ip"
              id="ip"
              placeholder="IP Address"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.ip}
              ref={input => (this.ipField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="secondaryIp">Secondary IP</label>
            <input
              type="text"
              tabIndex="30"
              name="secondaryIp"
              id="secondaryIp"
              placeholder="IP Address"
              autoComplete="off"
              defaultValue={search.secondaryIp}
              ref={input => (this.secondaryIpField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="servers">Servers</label>
            <input
              type="number"
              tabIndex="40"
              name="servers"
              id="servers"
              placeholder="Number of servers"
              autoComplete="off"
              defaultValue={search.servers}
              ref={input => (this.serversField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="50"
              defaultValue={search.status}
              ref={input => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["active", "pending", "archived"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          {isStaff && (
            <div className="field">
              <AutocompleteInput
                title="Customer"
                count={3}
                tabIndex={60}
                getSuggestedItem={id => {
                  getSuggestedItem("customer", id, { _id: 1, company: 1 });
                }}
                searchItem={this.autocomplete}
                required={true}
                getName={customer => customer.company}
                selectItem={id => {
                  this.customer = id;
                }}
              />
            </div>
          )}
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

const mapStateToProps = state => ({
  userGroup: state.auth.user.group
});

export default connect(
  mapStateToProps,
  {
    autocomplete,
    getSuggestedItem
  }
)(OrderSearch);
