import {
  SHOW_PROGRESS,
  HIDE_PROGRESS,
  UPDATE_PROGRESS
} from "../../actions/ui/progress";

// Updates progress indicator.
const progress = (
  state = {
    show: false,
    label: "",
    percent: 0
  },
  action
) => {
  const { type, percent, label } = action;

  switch (type) {
    case SHOW_PROGRESS:
      return {
        ...state,
        show: true,
        label,
        percent: percent || 0
      };
    case HIDE_PROGRESS:
      return { ...state, show: false };
    case UPDATE_PROGRESS:
      // update progress only if the progress is displayed
      if (state.show) {
        return {
          ...state,
          percent
        };
      }
      return state;
    default:
      return state;
  }
};

export default progress;
