// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  hasPageData,
  getCurrentPageData,
  getById
} from "../../reducers/listing";
import {
  readItem,
  deleteItem,
  searchItem,
  sortItem,
  setItemPage,
  resetItemPage,
  setItemPerPage,
  confirmDeleteItem,
  cancelDeleteItem
} from "../../actions/listing";
import List from "../../components/List";
import Header from "../../components/Header";
import Confirmation from "../../components/Confirmation";
import SearchBar from "../SearchBar";

class Listing extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    simpleSearchTitle: PropTypes.string,
    getSimpleSearch: PropTypes.func,
    mainField: PropTypes.string,
    initialSearch: PropTypes.object,
    // list config
    fields: PropTypes.object.isRequired,
    actions: PropTypes.array,
    highlightFields: PropTypes.object,
    highlightTextPath: PropTypes.object,
    defaultSort: PropTypes.object,
    // search form component
    ItemSearch: PropTypes.func,
    // Injected by React-Redux
    listing: PropTypes.shape({
      loading: PropTypes.bool,
      perPage: PropTypes.number.isRequired,
      page: PropTypes.number.isRequired,
      total: PropTypes.number.isRequired,
      byId: PropTypes.object.isRequired,
      pageIds: PropTypes.object.isRequired,
      delete: PropTypes.string,
      search: PropTypes.object.isRequired,
      sortBy: PropTypes.object.isRequired
    }).isRequired,
    readItem: PropTypes.func.isRequired,
    searchItem: PropTypes.func.isRequired,
    sortItem: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    confirmDeleteItem: PropTypes.func.isRequired,
    cancelDeleteItem: PropTypes.func.isRequired,
    setItemPage: PropTypes.func.isRequired,
    resetItemPage: PropTypes.func.isRequired,
    setItemPerPage: PropTypes.func.isRequired
  };

  metaFields = { serial: 1, actions: 1 };

  componentWillMount = () => {
    // empty search and default sort
    const {
      initialSearch,
      defaultSort,
      searchItem,
      sortItem,
      resetItemPage
    } = this.props,
      search = initialSearch ? initialSearch : {},
      sortBy = defaultSort ? defaultSort : {};
    resetItemPage();
    searchItem(search);
    sortItem(sortBy);
    this.refresh(search, sortBy);
  };

  componentWillUnmount = () => {
    this.props.cancelDeleteItem();
  };

  getFields = () =>
    Object.keys(this.props.fields).filter(field => !this.metaFields[ field ]);

  refresh = (search, sortBy, perPage) => {
    const { match, model, listing, readItem, setItemPage } = this.props,
      fields = this.getFields(),
      limit = perPage ? perPage : listing.perPage;
    let page = 1;
    if (match.params.page) {
      const urlPage = parseInt(match.params.page, 10);
      if (urlPage) {
        page = urlPage;
      }
    }
    setItemPage(page);
    readItem(model, {
      search: search ? search : listing.search,
      sortBy: sortBy ? sortBy : listing.sortBy,
      getTotal: 1,
      skip: (page - 1) * limit,
      limit,
      fields
    });
  };

  search = search => {
    this.props.searchItem(search);
    this.refresh(search);
  };

  sortBy = sortBy => {
    this.props.sortItem(sortBy);
    this.refresh(null, sortBy);
  };

  changePerPage = perPage => {
    this.props.setItemPerPage(perPage);
    this.refresh(null, null, perPage);
  };

  setPage = page => {
    const { history, model, listing, readItem, setItemPage } = this.props,
      perPage = listing.perPage,
      fields = this.getFields();
    if (!hasPageData(listing, page)) {
      // if the page data is not already available, send a read request
      readItem(model, {
        search: listing.search,
        sortBy: listing.sortBy,
        skip: (page - 1) * perPage,
        limit: perPage,
        fields
      });
    }
    setItemPage(page);
    history.push("/" + history.location.pathname.split("/", 2)[ 1 ] + "/" + page);
  };

  render() {
    const {
      title,
      simpleSearchTitle,
      model,
      onAdd,
      mainField,
      getSimpleSearch,
      fields,
      actions,
      listing,
      highlightFields,
      highlightTextPath,
      ItemSearch,
      deleteItem,
      cancelDeleteItem
    } = this.props,
      itemDelete = listing.delete,
      listFields = { ...fields };
    // Remove the hidden fields from listing
    for (const field in fields) {
      if (fields[ field ].hidden) {
        delete listFields[ field ];
      }
    }
    return (
      <article className="thirteen wide column">
        <Header
          title={title}
          isLoading={listing.loading}
          onRefresh={() => {
            this.refresh();
          }}
          onAdd={onAdd}
        />
        <div className="ui">
          {getSimpleSearch && (
            <SearchBar
              mainField={mainField}
              title={simpleSearchTitle}
              search={listing.search}
              SearchForm={ItemSearch}
              perPage={listing.perPage}
              setPerPage={this.changePerPage}
              onSearch={search => {
                this.search(search ? getSimpleSearch(search) : {});
              }}
              onAdvancedSearch={this.search}
            />
          )}
          {null !== itemDelete && (
            <Confirmation
              message={
                "Are you sure you want to delete the item " +
                (mainField ? getById(listing, itemDelete)[ mainField ] : "")
              }
              onNo={cancelDeleteItem}
              onYes={() => deleteItem(model, itemDelete)}
            />
          )}
          <List
            fields={listFields}
            records={getCurrentPageData(listing)}
            actions={actions}
            page={listing.page}
            perPage={listing.perPage}
            total={listing.total}
            setPage={this.setPage}
            highlightFields={highlightFields}
            highlightTextPath={highlightTextPath}
            highlightText={listing.search}
            sortField={listing.sortBy}
            onSort={this.sortBy}
          />
        </div>
      </article>
    );
  }
}

const mapStateToProps = state => ({
  listing: state.listing
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      readItem,
      searchItem,
      sortItem,
      deleteItem,
      confirmDeleteItem,
      cancelDeleteItem,
      setItemPage,
      resetItemPage,
      setItemPerPage
    }
  )(Listing)
);
