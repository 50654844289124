import { SHOW_LOADING, HIDE_LOADING } from "../../actions/ui/loading";

// Updates UI loading state.
const loading = (state = false, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return true;
    case HIDE_LOADING:
      return false;
    default:
      return state;
  }
};

export default loading;
