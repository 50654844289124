import { CALL_SOCKET_API } from "../middleware/agent-api";

export const INVOICE_READ_REQUEST = "INVOICE_READ_REQUEST";
export const INVOICE_READ_SUCCESS = "INVOICE_READ_SUCCESS";
export const INVOICE_READ_FAILURE = "INVOICE_READ_FAILURE";
export const readInvoice = (data = {}) => ({
  [CALL_SOCKET_API]: {
    types: [INVOICE_READ_REQUEST, INVOICE_READ_SUCCESS, INVOICE_READ_FAILURE],
    endpoint: "readInvoice",
    data
  }
});

export const INVOICE_SELECT_CARD = "INVOICE_SELECT_CARD";
export const selectCard = id => ({
  type: INVOICE_SELECT_CARD,
  id
});

export const INVOICE_SET_CARD_SAVE = "INVOICE_SET_CARD_SAVE";
export const setCardSave = status => ({
  type: INVOICE_SET_CARD_SAVE,
  status
});

export const INVOICE_DOWNLOAD_REQUEST = "INVOICE_DOWNLOAD_REQUEST";
export const INVOICE_DOWNLOAD_SUCCESS = "INVOICE_DOWNLOAD_SUCCESS";
export const INVOICE_DOWNLOAD_FAILURE = "INVOICE_DOWNLOAD_FAILURE";
export const downloadPdfInvoice = id => ({
  [CALL_SOCKET_API]: {
    types: [
      INVOICE_DOWNLOAD_REQUEST,
      INVOICE_DOWNLOAD_SUCCESS,
      INVOICE_DOWNLOAD_FAILURE
    ],
    endpoint: "downloadPdfInvoice",
    nonBlocking: true,
    data: { _id: id }
  }
});

export const INVOICE_SHOW_REFUND = "INVOICE_SHOW_REFUND";
export const showRefund = status => ({
  type: INVOICE_SHOW_REFUND,
  status
});
