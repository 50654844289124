import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ProgressBar extends Component {
  static propTypes = {
    cancel: PropTypes.func,
    // Injected by React-Redux
    progress: PropTypes.shape({
      show: PropTypes.bool.isRequired,
      percent: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired
    }).isRequired
  };

  renderLabel = (label, cancel) => {
    if ("" === label && "undefined" === typeof cancel) {
      return null;
    }
    return (
      <div className="label">
        {label}{" "}
        {cancel &&
          <button
            type="button"
            className="negative mini ui button"
            onClick={cancel}
          >
            Cancel
          </button>}
      </div>
    );
  };

  render() {
    const { cancel, progress } = this.props;
    const { show, percent, label } = progress;
    if (!show) {
      return null;
    }
    const percentStr = percent.toString(10) + "%";
    const style = { transitionDuration: "100ms", width: percentStr };
    return (
      <div className="ui indicating progress" data-percent={percent}>
        <div className="bar" style={style}>
          <div className="progress">
            {percentStr}
          </div>
        </div>
        {this.renderLabel(label, cancel)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  progress: state.ui.progress
});

export default connect(mapStateToProps)(ProgressBar);
