import io from "socket.io-client";
import history from "../history";
import config from "../config";
import { notifyUser, agentInfo } from "../lib/utils";
import fileDownload from "../lib/fileDownload";
import {
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGOUT,
  ITEM_CREATE_SUCCESS,
  ITEM_UPDATE_SUCCESS,
  ITEM_DELETE_SUCCESS,
  REGISTER_CUSTOMER_SUCCESS,
  CUSTOMER_TERMINATE_SUCCESS,
  showLoading,
  hideLoading,
  setStatusMessage,
  resetStatusMessage,
  logoutAgent,
  agentConnected,
  agentDisconnected,
} from "../actions";
import redirection from "./redirection";

const API_ROOT = config.apiUrl || "/",
  socketRequestTimers = {},
  socketRequestPromises = {},
  apiSequences = {};
let agentSocket = null,
  socketConnected = false,
  socketInitialConnected = false,
  agentSocketApiQueuedRequest = null;

const logout = () => {
  if (agentSocket) {
    agentSocket.off();
    agentSocket.close();
    agentSocket = null;
    socketConnected = false;
    socketInitialConnected = false;
  }
};

// Fetches an HTTP API response.
const callHttpApi = (endpoint, data) => {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  return fetch(fullUrl, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_HTTP_API = "Call HttpAPI";

// A Redux middleware that interprets actions with CALL_HTTP_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callHttpAPI = action[CALL_HTTP_API];
  if (typeof callHttpAPI === "undefined") {
    return next(action);
  }

  let { endpoint, data } = callHttpAPI;
  const { types } = callHttpAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_HTTP_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  return callHttpApi(endpoint, data).then(
    (response) => {
      if (
        (AGENT_LOGIN_SUCCESS === successType ||
          REGISTER_CUSTOMER_SUCCESS === successType) &&
        response.ok
      ) {
        // Open agent socket for Socket API
        connectSocket(response.agent, store);
      }
      const nextAction = { response, type: successType };
      if (response.error) {
        nextAction.error = response.error;
      } else {
        nextAction.resetStatusMessage = true;
      }
      next(actionWith(nextAction));
    },
    (error) => {
      next(
        actionWith({
          type: failureType,
          error: error.message || "Something bad happened",
        })
      );
    }
  );
};

const connectSocket = (user, store) => {
  agentSocket = io(API_ROOT + "agent-api", {
    query: {
      email: user.email,
      token: user.token,
    },
  });
  attachEvents(agentSocket, store);
};

// Call a SOCKET API action.
const callSocketApi = (action, data) => {
  if (apiSequences[action]) {
    apiSequences[action]++;
  } else {
    apiSequences[action] = 1;
  }
  if (!socketRequestPromises[action]) {
    socketRequestPromises[action] = {};
  }
  return new Promise((resolve, reject) => {
    const sequence = apiSequences[action];
    if (socketRequestTimers[action]) {
      const timer = socketRequestTimers[action],
        promise = socketRequestPromises[action][timer.sequence];
      // Subsequent request, clear previous timer and reject it's promise as stale
      clearTimeout(timer.timeout);
      if (promise) {
        delete socketRequestPromises[action][timer.sequence];
        promise.reject({
          type: "stale",
          sequence: timer.sequence,
          message: "Newer request sent",
        });
      }
    }
    socketRequestTimers[action] = {
      sequence,
      timeout: setTimeout(() => {
        delete socketRequestTimers[action];
        delete socketRequestPromises[action][sequence];
        if (apiSequences[action] > sequence) {
          console.warn(`${action} old request timed out: ${sequence}`);
          reject({ type: "stale", sequence, message: "Old request timed out" });
        } else {
          console.warn(`${action} request timed out: ${sequence}`);
          reject({ message: `${action} request timed out` });
        }
      }, config.socketRequestTimeout),
    };
    socketRequestPromises[action][sequence] = { resolve, reject };
    if (agentSocket) {
      agentSocket.emit("request", { action, sequence, data }, (ackData) => {
        console.info("SOCKET ACK:", ackData);
      });
    } else {
      console.error("No socket connection");
    }
  });
};

const handleResponse = (myId, response, store) => {
  const { id, action, sequence, data } = response;
  if (id === myId) {
    // response to my request
    const timer = socketRequestTimers[action],
      promise = socketRequestPromises[action][sequence];
    if (timer) {
      clearTimeout(timer.timeout);
      delete socketRequestTimers[action];
    }
    delete socketRequestPromises[action][sequence];
    if (promise) {
      if (sequence === apiSequences[action]) {
        promise.resolve(data);
      } else {
        // stale response
        promise.reject({ type: "stale", sequence, message: "Stale response" });
      }
    } else {
      console.error("INVALID PROMISE", myId, response);
    }
  } else {
    // broadcasted message
    console.log("Broadcast message:", action, data);
    let type = "";
    switch (action) {
      case "createAgent":
        type = ITEM_CREATE_SUCCESS;
        if ("admin" === store.getState().auth.user.group) {
          notifyUser(
            "New agent added",
            agentInfo(data.by) + " added: " + agentInfo(data.data)
          );
        }
        break;
      case "updateAgent":
        type = ITEM_UPDATE_SUCCESS;
        if ("admin" === store.getState().auth.user.group) {
          notifyUser(
            "Agent updated",
            agentInfo(data.by) + " updated: " + agentInfo(data.data)
          );
        }
        break;
      case "deleteAgent":
        type = ITEM_DELETE_SUCCESS;
        if ("admin" === store.getState().auth.user.group) {
          notifyUser(
            "Agent deleted",
            agentInfo(data.by) + " deleted: " + agentInfo(data.data)
          );
        }
        break;
      default:
        break;
    }
    if ("" !== type) {
      store.dispatch({ type, response: data });
    } else {
      console.warn("Unknown message from server");
    }
  }
};

const attachEvents = (socket, store) => {
  // STANDARD EVENTS
  socket.on("connect", () => {
    console.log("AS CONNECTED:", socket.id);
    socketConnected = true;
    socketInitialConnected = true;
    if (agentSocketApiQueuedRequest) {
      console.info("Dispatch queued request:", agentSocketApiQueuedRequest);
      store.dispatch(agentSocketApiQueuedRequest);
      agentSocketApiQueuedRequest = null;
    }
    store.dispatch(resetStatusMessage());
  });
  socket.on("connect_error", (error) => {
    console.error("AS CONNECT FAILED:", error);
    store.dispatch(
      setStatusMessage("Connection failed", "error", 0, error.message)
    );
  });
  socket.on("connect_timeout", (timeout) => {
    console.error("AS CONNECTION TIMED OUT:", timeout);
    store.dispatch(setStatusMessage("Connection timed out", "error"));
  });
  socket.on("error", (error) => {
    console.error("AS ERROR:", error);
    if ("Unauthorized" === error) {
      store.dispatch(logoutAgent());
    }
    store.dispatch(setStatusMessage(error, "error"));
  });
  socket.on("disconnect", (reason) => {
    console.error("AS DISCONNECTED:", reason);
    socketConnected = false;
    store.dispatch(
      setStatusMessage("Disconnected from server", "error", 0, reason)
    );
  });
  socket.on("reconnect", (attemptNumber) => {
    console.log("AS RECONNECTED:", attemptNumber);
    socketConnected = true;
    store.dispatch(setStatusMessage("Reconnected to server", "success", 10));
  });
  socket.on("reconnecting", (attemptNumber) => {
    console.log("AS RECONNECTING:", attemptNumber);
    store.dispatch(setStatusMessage("Reconnecting to server...", "warning"));
  });
  socket.on("reconnect_error", (error) => {
    console.error("AS RECONNECT ERROR:", error);
    store.dispatch(
      setStatusMessage("Reconnect error", "error", 0, error.message)
    );
  });
  socket.on("reconnect_failed", () => {
    console.error("AS RECONNECT FAILED");
    store.dispatch(setStatusMessage("Reconnect failed", "error"));
  });

  // CUSTOM EVENTS
  // Server responses (model CRUDs)
  socket.on("response", (response) =>
    handleResponse(socket.id, response, store)
  );
  // the updates and notifications pushed from server
  socket.on("errorNotification", (data) => {
    notifyUser(data.title, data.description, true, () =>
      store.dispatch(setStatusMessage(data.title, "error", 0, data.description))
    );
    console.info("Error notification: responseCode:", data.responseCode);
  });
  // the agent notifications pushed from server
  socket.on("agentConnected", (data) => {
    const state = store.getState(),
      agent = data.data;
    if (
      "customer" !== state.auth.user.group &&
      agent._id !== state.auth.user._id
    ) {
      notifyUser("Agent connected", agentInfo(agent) + " connected", true);
    }
    store.dispatch(agentConnected(agent._id));
  });
  socket.on("agentDisconnected", (data) => {
    const state = store.getState(),
      agent = data.data;
    if (
      "customer" !== state.auth.user.group &&
      agent._id !== state.auth.user._id
    ) {
      notifyUser(
        "Agent disconnected",
        agentInfo(agent) + " disconnected",
        true
      );
    }
    store.dispatch(agentDisconnected(agent._id));
  });
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_SOCKET_API = "Call SocketAPI";

// A Redux middleware that interprets actions with CALL_SOCKET_API info specified.
// Performs the call and promises when such actions are dispatched.
export const agentSocketApi = (store) => (next) => (action) => {
  const callSocketAPI = action[CALL_SOCKET_API];
  if ("undefined" === typeof callSocketAPI) {
    return next(action);
  }

  const { endpoint, types, nonBlocking, data, successMessage } = callSocketAPI;
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_SOCKET_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types,
    user = store.getState().auth.user;

  if (!socketConnected) {
    // Logout anyway
    if (
      AGENT_LOGOUT === successType ||
      CUSTOMER_TERMINATE_SUCCESS === successType
    ) {
      logout();
      return next(actionWith({ type: successType, resetStatusMessage: true }));
    }
    if (user) {
      console.warn("No connection to server");
      if ("undefined" !== typeof callSocketAPI) {
        agentSocketApiQueuedRequest = action;
        console.info("Request queued");
      }
      // Connect socket if authenticated but not connected
      if (null === agentSocket) {
        connectSocket(user, store);
      }
    }
    if (socketInitialConnected) {
      next(setStatusMessage("No connection to the server", "error"));
    }
    return;
  }

  next(actionWith({ type: requestType }));
  if (!nonBlocking) {
    next(showLoading());
  }
  return callSocketApi(endpoint, data).then(
    (response) => {
      if (response.ok) {
        next(
          actionWith({ type: successType, response, resetStatusMessage: true })
        );
        if ("logoutAgent" === endpoint) {
          logout();
        } else if ("downloadPdfInvoice" === endpoint) {
          fileDownload(response.pdfData, response.fileName, "application/pdf");
        } else if ("getStandaloneLicense" === endpoint) {
          fileDownload(
            response.license + "\n",
            response.ip + ".txt",
            "text/plain"
          );
        } else if (redirection[endpoint]) {
          for (let redirectOption of redirection[endpoint]) {
            const {
              path,
              noPathMatch,
              conditionParam,
              conditionValue,
              param,
              fallback,
              groups,
            } = redirectOption;
            if (noPathMatch || 1 === window.location.hash.indexOf(path)) {
              if (!groups || groups[user.group]) {
                let value = "",
                  cValue = "";
                if (response.data) {
                  if (param) {
                    if (param.indexOf(".") !== -1) {
                      const keys = param.split("."),
                        length = keys.length;
                      value = response.data;
                      for (let i = 0; i < length; i++) {
                        if (value[keys[i]]) {
                          value = value[keys[i]];
                        } else {
                          value = "";
                          break;
                        }
                      }
                    } else if (response.data[param]) {
                      value = response.data[param];
                    }
                  }
                  if (conditionParam) {
                    if (conditionParam.indexOf(".") !== -1) {
                      const keys = conditionParam.split("."),
                        length = keys.length;
                      cValue = response.data;
                      for (let i = 0; i < length; i++) {
                        if (cValue[keys[i]]) {
                          cValue = cValue[keys[i]];
                        } else {
                          cValue = "";
                          break;
                        }
                      }
                    } else if (response.data[conditionParam]) {
                      cValue = response.data[conditionParam];
                    }
                  }
                }
                if (!conditionParam || cValue === conditionValue) {
                  if (param) {
                    if (!value) {
                      if (fallback) {
                        history.push(fallback);
                        store.dispatch(
                          setStatusMessage("Success", "success", 10)
                        );
                      }
                    } else {
                      history.push(path + value);
                    }
                  } else {
                    history.push(path);
                    store.dispatch(setStatusMessage("Success", "success", 10));
                  }
                  break;
                }
              }
            }
          }
        }
        if (successMessage) {
          store.dispatch(setStatusMessage(successMessage, "success", 10));
        }
      } else {
        next(
          actionWith({
            type: failureType,
            error: response.error || "Invalid error",
          })
        );
      }
      if (!nonBlocking) {
        // do this last so that the app will re-render with the new uri location
        next(hideLoading());
      }
    },
    (error) => {
      if ("logoutAgent" === endpoint) {
        // ignore error for logout
        logout();
      } else if ("stale" === error.type) {
        console.warn(
          "Stale response: ",
          endpoint,
          error.sequence,
          error.message
        );
      } else {
        next(
          actionWith({
            type: failureType,
            error: error.message || "Something bad happened",
          })
        );
      }
      if (!nonBlocking) {
        next(hideLoading());
      }
    }
  );
};
