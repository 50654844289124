// cSpell:ignore unmount
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LeftBar from "../../components/LeftBar";

class LeftBarContainer extends Component {
  static propTypes = {
    // Injected by React-Redux
    userGroup: PropTypes.string
  };

  render = () => {
    const { userGroup } = this.props;
    return (
      <LeftBar
        isAdmin={"admin" === userGroup}
        isAnyStaff={"customer" !== userGroup}
      />
    );
  };
}

const mapStateToProps = state => ({
  userGroup: state.auth.user.group
});

export default withRouter(connect(mapStateToProps)(LeftBarContainer));
