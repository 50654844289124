import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import throttle from "lodash/throttle";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import {
  setStatusMessage,
  showProgress,
  hideProgress,
  updateProgress,
  avatarSourceReady,
  avatarCropUpdate,
  avatarCropReady,
  avatarReset,
  formUnsetError
} from "../../actions/ui";
import ProgressBar from "../ProgressBar";

import "react-image-crop/dist/ReactCrop.css";

class AvatarInput extends Component {
  static propTypes = {
    tabIndex: PropTypes.number.isRequired,
    setAvatar: PropTypes.func.isRequired,
    unsetAvatar: PropTypes.func.isRequired,
    newAvatar: PropTypes.func.isRequired,
    newAvatarCancel: PropTypes.func.isRequired,
    defaultValue: PropTypes.string,
    hasError: PropTypes.bool,
    // Injected by React-Redux
    avatarInput: PropTypes.shape({
      sourceReady: PropTypes.bool.isRequired,
      cropReady: PropTypes.bool.isRequired
    }).isRequired,
    setStatusMessage: PropTypes.func.isRequired,
    showProgress: PropTypes.func.isRequired,
    hideProgress: PropTypes.func.isRequired,
    updateProgress: PropTypes.func.isRequired,
    avatarSourceReady: PropTypes.func.isRequired,
    avatarCropUpdate: PropTypes.func.isRequired,
    avatarCropReady: PropTypes.func.isRequired,
    avatarReset: PropTypes.func.isRequired,
    formUnsetError: PropTypes.func.isRequired,
    ref: PropTypes.func
  };

  componentWillMount = () => {
    const {
      showProgress,
      hideProgress,
      avatarSourceReady,
      newAvatar,
      avatarReset,
      defaultValue,
      reInitRef
    } = this.props;
    if (defaultValue) {
      this.avatar = defaultValue;
    }
    avatarReset();
    this.reader = new FileReader();
    this.reader.onerror = this.handleFileReadError;
    this.reader.onprogress = this.handleFileReadProgress;
    this.reader.onabort = e => {
      this.showFileReadError("File read aborted");
    };
    this.reader.onloadstart = e => {
      showProgress("Reading file");
    };
    this.reader.onload = e => {
      this.avatarSrc = e.target.result;
      avatarSourceReady();
      newAvatar();
    };
    this.reader.onloadend = e => {
      hideProgress();
    };
    if (reInitRef) {
      reInitRef(this.reInit);
    }
  };

  showFileReadError = message => {
    this.props.setStatusMessage(message, "error");
  };

  handleFileReadError = event => {
    switch (event.target.error.code) {
      case event.target.error.NOT_FOUND_ERR:
        this.showFileReadError("File not found");
        break;
      case event.target.error.NOT_READABLE_ERR:
        this.showFileReadError("File is not readable");
        break;
      case event.target.error.ABORT_ERR:
        this.showFileReadError("File read aborted");
        break;
      default:
        this.showFileReadError("An error occurred reading the file");
    }
  };

  handleFileReadProgress = event =>
    throttle(() => {
      // event is a ProgressEvent.
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        this.props.updateProgress(progress);
      }
    }, 100);

  cancelFileRead = () => {
    this.reader.abort();
    this.input.value = "";
  };

  avatarSelect = event => {
    // Read in the image file
    this.reader.readAsDataURL(event.target.files[0]);
  };

  avatarCrop = () => {
    const { setAvatar, avatarCropReady } = this.props,
      canvas = document.createElement("canvas"),
      destWidth = Math.min(this.pixelCrop.width, 100),
      destHeight = Math.min(this.pixelCrop.height, 100),
      ctx = canvas.getContext("2d");
    canvas.width = destWidth;
    canvas.height = destHeight;
    ctx.drawImage(
      this.loadedImg,
      this.pixelCrop.x,
      this.pixelCrop.y,
      this.pixelCrop.width,
      this.pixelCrop.height,
      0,
      0,
      destWidth,
      destHeight
    );
    this.avatar = canvas.toDataURL("image/jpeg", 0.9);
    avatarCropReady();
    setAvatar(this.avatar);
  };

  cropInit = image => {
    this.loadedImg = image;
    this.props.avatarCropUpdate(
      makeAspectCrop(
        {
          x: 0,
          y: 0,
          aspect: 1,
          width: 100
        },
        image.width / image.height
      )
    );
    this.pixelCrop = { x: 0, y: 0, width: image.width, height: image.height };
  };

  cropComplete = (crop, pixelCrop) => {
    this.props.avatarCropUpdate(crop);
    this.pixelCrop = pixelCrop;
  };

  cancelFile = () => {
    const { avatarReset, newAvatarCancel, formUnsetError } = this.props;
    avatarReset();
    newAvatarCancel();
    formUnsetError("avatar");
  };

  removeAvatar = () => {
    const { avatarReset, unsetAvatar } = this.props;
    this.avatar = undefined;
    avatarReset();
    unsetAvatar();
  };

  reInit = avatar => {
    this.avatar = avatar;
    this.props.avatarReset();
  };

  render = () => {
    const { tabIndex, avatarInput, hasError, avatarCropUpdate } = this.props;
    return (
      <div className={"field" + (hasError ? " error" : "")}>
        <label>Avatar</label>
        {!avatarInput.sourceReady && (
          <input
            ref={input => (this.input = input)}
            type="file"
            accept="image/*"
            tabIndex={tabIndex}
            name="avatar"
            onChange={this.avatarSelect}
          />
        )}
        <ProgressBar cancel={this.cancelFileRead} />
        {avatarInput.sourceReady &&
          !avatarInput.cropReady && (
            <div className="ui grid bottom attached padded segment">
              <div className="row">
                <button
                  id="cancel"
                  type="button"
                  className="ui mini negative button"
                  onClick={this.cancelFile}
                >
                  Cancel
                </button>
                <button
                  id="crop"
                  type="button"
                  className="ui mini primary button"
                  onClick={this.avatarCrop}
                >
                  Crop
                </button>
              </div>
              <div className="row">
                <ReactCrop
                  src={this.avatarSrc}
                  crop={avatarInput.crop}
                  keepSelection={true}
                  onImageLoaded={this.cropInit}
                  onChange={avatarCropUpdate}
                  onComplete={this.cropComplete}
                />
              </div>
            </div>
          )}
        {(this.avatar || avatarInput.cropReady) && (
          <div className="ui grid bottom attached padded segment">
            <div className="row">
              <img
                alt="avatar"
                className="ui circular image"
                src={this.avatar}
              />
            </div>
            <div className="row">
              <button
                type="button"
                className="ui mini negative button"
                onClick={this.removeAvatar}
              >
                Remove
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  avatarInput: state.ui.avatarInput
});

export default connect(
  mapStateToProps,
  {
    setStatusMessage,
    showProgress,
    hideProgress,
    updateProgress,
    avatarSourceReady,
    avatarCropUpdate,
    avatarCropReady,
    avatarReset,
    formUnsetError
  }
)(AvatarInput);
