import React from "react";
import PropTypes from "prop-types";

const ScreenTitle = ({ title, className }) => (
  <h2 id="screenTitle" className={className}>
    {title}
  </h2>
);

ScreenTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ScreenTitle;
