import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getById } from "../../reducers/payment";
import ScreenTitle from "../../components/ScreenTitle";
import CardInfo from "../../components/CardInfo";

class ViewCard extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    payment: PropTypes.object.isRequired
  };

  componentWillMount = () => {
    const { history, match, payment } = this.props;
    this.card = null;
    if (match.params.id) {
      this.card = getById(payment, match.params.id);
      if (!this.card) {
        history.push("/payment");
      }
    }
  };

  render = () => {
    const { history } = this.props,
      card = this.card;
    return (
      <article className="thirteen wide column">
        <header className="ui dividing header">
          <div className="ui two column grid">
            <ScreenTitle title="Card" className="column" />
            <div className="right floated right aligned column">
              <button
                id="back"
                className="ui primary button"
                onClick={() => history.push("/payment")}
              >
                <i className="left arrow icon" />
                Go back
              </button>
            </div>
          </div>
        </header>
        {card && (
          <div className="ui">
            <CardInfo card={card} />
          </div>
        )}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  payment: state.payment
});

export default withRouter(connect(mapStateToProps)(ViewCard));
