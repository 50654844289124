import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class AgentSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const firstName = this.firstNameField.value.trim(),
      lastName = this.lastNameField.value.trim(),
      email = this.emailField.value.trim(),
      group = this.groupField ? this.groupField.value : "",
      status = this.statusField.value,
      params = {};
    if (firstName) {
      params.firstName = firstName;
    }
    if (lastName) {
      params.lastName = lastName;
    }
    if (email) {
      params.email = email;
    }
    if (status) {
      switch (status) {
        case "active":
          params.active = true;
          break;
        case "inactive":
          params.active = false;
          break;
        default:
          break;
      }
    }
    if (group) {
      params.group = group;
    }
    if (this.customer) {
      params.customer = this.customer;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.firstNameField.value = "";
    this.lastNameField.value = "";
    this.emailField.value = "";
    if (this.groupField) {
      this.groupField.value = "";
    }
    this.statusField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  render = () => {
    const { search, userGroup, getSuggestedItem } = this.props,
      isStaff = "admin" === userGroup || "staff" === userGroup,
      status =
        true === search.active
          ? "active"
          : false === search.active
            ? "inactive"
            : "";
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className={isStaff ? "six fields" : "four fields"}>
          <div className="field">
            <label htmlFor="firstName">First name</label>
            <input
              type="text"
              tabIndex="10"
              name="firstName"
              id="firstName"
              placeholder="First name"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.firstName}
              ref={input => (this.firstNameField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="lastName">Last name</label>
            <input
              type="text"
              tabIndex="20"
              name="lastName"
              id="lastName"
              placeholder="Last name"
              autoComplete="off"
              defaultValue={search.lastName}
              ref={input => (this.lastNameField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              tabIndex="30"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="off"
              defaultValue={search.email}
              ref={input => (this.emailField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="40"
              defaultValue={status}
              ref={input => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["active", "inactive"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          {isStaff && (
            <div className="field">
              <label htmlFor="group">Group</label>
              <select
                id="group"
                name="group"
                tabIndex="50"
                defaultValue={search.group}
                ref={input => (this.groupField = input)}
              >
                <option value="">Select</option>
                {["admin", "staff", "customer"].map(item => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))};
              </select>
            </div>
          )}
          {isStaff && (
            <div className="field">
              <AutocompleteInput
                title="Customer"
                count={3}
                tabIndex={60}
                getSuggestedItem={id => {
                  getSuggestedItem("customer", id, { _id: 1, company: 1 });
                }}
                searchItem={this.autocomplete}
                required={true}
                getName={customer => customer.company}
                selectItem={id => {
                  this.customer = id;
                }}
              />
            </div>
          )}
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

const mapStateToProps = state => ({
  userGroup: state.auth.user.group
});

export default connect(
  mapStateToProps,
  {
    autocomplete,
    getSuggestedItem
  }
)(AgentSearch);
