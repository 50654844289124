import React from "react";

const NoMatch = () => (
  <article className="thirteen wide column">
    <header className="ui">
      <h2>Not found</h2>
      <h3>No route found.</h3>
    </header>
    <div className="ui">Sorry, the requested path cannot be found.</div>
  </article>
);

export default NoMatch;
