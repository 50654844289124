import { CALL_SOCKET_API } from "../middleware/agent-api";

export const GET_PASSWORD_RESET_REQUEST = "GET_PASSWORD_RESET_REQUEST";
export const GET_PASSWORD_RESET_SUCCESS = "GET_PASSWORD_RESET_SUCCESS";
export const GET_PASSWORD_RESET_FAILURE = "GET_PASSWORD_RESET_FAILURE";
export const getPasswordReset = licenseId => ({
  [CALL_SOCKET_API]: {
    types: [
      GET_PASSWORD_RESET_REQUEST,
      GET_PASSWORD_RESET_SUCCESS,
      GET_PASSWORD_RESET_FAILURE
    ],
    endpoint: "getPasswordReset",
    data: { licenseId },
    nonBlocking: true
  }
});

export const CLEAR_PASSWORD_RESET = "CLEAR_PASSWORD_RESET";
export const clearPasswordReset = () => ({
  type: CLEAR_PASSWORD_RESET
});
