const redirection = {
  createAgent: [{ path: "/agent" }],
  updateAgent: [{ path: "/agent" }],
  createCustomer: [{ path: "/customer" }],
  updateCustomer: [{ path: "/customer", groups: { admin: 1, staff: 1 } }],
  createCoupon: [{ path: "/coupon" }],
  updateCoupon: [{ path: "/coupon" }],
  purchaseLicense: [
    {
      path: "/license",
      conditionParam: "order.trial",
      conditionValue: true
    },
    {
      path: "/invoice/pay/",
      noPathMatch: true,
      param: "_id",
      fallback: "/invoice",
      groups: { customer: 1 }
    },
    { path: "/license", groups: { admin: 1, staff: 1 } }
  ],
  processInvoicePayment: [
    {
      path: "/invoice/view/",
      noPathMatch: true,
      param: "_id",
      fallback: "/invoice",
      groups: { customer: 1 }
    }
  ],
  createSetting: [{ path: "/setting" }],
  updateSetting: [{ path: "/setting" }],
  createCredit: [{ path: "/credit" }],
  processCreditPayment: [{ path: "/credit" }],
  createDnd: [{ path: "/dnd" }],
  createBuild: [{ path: "/build" }],
  updateBuild: [{ path: "/build" }],
  createBlacklist: [{ path: "/blacklist" }],
  updateBlacklist: [{ path: "/blacklist" }]
};

export default redirection;
