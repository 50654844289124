import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { readAgent } from "../../actions/agent";
import Customer from "./Customer";
import Staff from "./Staff";

class Account extends Component {
  static propTypes = {
    // Injected by React-Redux
    user: PropTypes.object.isRequired,
    readAgent: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { user, readAgent } = this.props;
    readAgent({ search: { _id: user._id } });
  };

  render = () => {
    const { user } = this.props;
    return "customer" === user.group ? <Customer /> : <Staff />;
  };
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  {
    readAgent
  }
)(Account);
