const config = {
  socketRequestTimeout: 10000, // milliseconds
  billingPeriod: "months",
  reCaptchaSiteKey: "6Lda53IUAAAAAORqQQis81bcQmXAwyQHeQ1NnLXm",
  pricePerServer: 1.99,
  stripeApiKey: "pk_live_YA46cMNYPzxBP1cQ0OccPUug",
  fromEmail: "billing@ezeelogin.com",
  minPasswordScore: 60 /* out of 100 */,
};
switch (process.env.NODE_ENV) {
  case "development":
    config.apiUrl = "http://localhost:8080/";
    break;
  case "testing":
    config.apiUrl = "http://billingtest.ezeelogin.com:8080/";
    break;
  default:
    break;
}

export default config;
