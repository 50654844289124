// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel, confirmDeleteItem } from "../../actions/listing";
import CreditSearch from "./CreditSearch";
import Listing from "../Listing";

class CreditListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string.isRequired,
    setItemModel: PropTypes.func.isRequired,
    confirmDeleteItem: PropTypes.func.isRequired
  };

  model = "credit";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, userGroup, confirmDeleteItem } = this.props,
      isAdmin = "admin" === userGroup,
      isStaff = "staff" === userGroup,
      fields = {
        serial: { title: "#", type: "serial" },
        amount: { title: "Amount", type: "number" },
        description: { title: "Description" }
      },
      actions = [
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: id => {
            confirmDeleteItem(id);
          }
        }
      ];
    if (isAdmin || isStaff) {
      fields["customer.company"] = { title: "Customer" };
      fields.creator = { title: "Creator", link: "/agent/edit/" };
    }
    fields.created = {
      title: "Created",
      type: "date",
      format: "YYYY-MM-DD hh:mm",
      sortable: true
    };
    if (isAdmin) {
      fields.actions = { title: "Actions", type: "actions" };
    }
    return (
      <Listing
        title="Credits"
        model={this.model}
        mainField="amount"
        // list config
        fields={fields}
        actions={isAdmin ? actions : null}
        highlightFields={{ description: 1 }}
        defaultSort={{ created: -1 }}
        // search form component
        ItemSearch={isAdmin || isStaff ? CreditSearch : null}
        onAdd={
          !isStaff
            ? () => {
                history.push(`/${this.model}/add`);
              }
            : null
        }
        simpleSearchTitle="Description"
        getSimpleSearch={search => ({
          description: search
        })}
      />
    );
  }
}

const mapStateToProps = state => ({
  userGroup: state.auth.user.group
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      setItemModel,
      confirmDeleteItem
    }
  )(CreditListing)
);
