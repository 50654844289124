// cSpell:ignore normalizr
import { schema } from "normalizr";

export const item = new schema.Entity("items", {}, { idAttribute: "_id" });
export const items = [item];

export const agent = new schema.Entity("agents", {}, { idAttribute: "_id" });
export const agents = [agent];

export const coupon = new schema.Entity("coupons", {}, { idAttribute: "_id" });
export const coupons = [coupon];

export const customer = new schema.Entity(
  "customers",
  {},
  { idAttribute: "_id" }
);
export const customers = [customer];

export const license = new schema.Entity(
  "licenses",
  {},
  { idAttribute: "_id" }
);
export const licenses = [license];

export const card = new schema.Entity("cards", {}, { idAttribute: "_id" });
export const cards = [card];
