import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getItem,
  cancelOrder,
  resetOrderCancel,
  confirmOrderCancel,
  updateOrderAutoRenew
} from "../../actions";
import Header from "../../components/Header";
import Order from "../../components/Order";
import Confirmation from "../../components/Confirmation";

class OrderView extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    item: PropTypes.object.isRequired,
    orderCancel: PropTypes.bool.isRequired,
    getItem: PropTypes.func.isRequired,
    cancelOrder: PropTypes.object.isRequired,
    resetOrderCancel: PropTypes.object.isRequired,
    confirmOrderCancel: PropTypes.object.isRequired,
    updateOrderAutoRenew: PropTypes.object.isRequired
  };

  model = "order";

  componentWillMount = () => {
    this.refresh();
  };

  refresh = () => {
    const { history, match, getItem, resetOrderCancel } = this.props;
    if (match.params.id) {
      resetOrderCancel();
      getItem(this.model, match.params.id);
    } else {
      history.push("/order");
    }
  };

  confirmOrderCancel = () => {
    this.props.confirmOrderCancel();
  };

  cancelOrder = () => {
    const { item, cancelOrder } = this.props;
    cancelOrder(item.data._id);
  };

  render = () => {
    const {
      history,
      item,
      orderCancel,
      resetOrderCancel,
      updateOrderAutoRenew
    } = this.props;
    return (
      <article className="thirteen wide column">
        <Header
          title="Order details"
          isLoading={item.loading}
          onRefresh={this.refresh}
        />
        {orderCancel && (
          <Confirmation
            message="If canceled, the order will not generate a new invoice or renew automatically. Are you sure you want to cancel the order"
            onNo={resetOrderCancel}
            onYes={this.cancelOrder}
          />
        )}
        {!!item.data && <Order order={item.data} />}
        <button
          id="listing"
          type="button"
          tabIndex="120"
          className="ui primary button"
          onClick={() => {
            history.goBack();
          }}
        >
          Go back
        </button>
        {!!item.data && "active" === item.data.status && (
          <button
            id="cancelOrder"
            type="button"
            tabIndex="110"
            className="ui negative button"
            onClick={this.confirmOrderCancel}
          >
            Cancel order
          </button>
        )}
        {!!item.data && "active" === item.data.status && (
          <button
            id="toggleAutoRenew"
            type="button"
            tabIndex="100"
            className="ui button"
            onClick={() => {
              updateOrderAutoRenew(item.data._id, !item.data.autoRenew);
            }}
          >
            Toggle auto renew
          </button>
        )}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  item: state.item,
  orderCancel: state.license.confirmOrderCancel
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getItem,
      cancelOrder,
      resetOrderCancel,
      confirmOrderCancel,
      updateOrderAutoRenew
    }
  )(OrderView)
);
