import {
  CUSTOMER_READ_REQUEST,
  CUSTOMER_READ_SUCCESS,
  CUSTOMER_READ_FAILURE,
  CUSTOMER_UPDATE_SUCCESS,
  CARD_DELETE_SUCCESS,
  STRIPE_ADD_CARD_SUCCESS,
  ITEM_UPDATE_SUCCESS
} from "../actions";

const customer = (
  state = {
    loading: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case CUSTOMER_READ_REQUEST:
      return { ...state, loading: true };
    case CUSTOMER_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };
    case ITEM_UPDATE_SUCCESS:
      if ("customer" === action.model) {
        return {
          ...state,
          data: action.response.data
        };
      }
      return state;
    case CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.response.data
      };
    case CUSTOMER_READ_FAILURE:
      return { ...state, loading: false };
    case CARD_DELETE_SUCCESS:
      if (action.response.data._id === state.data.defaultCard) {
        const { defaultCard: omit, ...customer } = state.data;
        return { ...state, data: customer };
      }
      return state;
    case STRIPE_ADD_CARD_SUCCESS:
      if (action.response.default) {
        return {
          ...state,
          data: { ...state.data, defaultCard: action.response.data._id }
        };
      }
      return state;
    default:
      return state;
  }
};

export default customer;
