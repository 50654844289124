import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class CreditSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const amount = parseInt(this.amountField.value.trim(), 10),
      description = this.descriptionField.value.trim(),
      params = {};
    if (amount) {
      params.amount = amount;
    }
    if (description) {
      params.description = description;
    }
    if (this.customer) {
      params.customer = this.customer;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.amountField.value = "";
    this.descriptionField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  render = () => {
    const { search, getSuggestedItem } = this.props;
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="three fields">
          <div className="field">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              tabIndex="10"
              name="amount"
              id="amount"
              placeholder="Amount"
              autoComplete="off"
              defaultValue={search.amount}
              ref={input => (this.amountField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              tabIndex="20"
              name="description"
              id="description"
              placeholder="Description"
              defaultValue={search.description}
              ref={input => (this.descriptionField = input)}
            />
          </div>
          <div className="field">
            <AutocompleteInput
              title="Customer"
              count={3}
              tabIndex={30}
              getSuggestedItem={id => {
                getSuggestedItem("customer", id, { _id: 1, company: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              getName={customer => customer.company}
              selectItem={id => {
                this.customer = id;
              }}
            />
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default connect(
  null,
  {
    autocomplete,
    getSuggestedItem
  }
)(CreditSearch);
