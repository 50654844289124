// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel } from "../../actions/listing";
import CustomerSearch from "./CustomerSearch";
import Listing from "../Listing";

class CustomerListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired
  };

  model = "customer";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        _id: { title: "ID", link: "/customer/license/" },
        company: { title: "Company", sortable: true },
        email: { title: "Email", sortable: true },
        phone: { title: "Phone" },
        credit: { title: "Credit" },
        active: { title: "Active", type: "bool", sortable: true },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true
        },
        actions: { title: "Actions", type: "actions" }
      },
      actions = [
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: id => {
            history.push(`/${this.model}/edit/${id}`);
          }
        }
      ];
    return (
      <Listing
        title="Customers"
        model={this.model}
        mainField="company"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ company: 1, email: 1 }}
        // search form component
        ItemSearch={CustomerSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Company"
        getSimpleSearch={search => ({
          company: search
        })}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      setItemModel
    }
  )(CustomerListing)
);
