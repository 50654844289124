import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class InvoiceSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const id = this.idField.value.trim(),
      order = this.orderField.value.trim(),
      status = this.statusField.value,
      params = {};
    if (id) {
      params._id = id;
    }
    if (order) {
      params.order = order;
    }
    if (status) {
      params.status = status;
    }
    if (this.customer) {
      params.customer = this.customer;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.idField.value = "";
    this.orderField.value = "";
    this.statusField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  render = () => {
    const { search, userGroup, getSuggestedItem } = this.props,
      isStaff = "admin" === userGroup || "staff" === userGroup;
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className={isStaff ? "four fields" : "three fields"}>
          <div className="field">
            <label htmlFor="id">ID</label>
            <input
              type="text"
              tabIndex="10"
              name="id"
              id="id"
              placeholder="Exact ID"
              defaultValue={search._id}
              ref={input => (this.idField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="order">Order ID</label>
            <input
              type="text"
              tabIndex="20"
              name="order"
              id="order"
              placeholder="Exact order ID"
              autoFocus={true}
              defaultValue={search.order}
              ref={input => (this.orderField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="30"
              defaultValue={search.status}
              ref={input => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["paid", "unpaid"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          {isStaff && (
            <div className="field">
              <AutocompleteInput
                title="Customer"
                count={3}
                tabIndex={40}
                getSuggestedItem={id => {
                  getSuggestedItem("customer", id, { _id: 1, company: 1 });
                }}
                searchItem={this.autocomplete}
                required={true}
                getName={customer => customer.company}
                selectItem={id => {
                  this.customer = id;
                }}
              />
            </div>
          )}
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

const mapStateToProps = state => ({
  userGroup: state.auth.user.group
});

export default connect(
  mapStateToProps,
  {
    autocomplete,
    getSuggestedItem
  }
)(InvoiceSearch);
