import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem
} from "../../actions";
import * as validations from "../../lib/validations";

class Form extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired
  };

  model = "dnd";

  componentWillMount = () => {
    const { resetStatusMessage, formResetError } = this.props;
    resetStatusMessage();
    formResetError();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, createItem } = this.props,
      email = this.emailField.value.trim();
    let hasError = false;
    formResetError();
    if (!email || !validations.email(email)) {
      formSetError("email");
      hasError = true;
    }
    if (!hasError) {
      const data = { email };
      createItem(this.model, data);
    }
    return false;
  };

  reset = () => {
    this.emailField.value = "";
  };

  render = () => {
    const { form, onCancel } = this.props;
    return (
      <div className="ui">
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.email && <li>Enter a valid email address</li>}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div className={"field" + (form.errorFields.email ? " error" : "")}>
            <label htmlFor="newEmail">Email</label>
            <input
              type="email"
              tabIndex="20"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="off"
              ref={input => (this.emailField = input)}
            />
          </div>
          <button
            id="cancelDnd"
            tabIndex="210"
            className="ui negative button"
            type="button"
            onClick={onCancel ? onCancel : this.reset}
          >
            Cancel
          </button>
          <button tabIndex="200" className="ui primary button" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    resetStatusMessage,
    formSetError,
    formResetError,
    createItem
  }
)(Form);
