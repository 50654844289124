import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem,
  updateItem,
  autocomplete,
  getSuggestedItem,
  getItem
} from "../../actions";
import * as validations from "../../lib/validations";
import Header from "../../components/Header";
import AutocompleteInput from "../AutocompleteInput";

class CouponForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired
  };

  model = "coupon";

  componentWillMount = () => {
    const { match, resetStatusMessage, formResetError } = this.props;
    this.edit = false;
    if (match.params.id) {
      this.edit = true;
    }
    resetStatusMessage();
    formResetError();
    this.refresh();
  };

  refresh = () => {
    const { match, getItem } = this.props;
    if (match.params.id) {
      getItem(this.model, match.params.id);
    }
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
        match,
        formSetError,
        formResetError,
        createItem,
        updateItem
      } = this.props,
      description = this.descriptionField.value.trim();
    let hasError = false,
      minMaxServers = 0,
      minMaxTerm = 0,
      percentageAmount = 0,
      minServers = this.minServersField.value.trim(),
      maxServers = this.maxServersField.value.trim(),
      minTerm = this.minTermField.value,
      maxTerm = this.maxTermField.value,
      percentage = this.percentageField.value.trim(),
      amount = this.amountField.value,
      expiry = this.expiryField.value.trim();
    formResetError();
    if (!this.customer) {
      formSetError("customer");
      hasError = true;
    }
    if (!description || !validations.standardText(description)) {
      formSetError("description");
      hasError = true;
    }
    if (minServers) {
      minServers = parseInt(minServers, 10);
      if (isNaN(minServers) || minServers < 1 || minServers > 9999) {
        formSetError("minServers");
        hasError = true;
      } else {
        minMaxServers++;
      }
    }
    if (maxServers) {
      maxServers = parseInt(maxServers, 10);
      if (isNaN(maxServers) || maxServers < 1 || maxServers > 9999) {
        formSetError("maxServers");
        hasError = true;
      } else {
        minMaxServers++;
      }
    }
    if (2 === minMaxServers && minServers > maxServers) {
      formSetError("minGreaterThanMaxServers");
      hasError = true;
    }
    if (minTerm) {
      minTerm = parseInt(minTerm, 10);
      if (isNaN(minTerm) || !validations.billingCycle(minTerm)) {
        formSetError("minTerm");
        hasError = true;
      } else {
        minMaxTerm++;
      }
    }
    if (maxTerm) {
      maxTerm = parseInt(maxTerm, 10);
      if (isNaN(maxTerm) || !validations.billingCycle(maxTerm)) {
        formSetError("maxTerm");
        hasError = true;
      } else {
        minMaxTerm++;
      }
    }
    if (2 === minMaxTerm && minTerm > maxTerm) {
      formSetError("minGreaterThanMaxTerm");
      hasError = true;
    }
    if (percentage) {
      percentage = parseInt(percentage, 10);
      if (isNaN(percentage) || percentage < 1 || percentage > 50) {
        formSetError("percentage");
        hasError = true;
      } else {
        percentageAmount++;
      }
    }
    if (amount) {
      amount = parseInt(amount, 10);
      if (isNaN(amount) || amount < 1 || amount > 9999) {
        formSetError("amount");
        hasError = true;
      } else {
        percentageAmount++;
      }
    }
    if (!percentage && !amount) {
      formSetError("percentageAmountNone");
      hasError = true;
    }
    if (2 === percentageAmount) {
      formSetError("percentageAmountBoth");
      hasError = true;
    }
    if (!hasError) {
      const coupon = {
        customer: this.customer,
        description,
        newOnly: this.newOnlyField.checked,
        recurring: this.recurringField.checked,
        active: this.activeField.checked
      };
      if (match.params.id) {
        coupon._id = match.params.id;
      }
      if (minServers) {
        coupon.minServers = minServers;
      }
      if (maxServers) {
        coupon.maxServers = maxServers;
      }
      if (minTerm) {
        coupon.minTerm = minTerm;
      }
      if (maxTerm) {
        coupon.maxTerm = maxTerm;
      }
      if (percentage) {
        coupon.percentage = percentage;
      }
      if (amount) {
        coupon.amount = amount;
      }
      if (expiry) {
        coupon.expiry = moment(expiry);
      }
      this.edit
        ? updateItem(this.model, coupon)
        : createItem(this.model, coupon);
    }
    return false;
  };

  render = () => {
    const { history, match, item, form, getSuggestedItem } = this.props,
      coupon = match.params.id ? item.data : {};
    if (!coupon) {
      return null;
    } else if (coupon.customer) {
      this.customer = coupon.customer;
    }
    return (
      <article className="thirteen wide column">
        <Header
          title={this.edit ? "Edit coupon" : "Add coupon"}
          isLoading={item.loading}
          onRefresh={this.edit ? this.refresh : null}
        />
        <div className="ui">
          {form.hasError && (
            <div className="ui error message">
              <ul className="list">
                {form.errorFields.customer && <li>Select a customer</li>}
                {form.errorFields.description && (
                  <li>Enter a valid description</li>
                )}
                {form.errorFields.minServers && (
                  <li>Minimum servers must be between 1 and 9999</li>
                )}
                {form.errorFields.maxServers && (
                  <li>Maximum servers must be between 1 and 9999</li>
                )}
                {form.errorFields.minGreaterThanMaxServers && (
                  <li>
                    Minimum servers must be less than or equal to maximum
                    servers
                  </li>
                )}
                {form.errorFields.minTerm && <li>Invalid minimum term</li>}
                {form.errorFields.maxTerm && <li>Invalid maximum term</li>}
                {form.errorFields.minGreaterThanMaxTerm && (
                  <li>
                    Minimum term must be less than or equal to maximum term
                  </li>
                )}
                {form.errorFields.percentage && (
                  <li>Percentage must be between 1 and 50</li>
                )}
                {form.errorFields.amount && (
                  <li>Amount must be between 1 and 9999</li>
                )}
                {form.errorFields.percentageAmountNone && (
                  <li>Percentage or amount is required</li>
                )}
                {form.errorFields.percentageAmountBoth && (
                  <li>Percentage and amount cannot be both specified</li>
                )}
              </ul>
            </div>
          )}
          <form className="ui form" onSubmit={this.submit}>
            <AutocompleteInput
              title="Customer"
              count={3}
              tabIndex={10}
              selectedItem={coupon ? coupon.customer : null}
              getSuggestedItem={id => {
                getSuggestedItem("customer", id, { _id: 1, company: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              hasError={form.errorFields.customer}
              getName={customer => customer.company}
              selectItem={id => {
                this.customer = id;
              }}
              autoFocus={true}
            />
            <div
              className={
                "required field" +
                (form.errorFields.description ? " error" : "")
              }
            >
              <label htmlFor="description">Description</label>
              <input
                type="text"
                tabIndex="20"
                name="description"
                id="description"
                placeholder="Description"
                autoComplete="off"
                ref={input => (this.descriptionField = input)}
                defaultValue={coupon ? coupon.description : ""}
              />
            </div>
            <div
              className={
                "field" +
                (form.errorFields.percentage ||
                form.errorFields.percentageAmountNone ||
                form.errorFields.percentageAmountBoth
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="percentage">Percentage</label>
              <input
                type="number"
                tabIndex="30"
                name="percentage"
                id="percentage"
                placeholder="Discount percentage"
                autoComplete="off"
                ref={input => (this.percentageField = input)}
                defaultValue={coupon ? coupon.percentage : ""}
              />
            </div>
            <div
              className={
                "field" +
                (form.errorFields.amount ||
                form.errorFields.percentageAmountNone ||
                form.errorFields.percentageAmountBoth
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                tabIndex="40"
                name="amount"
                id="amount"
                placeholder="Discount amount"
                autoComplete="off"
                ref={input => (this.amountField = input)}
                defaultValue={coupon ? coupon.amount : ""}
              />
            </div>
            <div
              className={"field" + (form.errorFields.expiry ? " error" : "")}
            >
              <label htmlFor="expiry">Expiry</label>
              <input
                type="date"
                tabIndex="50"
                name="expiry"
                id="expiry"
                placeholder="Expiry date as YYYY-MM-DD"
                autoComplete="off"
                ref={input => (this.expiryField = input)}
                defaultValue={
                  coupon && coupon.expiry
                    ? moment(coupon.expiry).format("YYYY-MM-DD")
                    : ""
                }
                min={moment().format("YYYY-MM-DD")}
              />
            </div>
            <div
              className={
                "field" +
                (form.errorFields.minServers ||
                form.errorFields.minGreaterThanMaxServers
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="minServers">Minimum servers</label>
              <input
                type="number"
                tabIndex="60"
                name="minServers"
                id="minServers"
                placeholder="Minimum number of servers"
                autoComplete="off"
                ref={input => (this.minServersField = input)}
                defaultValue={coupon ? coupon.minServers : ""}
              />
            </div>
            <div
              className={
                "field" +
                (form.errorFields.maxServers ||
                form.errorFields.minGreaterThanMaxServers
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="maxServers">Maximum servers</label>
              <input
                type="number"
                tabIndex="70"
                name="maxServers"
                id="maxServers"
                placeholder="Maximum number of servers"
                autoComplete="off"
                ref={input => (this.maxServersField = input)}
                defaultValue={coupon ? coupon.maxServers : ""}
              />
            </div>
            <div
              className={
                "field" +
                (form.errorFields.minTerm ||
                form.errorFields.minGreaterThanMaxTerm
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="minTerm">Minimum term</label>
              <select
                id="minTerm"
                name="minTerm"
                tabIndex="80"
                ref={input => (this.minTermField = input)}
                defaultValue={coupon ? coupon.minTerm : ""}
              >
                <option value="">Unspecified</option>
                <option value="1">Monthly</option>
                <option value="3">Quarterly</option>
                <option value="6">Half-yearly</option>
                <option value="12">Yearly</option>
              </select>
            </div>
            <div
              className={
                "field" +
                (form.errorFields.maxTerm ||
                form.errorFields.minGreaterThanMaxTerm
                  ? " error"
                  : "")
              }
            >
              <label htmlFor="maxTerm">Maximum term</label>
              <select
                id="maxTerm"
                name="maxTerm"
                tabIndex="90"
                ref={input => (this.maxTermField = input)}
                defaultValue={coupon ? coupon.maxTerm : ""}
              >
                <option value="">Unspecified</option>
                <option value="1">Monthly</option>
                <option value="3">Quarterly</option>
                <option value="6">Half-yearly</option>
                <option value="12">Yearly</option>
              </select>
            </div>
            <div className="inline field">
              <div className="ui checkbox">
                <input
                  id="newOnly"
                  type="checkbox"
                  tabIndex="100"
                  name="newOnly"
                  ref={input => (this.newOnlyField = input)}
                  defaultChecked={coupon ? coupon.newOnly : true}
                />
                <label>New licenses only</label>
              </div>
            </div>
            <div className="inline field">
              <div className="ui checkbox">
                <input
                  id="recurring"
                  type="checkbox"
                  tabIndex="110"
                  name="recurring"
                  ref={input => (this.recurringField = input)}
                  defaultChecked={coupon ? coupon.recurring : false}
                />
                <label>Recurring</label>
              </div>
            </div>
            <div className="inline field">
              <div className="ui checkbox">
                <input
                  id="active"
                  type="checkbox"
                  tabIndex="120"
                  name="active"
                  ref={input => (this.activeField = input)}
                  defaultChecked={coupon ? coupon.active : false}
                />
                <label>Active</label>
              </div>
            </div>
            <button
              id="cancel"
              tabIndex="210"
              className="ui negative button"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
            <button tabIndex="200" className="ui primary button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form,
  item: state.item
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      resetStatusMessage,
      formSetError,
      formResetError,
      createItem,
      updateItem,
      autocomplete,
      getSuggestedItem,
      getItem
    }
  )(CouponForm)
);
