import { SEARCHBAR_CLICK_TAB } from "../../actions/ui/searchBar";

// Search bar state
const searchBar = (state = { activeTab: "", search: "" }, action) => {
  switch (action.type) {
    case SEARCHBAR_CLICK_TAB:
      return {
        ...state,
        activeTab: action.tab === state.activeTab ? "" : action.tab
      };
    default:
      return state;
  }
};

export default searchBar;
