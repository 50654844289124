export const SET_STATUS_MESSAGE = "SET_STATUS_MESSAGE";
// Sets the status message.
export const setStatusMessage = (message, type, timeout, description) => ({
  type: SET_STATUS_MESSAGE,
  message,
  description,
  messageType: type,
  messageTimeout: timeout
});
export const RESET_STATUS_MESSAGE = "RESET_STATUS_MESSAGE";
// Resets the currently visible status message.
export const resetStatusMessage = () => ({
  type: RESET_STATUS_MESSAGE
});
