import {
  READ_PRIVACY_REQUEST,
  READ_PRIVACY_SUCCESS,
  READ_PRIVACY_FAILURE
} from "../actions/privacy";

const privacy = (state = { loading: false, map: {} }, action) => {
  switch (action.type) {
    case READ_PRIVACY_REQUEST:
      return { ...state, loading: true };
    case READ_PRIVACY_SUCCESS:
      const map = {},
        data = action.response.data;
      data.forEach(entry => {
        if (!map[entry.type]) {
          map[entry.type] = {};
        }
        map[entry.type][entry.email] = 1;
      });
      return {
        ...state,
        loading: false,
        map
      };
    case READ_PRIVACY_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default privacy;
