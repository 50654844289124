import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const CurrentBilling = ({ lastPayment }) => {
  if (!lastPayment) {
    return null;
  }
  const {
    amount,
    periodStart,
    periodEnd,
    daysUsed,
    daysTotal,
    daysRemaining,
    amountRemaining
  } = lastPayment;
  return (
    <table className="ui celled collapsing table">
      <tbody>
        <tr>
          <td>Amount</td>
          <td>${amount} USD</td>
        </tr>
        <tr>
          <td>Start date</td>
          <td>{periodStart.format("MMM D, YYYY")}</td>
        </tr>
        <tr>
          <td>End date</td>
          <td>{periodEnd.format("MMM D, YYYY")}</td>
        </tr>
        <tr>
          <td>Days total</td>
          <td>{daysTotal}</td>
        </tr>
        <tr>
          <td>Days used</td>
          <td>{daysUsed}</td>
        </tr>
        <tr>
          <td>Days remaining</td>
          <td>{daysRemaining}</td>
        </tr>
        <tr>
          <td>
            <strong>Amount remaining</strong>
          </td>
          <td>
            {amount} * ({daysRemaining} / {daysTotal}) ={" "}
            <strong>${amountRemaining} USD</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

CurrentBilling.propTypes = {
  // Injected by React-Redux
  lastPayment: PropTypes.object
};

const mapStateToProps = state => ({
  lastPayment: state.license.lastPayment
});

export default connect(mapStateToProps)(CurrentBilling);
