import { getScoreMessage } from "../../lib/password";

export const FORM_SET_ERROR = "FORM_SET_ERROR";
export const FORM_RESET_ERROR = "FORM_RESET_ERROR";
export const FORM_UNSET_ERROR = "FORM_UNSET_ERROR";
export const formSetError = (field, message) => ({
  type: FORM_SET_ERROR,
  field,
  message,
});
export const formResetError = () => ({ type: FORM_RESET_ERROR });
export const formUnsetError = (field) => ({ type: FORM_UNSET_ERROR, field });

export const FORM_SET_DATA = "FORM_SET_DATA";
export const FORM_RESET_DATA = "FORM_RESET_DATA";
export const FORM_UNSET_DATA = "FORM_UNSET_DATA";
export const formSetData = (field, value) => ({
  type: FORM_SET_DATA,
  field,
  value,
});
export const formResetData = () => ({ type: FORM_RESET_DATA });
export const formUnsetData = (field) => ({ type: FORM_UNSET_DATA, field });

export const FORM_SET_INFO = "FORM_SET_INFO";
export const FORM_TOGGLE_INFO = "FORM_TOGGLE_INFO";
export const FORM_RESET_INFO = "FORM_RESET_INFO";
export const formSetInfo = (field, value) => ({
  type: FORM_SET_INFO,
  field,
  value,
});
export const formToggleInfo = (field) => ({ type: FORM_TOGGLE_INFO, field });
export const formResetInfo = () => ({ type: FORM_RESET_INFO });

export const FORM_SET_PASSWORD_STRENGTH = "FORM_SET_PASSWORD_STRENGTH";
export const FORM_RESET_PASSWORD_STRENGTH = "FORM_RESET_PASSWORD_STRENGTH";
export const formSetPasswordStrength = (score) => ({
  type: FORM_SET_PASSWORD_STRENGTH,
  score: score,
  strength: getScoreMessage(score),
});
export const formResetPasswordStrength = () => ({
  type: FORM_RESET_PASSWORD_STRENGTH,
});

export const FORM_CAPTCHA_SOLVED = "FORM_CAPTCHA_SOLVED";
export const captchaSolved = () => ({ type: FORM_CAPTCHA_SOLVED });

export const FORM_RESET_ALL = "FORM_RESET_ALL";
export const formResetAll = () => ({ type: FORM_RESET_ALL });
