import { CALL_SOCKET_API } from "../middleware/agent-api";
import { ucFirst } from "../lib/strings";

export const ITEM_READ_REQUEST = "ITEM_READ_REQUEST";
export const ITEM_READ_SUCCESS = "ITEM_READ_SUCCESS";
export const ITEM_READ_FAILURE = "ITEM_READ_FAILURE";
export const readItem = (model, data = {}) => ({
  [CALL_SOCKET_API]: {
    types: [ITEM_READ_REQUEST, ITEM_READ_SUCCESS, ITEM_READ_FAILURE],
    endpoint: "read" + ucFirst(model),
    nonBlocking: true,
    data
  },
  model
});

export const ITEM_CREATE_REQUEST = "ITEM_CREATE_REQUEST";
export const ITEM_CREATE_SUCCESS = "ITEM_CREATE_SUCCESS";
export const ITEM_CREATE_FAILURE = "ITEM_CREATE_FAILURE";
export const createItem = (model, document) => ({
  [CALL_SOCKET_API]: {
    types: [ITEM_CREATE_REQUEST, ITEM_CREATE_SUCCESS, ITEM_CREATE_FAILURE],
    endpoint: "create" + ucFirst(model),
    data: { document },
    successMessage: "Added"
  },
  model
});

export const ITEM_UPDATE_REQUEST = "ITEM_UPDATE_REQUEST";
export const ITEM_UPDATE_SUCCESS = "ITEM_UPDATE_SUCCESS";
export const ITEM_UPDATE_FAILURE = "ITEM_UPDATE_FAILURE";
export const updateItem = (model, document) => ({
  [CALL_SOCKET_API]: {
    types: [ITEM_UPDATE_REQUEST, ITEM_UPDATE_SUCCESS, ITEM_UPDATE_FAILURE],
    endpoint: "update" + ucFirst(model),
    data: { document },
    successMessage: "Updated"
  },
  model
});

export const ITEM_DELETE = "ITEM_DELETE";
export const ITEM_DELETE_CANCEL = "ITEM_DELETE_CANCEL";
export const ITEM_DELETE_REQUEST = "ITEM_DELETE_REQUEST";
export const ITEM_DELETE_SUCCESS = "ITEM_DELETE_SUCCESS";
export const ITEM_DELETE_FAILURE = "ITEM_DELETE_FAILURE";
export const confirmDeleteItem = id => ({
  type: ITEM_DELETE,
  id
});
export const cancelDeleteItem = () => ({
  type: ITEM_DELETE_CANCEL
});
export const deleteItem = (model, id) => ({
  [CALL_SOCKET_API]: {
    types: [ITEM_DELETE_REQUEST, ITEM_DELETE_SUCCESS, ITEM_DELETE_FAILURE],
    endpoint: "delete" + ucFirst(model),
    data: { document: { _id: id } }
  },
  model
});

export const ITEM_SEARCH = "ITEM_SEARCH";
export const searchItem = search => ({
  type: ITEM_SEARCH,
  search
});

export const ITEM_SORT = "ITEM_SORT";
export const sortItem = sortBy => ({
  type: ITEM_SORT,
  sortBy
});

export const ITEM_SET_PER_PAGE = "ITEM_SET_PER_PAGE";
export const setItemPerPage = perPage => ({
  type: ITEM_SET_PER_PAGE,
  perPage
});

export const ITEM_SET_PAGE = "ITEM_SET_PAGE";
export const setItemPage = page => ({
  type: ITEM_SET_PAGE,
  page
});
export const ITEM_RESET_PAGE = "ITEM_RESET_PAGE";
export const resetItemPage = () => ({
  type: ITEM_RESET_PAGE
});

export const ITEM_SET_MODEL = "ITEM_SET_MODEL";
export const setItemModel = model => ({
  type: ITEM_SET_MODEL,
  model
});
