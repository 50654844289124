// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel, confirmDeleteItem } from "../../actions/listing";
import BuildSearch from "./BuildSearch";
import Listing from "../Listing";

class BuildListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired,
    confirmDeleteItem: PropTypes.func.isRequired
  };

  model = "build";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, confirmDeleteItem } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        _id: { title: "ID", link: "/build/license/" },
        oldId: { title: "Old ID" },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD hh:mm",
          sortable: true
        },
        actions: { title: "Actions", type: "actions" }
      },
      actions = [
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: id => {
            confirmDeleteItem(id);
          }
        }
      ];
    return (
      <Listing
        title="Builds"
        model={this.model}
        mainField="_id"
        // list config
        fields={fields}
        actions={actions}
        defaultSort={{ created: -1 }}
        // search form component
        ItemSearch={BuildSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Exact ID"
        getSimpleSearch={search => ({
          _id: search
        })}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    {
      setItemModel,
      confirmDeleteItem
    }
  )(BuildListing)
);
