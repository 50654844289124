import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { readSetting, readCustomer, checkTrial } from "../../actions";
import SimpleHeader from "../../components/SimpleHeader";

class Dashboard extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    user: PropTypes.object.isRequired,
    latest: PropTypes.string,
    customer: PropTypes.object,
    readSetting: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    checkTrial: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { user, latest, readSetting, readCustomer, checkTrial } = this.props;
    if (!latest) {
      // Give some time for the socket to stabilize
      setTimeout(() => {
        readSetting({ limit: 50 });
      }, 500);
    }
    if ("customer" === user.group) {
      setTimeout(() => {
        readCustomer();
        checkTrial();
      }, 600);
    }
  };

  render = () => {
    const { history, user, latest, customer, hasTrial } = this.props,
      linkPrefix = "https://downloads.ezeelogin.com/ezlogin_",
      linkSuffix = ".bin",
      linkPhp71 = linkPrefix + latest + "_php71" + linkSuffix,
      linkphp81 = linkPrefix + latest + "_php81" + linkSuffix,
      linkphp82 = linkPrefix + latest + "_php82" + linkSuffix;
    return (
      <article className="thirteen wide column">
        <SimpleHeader title="Dashboard" />
        <div className="ui cards">
          <div className="card">
            <div className="content">
              <div className="header">Latest version</div>
              <div className="description">
                The latest Ezeelogin version is: <strong>{latest}</strong>
              </div>
            </div>
          </div>
          {"customer" === user.group && hasTrial && (
            <div className="card">
              <div className="content">
                <div className="header">30 days free trial!</div>
                <div className="description">
                  <button
                    id="trial"
                    tabIndex="10"
                    className="ui positive button"
                    type="button"
                    onClick={() => {
                      history.push("/license/add/trial");
                    }}
                  >
                    Get free trial
                  </button>
                </div>
              </div>
            </div>
          )}
          {"customer" === user.group && !!customer && (
            <div className="card">
              <div className="content">
                <div className="header">Credit balance</div>
                <div className="description">
                  <strong>${customer.credit || 0} USD</strong>
                </div>
              </div>
            </div>
          )}
        </div>
        {!!latest && (
          <table className="ui celled collapsing unstackable table">
            <thead>
              <tr>
                <th colSpan="2">Downloads</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>For PHP version 8.2 and above:</td>
                <td>
                  <a href={linkphp82}>{linkphp82}</a>
                </td>
              </tr>
              <tr>
                <td>For PHP version 8.1.x:</td>
                <td>
                  <a href={linkphp81}>{linkphp81}</a>
                </td>
              </tr>
              <tr>
                <td>For PHP version 7.x (end of life):</td>
                <td>
                  <a href="https://downloads.ezeelogin.com/ezlogin_7.36.0_php71.bin">
                    https://downloads.ezeelogin.com/ezlogin_7.36.0_php71.bin
                  </a>
                </td>
              </tr>
              <tr>
                <td>For PHP version 5.6 to 7.0 (end of life):</td>
                <td>
                  <a href="https://downloads.ezeelogin.com/ezlogin_7.27.6_php56-70.bin">
                    https://downloads.ezeelogin.com/ezlogin_7.27.6_php56-70.bin
                  </a>
                </td>
              </tr>
              <tr>
                <td>For PHP version 5.3 to 5.5 (end of life):</td>
                <td>
                  <a href="https://downloads.ezeelogin.com/ezlogin_7.10.0_php53-55.bin">
                    https://downloads.ezeelogin.com/ezlogin_7.10.0_php53-55.bin
                  </a>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className="ui warning message">
                    <strong>NOTE:</strong> 7.36.0 is the last version that
                    supports PHP versions 7.x, 7.27.6 is the last version that
                    supports PHP versions from 5.6 to 7.0 and 7.10.0 is the
                    last version that supports PHP versions from 5.3 to 5.5
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <div className="ui info message">
          <strong>NOTE:</strong> You can create agent account for anyone who may
          need to manage your licenses. Every agent can login to the billing
          system and have the same privileges as your primary agent account to
          view and manage your licenses, invoices etc. However, saved credit
          card information cannot be viewed as they are not stored and not
          retrievable. But the saved card may be used to make payments within
          the billing system.
        </div>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  user: state.auth.user,
  latest: state.setting.latest,
  customer: state.customer.data,
  hasTrial: state.license.hasTrial
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      readSetting,
      readCustomer,
      checkTrial
    }
  )(Dashboard)
);
