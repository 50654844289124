import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem,
  autocomplete,
  getSuggestedItem
} from "../../actions";
import * as validations from "../../lib/validations";
import SimpleHeader from "../../components/SimpleHeader";
import AutocompleteInput from "../AutocompleteInput";

class CreditForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  model = "credit";

  componentWillMount = () => {
    const { resetStatusMessage, formResetError } = this.props;
    this.credit = {};
    resetStatusMessage();
    formResetError();
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, createItem } = this.props;
    let hasError = false,
      amount = this.amountField.value.trim(),
      description = this.descriptionField.value.trim();
    formResetError();
    if (!this.customer) {
      formSetError("customer");
      hasError = true;
    }
    if (amount) {
      amount = parseInt(amount, 10);
      if (isNaN(amount) || amount < 1 || amount > 9999) {
        formSetError("amount");
        hasError = true;
      }
    }
    if (!description || !validations.standardText(description)) {
      formSetError("description");
      hasError = true;
    }
    if (!hasError) {
      const credit = {
        _id: this.credit._id,
        customer: this.customer,
        amount,
        description
      };
      createItem(this.model, credit);
    }
    return false;
  };

  render = () => {
    const { history, form, getSuggestedItem } = this.props,
      credit = this.credit;
    if ("undefined" === typeof credit) {
      return null;
    }
    if (credit.customer) {
      this.customer = credit.customer;
    }
    return (
      <article className="thirteen wide column">
        <SimpleHeader title="Add credit" />
        <div className="ui">
          {form.hasError && (
            <div className="ui error message">
              <ul className="list">
                {form.errorFields.customer && <li>Select a customer</li>}
                {form.errorFields.amount && (
                  <li>Amount must be between 1 and 9999</li>
                )}
                {form.errorFields.description && (
                  <li>Enter a valid description</li>
                )}
              </ul>
            </div>
          )}
          <form className="ui form" onSubmit={this.submit}>
            <AutocompleteInput
              title="Customer"
              count={3}
              tabIndex={10}
              selectedItem={credit.customer}
              getSuggestedItem={id => {
                getSuggestedItem("customer", id, { _id: 1, company: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              hasError={form.errorFields.customer}
              getName={customer => customer.company}
              selectItem={id => {
                this.customer = id;
              }}
              autoFocus={true}
            />
            <div
              className={"field" + (form.errorFields.amount ? " error" : "")}
            >
              <label htmlFor="amount">Amount</label>
              <input
                type="number"
                tabIndex="20"
                name="amount"
                id="amount"
                placeholder="Credit amount"
                autoComplete="off"
                ref={input => (this.amountField = input)}
                defaultValue={credit.amount}
              />
            </div>
            <div
              className={
                "required field" +
                (form.errorFields.description ? " error" : "")
              }
            >
              <label htmlFor="description">Description</label>
              <input
                type="text"
                tabIndex="30"
                name="description"
                id="description"
                placeholder="Description"
                autoComplete="off"
                ref={input => (this.descriptionField = input)}
                defaultValue={credit.description}
              />
            </div>
            <button
              id="cancel"
              tabIndex="210"
              className="ui negative button"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
            <button tabIndex="200" className="ui primary button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      resetStatusMessage,
      formSetError,
      formResetError,
      createItem,
      autocomplete,
      getSuggestedItem
    }
  )(CreditForm)
);
