import { combineReducers } from "redux";
import auth from "./auth";
import ui from "./ui";
import listing from "./listing";
import item from "./item";
import license from "./license";
import invoice from "./invoice";
import payment from "./payment";
import customer from "./customer";
import agent from "./agent";
import privacy from "./privacy";
import setting from "./setting";
import recovery from "./recovery";
import { AGENT_LOGOUT, CUSTOMER_TERMINATE_SUCCESS } from "../actions";

const appReducer = combineReducers({
  auth,
  ui,
  listing,
  item,
  license,
  invoice,
  payment,
  customer,
  agent,
  privacy,
  setting,
  recovery
});

const rootReducer = (state, action) => {
  if (
    AGENT_LOGOUT === action.type ||
    CUSTOMER_TERMINATE_SUCCESS === action.type
  ) {
    state = {};
  }

  return appReducer(state, action);
};

export default rootReducer;
