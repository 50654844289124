import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { setLoginMessage } from "../../actions/ui/login";
import {
  formSetError,
  formResetError,
  formSetData,
  formResetData,
  recoverPassword,
  resetPassword,
  requestRegistration,
  registerCustomer
} from "../../actions";
import * as validations from "../../lib/validations";
import config from "../../config";
import PasswordInput from "../PasswordInput";

class OtpForm extends Component {
  static propTypes = {
    // Injected by React-Redux
    login: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired,
    requestRegistration: PropTypes.func.isRequired,
    registerCustomer: PropTypes.func.isRequired,
    recoverPassword: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    setLoginMessage: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    this.props.formSetData("welcome", true);
  };

  onCaptchaChange = response => {
    this.captcha = response;
  };

  onWelcomeChange = () => {
    const { formSetData, formResetData } = this.props;
    this.welcomeField.checked ? formSetData("welcome", true) : formResetData();
  };

  submitRequest = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
        login,
        formSetError,
        formResetError,
        requestRegistration,
        recoverPassword
      } = this.props,
      email = this.emailField.value.trim();
    let hasError = false;
    formResetError();
    if (config.reCaptchaSiteKey && !this.captcha) {
      formSetError("captcha");
      hasError = true;
    }
    if (!validations.email(email)) {
      formSetError("email");
      hasError = true;
    }
    if (!hasError) {
      const data = { email };
      if (this.captcha) {
        data.recaptchaResponse = this.captcha;
      }
      "register" === login.mode
        ? requestRegistration(data)
        : recoverPassword(data);
    }
    return false;
  };

  submitOtp = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
        login,
        formSetError,
        formResetError,
        registerCustomer,
        resetPassword
      } = this.props,
      password = this.passwordField.value,
      otpCode = this.OtpCodeField.value.trim();
    let hasError = false;
    formResetError();
    if (!otpCode) {
      formSetError("otpCode");
    }
    if (config.reCaptchaSiteKey && !this.captcha) {
      formSetError("captcha");
      hasError = true;
    }
    if (!password) {
      formSetError("password");
      hasError = true;
    } else if (!validations.strongPassword(password)) {
      formSetError("weakPassword");
      hasError = true;
    } else if (this.confirmPasswordField.value !== password) {
      formSetError("confirmPassword");
      hasError = true;
    }
    if (!hasError) {
      const data = { otpCode, password };
      // if (this.welcomeField && this.welcomeField.checked) {
      data.welcome = 1;
      // }
      if (this.captcha) {
        data.recaptchaResponse = this.captcha;
      }
      "register" === login.mode ? registerCustomer(data) : resetPassword(data);
    }
    return false;
  };

  renderRequestForm = () => {
    const { login, form, setLoginMessage } = this.props,
      mode = login.mode;
    return (
      <form className="ui form" onSubmit={this.submitRequest}>
        <div
          className={
            "required field" + (form.errorFields.email ? " error" : "")
          }
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            tabIndex="10"
            name="email"
            id="email"
            placeholder="Email address"
            autoFocus={true}
            required={true}
            ref={input => (this.emailField = input)}
          />
        </div>
        {config.reCaptchaSiteKey && (
          <ReCAPTCHA
            ref="recaptcha"
            sitekey={config.reCaptchaSiteKey}
            onChange={this.onCaptchaChange}
          />
        )}
        <button tabIndex="100" className="ui primary button" type="submit">
          Submit
        </button>
        <a
          onClick={() =>
            setLoginMessage(
              "register" === mode
                ? "Use the registration code to create the account and login"
                : "Use the recovery code to reset the password"
            )
          }
        >
          {"register" === mode
            ? "I have the registration code"
            : "I have the recovery code"}
        </a>
        <div className="ui info message">
          <ul>
            {"register" === mode ? (
              <li>
                Whitelist <strong>{config.fromEmail}</strong> to ensure that the
                registration email is delivered to your inbox.
              </li>
            ) : (
              <li>
                Whitelist <strong>{config.fromEmail}</strong> to ensure that the
                recovery email is delivered to your inbox.
              </li>
            )}
            <li>
              You can easily opt out of any type of emails we sent including
              even your account notifications.
            </li>
          </ul>
        </div>
      </form>
    );
  };

  renderOtpForm = () => {
    const { login, form, setLoginMessage } = this.props,
      mode = login.mode;
    return (
      <form className="ui form" onSubmit={this.submitOtp}>
        <div className="ui positive message">
          <p>{login.loginMessage}</p>
        </div>
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.email && <li>Enter a valid email address</li>}
              {form.errorFields.password && <li>Password cannot be empty</li>}
              {form.errorFields.weakPassword && (
                <li>Enter a stronger password</li>
              )}
              {form.errorFields.confirmPassword && (
                <li>Passwords do not match</li>
              )}
              {form.errorFields.otpCode && <li>Invalid code</li>}
              {form.errorFields.captcha && (
                <li>Solve the reCAPTCHA challenge</li>
              )}
            </ul>
          </div>
        )}
        <div
          className={
            "required field" + (form.errorFields.otpCode ? " error" : "")
          }
        >
          <label htmlFor="otpCode">
            {"register" === mode ? "Registration code" : "Recovery code"}
          </label>
          <input
            type="text"
            tabIndex="10"
            name="otpCode"
            id="otpCode"
            placeholder="Code"
            autoFocus={true}
            required={true}
            ref={input => (this.OtpCodeField = input)}
          />
        </div>
        <PasswordInput
          label="New password"
          tabIndex={20}
          required={true}
          inputRef={input => (this.passwordField = input)}
        />
        <div
          className={
            "required field" +
            (form.errorFields.confirmPassword ? " error" : "")
          }
        >
          <label htmlFor="password">Confirm password</label>
          <input
            type="password"
            tabIndex="30"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Password again"
            required={true}
            ref={input => (this.confirmPasswordField = input)}
          />
        </div>
        {/* "register" === mode && (
          <div className="inline field">
            <div className="ui checkbox">
              <input
                id="welcome"
                name="welcome"
                type="checkbox"
                tabIndex="40"
                ref={input => (this.welcomeField = input)}
                defaultChecked={true}
                onChange={this.onWelcomeChange}
              />
              <label htmlFor="welcome">Send me a welcome email</label>
            </div>
          </div>
        ) */}
        {"register" === mode && form.dataFields.welcome && (
          <div className="ui info message">
            <ul>
              <li>
                Whitelist <strong>{config.fromEmail}</strong> to ensure that the
                welcome email is delivered to your inbox.
              </li>
              <li>
                You can easily opt out of any type of emails we sent including
                your account notifications.
              </li>
            </ul>
          </div>
        )}
        {config.reCaptchaSiteKey && (
          <ReCAPTCHA
            ref="recaptcha"
            sitekey={config.reCaptchaSiteKey}
            onChange={this.onCaptchaChange}
          />
        )}
        <button tabIndex="100" className="ui primary button" type="submit">
          Submit
        </button>
        <a onClick={() => setLoginMessage("")}>
          {"register" === mode
            ? "I don't have the registration code"
            : "I don't have the recovery code"}
        </a>
      </form>
    );
  };

  render = () => {
    const { loginMessage, resetSuccess, mode } = this.props.login;
    if ("forgot" === mode && resetSuccess) {
      return (
        <div className="ui positive message">
          <p>Password has been reset</p>
        </div>
      );
    }
    return loginMessage ? this.renderOtpForm() : this.renderRequestForm();
  };
}

const mapStateToProps = state => ({
  login: state.ui.login,
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    formSetError,
    formResetError,
    formSetData,
    formResetData,
    requestRegistration,
    registerCustomer,
    recoverPassword,
    resetPassword,
    setLoginMessage
  }
)(OtpForm);
