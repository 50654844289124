import { CALL_SOCKET_API } from "../middleware/agent-api";

export const SETTING_READ_REQUEST = "SETTING_READ_REQUEST";
export const SETTING_READ_SUCCESS = "SETTING_READ_SUCCESS";
export const SETTING_READ_FAILURE = "SETTING_READ_FAILURE";
export const readSetting = (data = {}) => ({
  [CALL_SOCKET_API]: {
    types: [SETTING_READ_REQUEST, SETTING_READ_SUCCESS, SETTING_READ_FAILURE],
    endpoint: "readSetting",
    data
  }
});
