export const getNetworkClassInfo = ip => {
  const firstOctet = parseInt(ip.substring(0, ip.indexOf(".")), 10);
  let networkClass = "invalid",
    firstOctetRange = "0",
    defaultSubnetMask = 0,
    networkType = "invalid";
  if (firstOctet >= 1 && firstOctet <= 126) {
    networkClass = "Class A";
    firstOctetRange = "1 - 126";
    networkType = "public";
    defaultSubnetMask = 4278190080;
  } else if (firstOctet === 127) {
    networkClass = "Class A";
    firstOctetRange = "127";
    networkType = "loopback";
    defaultSubnetMask = 4278190080;
  } else if (firstOctet >= 128 && firstOctet <= 191) {
    networkClass = "Class B";
    firstOctetRange = "128 - 191";
    networkType = "public";
    defaultSubnetMask = 4294901760;
  } else if (firstOctet >= 192 && firstOctet <= 223) {
    networkClass = "Class C";
    firstOctetRange = "192 - 223";
    networkType = "public";
    defaultSubnetMask = 4294967040;
  } else if (firstOctet >= 224 && firstOctet <= 239) {
    networkClass = "Class D (Multicast)";
    networkType = "reserved";
    firstOctetRange = "224 - 239";
  } else if (firstOctet >= 240 && firstOctet <= 255) {
    networkClass = "Class E (Experimental)";
    networkType = "reserved";
    firstOctetRange = "240 - 254";
  }
  if (/^(10\.)|(172\.16\.)|(192\.168\.)/.test(ip)) {
    networkType = "private";
  }

  return {
    networkClass,
    firstOctetRange,
    networkType,
    defaultSubnetMask
  };
};
