import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Form from "./Form";

class StandaloneLicense extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired
  };

  model = "build";

  render = () => {
    const { history } = this.props;
    return (
      <article className="thirteen wide column">
        <Header title="Download Standalone License" />
        <Form onCancel={() => history.goBack()} />
      </article>
    );
  };
}

export default withRouter(StandaloneLicense);
