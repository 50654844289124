import React, { Component } from "react";
import PropTypes from "prop-types";

class SettingSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const name = this.nameField.value.trim(),
      description = this.descriptionField.value,
      params = {};
    if (name) {
      params.name = name;
    }
    if (description) {
      params.description = description;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.nameField.value = "";
    this.descriptionField.value = "";
    this.props.onSearch({});
  };

  render = () => {
    const { search } = this.props;
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="two fields">
          <div className="field">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              tabIndex="10"
              name="name"
              id="name"
              placeholder="Name"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.name}
              ref={input => (this.nameField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              tabIndex="20"
              name="description"
              id="description"
              autoComplete="off"
              defaultValue={search.description}
              ref={input => (this.descriptionField = input)}
            />
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default SettingSearch;
