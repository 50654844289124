import React, { Component } from "react";
import PropTypes from "prop-types";

class BuildSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const id = this.idField.value.trim(),
      oldId = this.oldIdField.value.trim(),
      params = {};
    if (id) {
      params._id = id;
    }
    if (oldId) {
      params.oldId = oldId;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.idField.value = "";
    this.oldIdField.value = "";
    this.props.onSearch({});
  };

  render = () => {
    const { search } = this.props;
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="two fields">
          <div className="field">
            <label htmlFor="id">ID</label>
            <input
              type="text"
              tabIndex="10"
              name="id"
              id="id"
              placeholder="Exact ID"
              autoComplete="off"
              defaultValue={search._id}
              ref={input => (this.idField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="oldId">Old ID</label>
            <input
              type="text"
              tabIndex="20"
              name="oldId"
              id="oldId"
              placeholder="Exact old ID"
              defaultValue={search.oldId}
              ref={input => (this.oldIdField = input)}
            />
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default BuildSearch;
