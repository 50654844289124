// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  readStripePaymentOption,
  readCustomer,
  updateCustomer,
  confirmDeleteCard,
  cancelDeleteCard,
  deleteCard,
  formToggleInfo,
} from "../../actions";
import { getById } from "../../reducers/payment";
import List from "../../components/List";
import Header from "../../components/Header";
import Confirmation from "../../components/Confirmation";

class PaymentListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    payment: PropTypes.object.isRequired,
    customer: PropTypes.object,
    infoFields: PropTypes.object.isRequired,
    readStripePaymentOption: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    updateCustomer: PropTypes.func.isRequired,
    confirmDeleteCard: PropTypes.func.isRequired,
    cancelDeleteCard: PropTypes.func.isRequired,
    deleteCard: PropTypes.func.isRequired,
    formToggleInfo: PropTypes.func.isRequired,
  };

  componentWillMount = () => {
    const {
      payment,
      customer,
      readStripePaymentOption,
      readCustomer,
    } = this.props;
    if (!payment.cards.length) {
      readStripePaymentOption();
    }
    if (!customer) {
      readCustomer();
    }
  };

  refresh = () => {
    const { readStripePaymentOption, readCustomer } = this.props;
    readStripePaymentOption();
    readCustomer();
  };

  render() {
    const {
        history,
        payment,
        customer,
        infoFields,
        updateCustomer,
        confirmDeleteCard,
        cancelDeleteCard,
        deleteCard,
        formToggleInfo,
      } = this.props,
      deleteCardId = payment.delete,
      deleteCardInfo = deleteCardId ? getById(payment, deleteCardId) : null,
      defaultCardInfo =
        customer && customer.defaultCard
          ? getById(payment, customer.defaultCard)
          : null,
      fields = {
        serial: { title: "#", type: "serial" },
        brand: { title: "Brand" },
        last4: { title: "Ending in" },
        exp_month: { title: "Expiry month" },
        exp_year: { title: "Expiry year" },
        name: { title: "Name on card" },
        country: { title: "Country" },
        address_zip: { title: "Zip" },
        actions: { title: "Actions", type: "actions" },
      },
      actions = [
        {
          name: "View",
          type: "primary",
          icon: "eye",
          handler: (id) => {
            history.push(`/payment/view/${id}`);
          },
        },
        {
          name: "Set as default",
          type: "positive",
          icon: "check circle",
          handler: (id) => {
            updateCustomer({
              document: { _id: customer._id, defaultCard: id },
            });
          },
        },
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: (id) => {
            confirmDeleteCard(id);
          },
        },
      ];
    return (
      <article className="thirteen wide column">
        <Header
          title="Payment options"
          isLoading={payment.loading}
          onRefresh={() => {
            this.refresh();
          }}
          onAdd={() => {
            history.push(`/payment/add`);
          }}
        />
        <div className="ui">
          {defaultCardInfo && (
            <div className="ui segment">
              <strong>Default:</strong> {defaultCardInfo.brand} ending in{" "}
              {defaultCardInfo.last4}{" "}
              <i
                className="info circle blue icon"
                title="Click for more info"
                onClick={() => formToggleInfo("defaultCard")}
              />
              <div
                className={
                  (infoFields.defaultCard ? "" : "hidden ") + "ui info message"
                }
              >
                The default card shall be used for processing recurring
                payments.
              </div>
            </div>
          )}
          {null !== deleteCardId && (
            <Confirmation
              message={`Are you sure you want to delete ${deleteCardInfo.brand} ending in ${deleteCardInfo.last4}`}
              onNo={cancelDeleteCard}
              onYes={() => deleteCard(deleteCardId)}
            />
          )}
          <List fields={fields} records={payment.cards} actions={actions} />
        </div>
      </article>
    );
  }
}

const mapStateToProps = (state) => ({
  payment: state.payment,
  customer: state.customer.data,
  infoFields: state.ui.form.infoFields,
});

export default withRouter(
  connect(mapStateToProps, {
    readStripePaymentOption,
    readCustomer,
    updateCustomer,
    confirmDeleteCard,
    cancelDeleteCard,
    deleteCard,
    formToggleInfo,
  })(PaymentListing)
);
