// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel } from "../../actions/listing";
import CouponSearch from "./CouponSearch";
import Listing from "../Listing";

class CouponListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired,
  };

  model = "coupon";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        description: { title: "Description", sortable: true },
        percentage: { title: "Percent", type: "number", sortable: true },
        amount: { title: "Amount", type: "number", sortable: true },
        newOnly: { title: "New only", type: "bool", sortable: true },
        recurring: { title: "Recurring", type: "bool", sortable: true },
        active: { title: "Active", type: "bool", sortable: true },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true,
        },
        actions: { title: "Actions", type: "actions" },
      },
      actions = [
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: (id) => {
            history.push(`/${this.model}/edit/${id}`);
          },
        },
      ];
    return (
      <Listing
        title="Coupons"
        model={this.model}
        mainField="description"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ description: 1 }}
        // search form component
        ItemSearch={CouponSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Description"
        getSimpleSearch={(search) => ({
          description: search,
        })}
      />
    );
  }
}

export default withRouter(
  connect(null, {
    setItemModel,
  })(CouponListing)
);
