import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem
} from "../../actions";

class Form extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired
  };

  model = "build";

  componentWillMount = () => {
    const { resetStatusMessage, formResetError } = this.props;
    resetStatusMessage();
    formResetError();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, createItem } = this.props,
      oldId = this.oldIdField.value.trim(),
      pslt = this.psltField.value.trim(),
      sslt = this.ssltField.value.trim(),
      ksec = this.ksecField.value.trim(),
      pbk = this.pbkField.value.trim(),
      prk = this.prkField.value.trim();
    let hasError = false;
    formResetError();
    if (!pslt) {
      formSetError("pslt");
      hasError = true;
    }
    if (!sslt) {
      formSetError("sslt");
      hasError = true;
    }
    if (!ksec) {
      formSetError("ksec");
      hasError = true;
    }
    if (!pbk) {
      formSetError("pbk");
      hasError = true;
    }
    if (!prk) {
      formSetError("prk");
      hasError = true;
    }
    if (!hasError) {
      const data = { config: JSON.stringify({ pslt, sslt, ksec, pbk, prk }) };
      if (oldId) {
        data.oldId = oldId;
      }
      createItem(this.model, data);
    }
    return false;
  };

  reset = () => {
    this.oldIdField.value = "";
    this.psltField.value = "";
    this.ssltField.value = "";
    this.ksecField.value = "";
    this.pbkField.value = "";
    this.prkField.value = "";
  };

  render = () => {
    const { form, onCancel } = this.props;
    return (
      <div className="ui">
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.pslt && <li>Enter a PSLT</li>}
              {form.errorFields.sslt && <li>Enter a SSLT</li>}
              {form.errorFields.ksec && <li>Enter a KSEC</li>}
              {form.errorFields.pbk && <li>Enter a PBK</li>}
              {form.errorFields.prk && <li>Enter a PRK</li>}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div className="field">
            <label htmlFor="oldId">Old ID</label>
            <input
              type="text"
              tabIndex="10"
              name="oldId"
              id="oldId"
              autoComplete="off"
              ref={input => (this.oldIdField = input)}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.pslt ? " error" : "")
            }
          >
            <label htmlFor="pslt">PSLT</label>
            <input
              type="text"
              tabIndex="20"
              name="pslt"
              id="pslt"
              autoComplete="off"
              ref={input => (this.psltField = input)}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.sslt ? " error" : "")
            }
          >
            <label htmlFor="sslt">SSLT</label>
            <input
              type="text"
              tabIndex="30"
              name="sslt"
              id="sslt"
              autoComplete="off"
              ref={input => (this.ssltField = input)}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.ksec ? " error" : "")
            }
          >
            <label htmlFor="ksec">KSEC</label>
            <input
              type="text"
              tabIndex="40"
              name="ksec"
              id="ksec"
              autoComplete="off"
              ref={input => (this.ksecField = input)}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.pbk ? " error" : "")
            }
          >
            <label htmlFor="pbk">PBK</label>
            <textarea
              tabIndex="50"
              name="pbk"
              id="pbk"
              ref={input => (this.pbkField = input)}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.prk ? " error" : "")
            }
          >
            <label htmlFor="prk">PRK</label>
            <textarea
              tabIndex="60"
              name="prk"
              id="prk"
              ref={input => (this.prkField = input)}
            />
          </div>
          <button
            id="cancelBuild"
            tabIndex="210"
            className="ui negative button"
            type="button"
            onClick={onCancel ? onCancel : this.reset}
          >
            Cancel
          </button>
          <button tabIndex="200" className="ui primary button" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    resetStatusMessage,
    formSetError,
    formResetError,
    createItem
  }
)(Form);
