import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getItem } from "../../actions/item";
import Form from "./Form";
import Header from "../../components/Header";

class CustomerForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    item: PropTypes.object.isRequired,
    getItem: PropTypes.func.isRequired
  };

  model = "customer";

  componentWillMount = () => {
    const { match } = this.props;
    this.edit = false;
    if (match.params.id) {
      this.edit = true;
    }
    this.refresh();
  };

  refresh = () => {
    const { match, getItem } = this.props;
    if (match.params.id) {
      getItem(this.model, match.params.id);
    }
  };

  render = () => {
    const { history, item } = this.props;
    return (
      <article className="thirteen wide column">
        <Header
          title={this.edit ? "Edit customer" : "Add customer"}
          isLoading={this.edit ? item.loading : null}
          onRefresh={this.edit ? this.refresh : null}
        />
        <Form
          customer={this.edit ? item.data : {}}
          showCredit={this.edit}
          onCancel={() => history.goBack()}
        />
      </article>
    );
  };
}

const mapStateToProps = state => ({
  item: state.item
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getItem
    }
  )(CustomerForm)
);
