// cSpell:ignore normalizr
import { normalize } from "normalizr";
import * as schema from "../middleware/schema";
import {
  ITEM_SEARCH,
  ITEM_SORT,
  ITEM_SET_PAGE,
  ITEM_RESET_PAGE,
  ITEM_SET_MODEL,
  ITEM_READ_REQUEST,
  ITEM_READ_SUCCESS,
  ITEM_READ_FAILURE,
  ITEM_CREATE_SUCCESS,
  ITEM_UPDATE_SUCCESS,
  ITEM_DELETE,
  ITEM_DELETE_CANCEL,
  ITEM_DELETE_SUCCESS,
  ITEM_SET_PER_PAGE
} from "../actions/listing";

const listing = (
  state = {
    model: "",
    loading: false,
    perPage: 10,
    page: 1,
    total: 0,
    byId: {},
    pageIds: {},
    delete: null,
    search: {},
    sortBy: {}
  },
  action
) => {
  let normalizedData = null;
  switch (action.type) {
    case ITEM_SEARCH:
      return {
        ...state,
        search: action.search,
        byId: {} // clear cache on search change
      };
    case ITEM_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        byId: {} // clear cache on sort change
      };
    case ITEM_SET_PAGE:
      return {
        ...state,
        page: action.page
      };
    case ITEM_SET_MODEL:
      return {
        ...state,
        model: action.model,
        perPage: 10,
        page: 1,
        total: 0,
        byId: {},
        pageIds: {},
        delete: null,
        search: {},
        sortBy: {}
      };
    case ITEM_READ_REQUEST:
      return { ...state, loading: true };
    case ITEM_READ_SUCCESS:
      const { skip, limit, data, total } = action.response;
      if (limit !== state.perPage) {
        // ignore if perPage has changed
        return state;
      }
      const page = skip / limit + 1;
      normalizedData = normalize(data, schema.items);
      return {
        ...state,
        loading: false,
        byId: normalizedData.entities.items
          ? { ...state.byId, ...normalizedData.entities.items }
          : state.byId,
        pageIds: { ...state.pageIds, [page]: normalizedData.result },
        total: total ? total : state.total,
        delete: null
      };
    case ITEM_READ_FAILURE:
      return {
        ...state,
        loading: false
      };
    case ITEM_RESET_PAGE:
      return {
        ...state,
        page: 1,
        total: 0,
        pageIds: {}
      };
    case ITEM_CREATE_SUCCESS:
      return {
        ...state,
        total: state.total + 1
      };
    case ITEM_UPDATE_SUCCESS:
      normalizedData = normalize(action.response.data, schema.item);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...normalizedData.entities.items
        }
      };
    case ITEM_DELETE:
      return {
        ...state,
        delete: action.id
      };
    case ITEM_DELETE_CANCEL:
      return {
        ...state,
        delete: null
      };
    case ITEM_DELETE_SUCCESS:
      const id = action.response.data._id,
        { [id]: omit, ...rest } = state.byId;
      return {
        ...state,
        byId: rest,
        total: state.total - 1,
        delete: null
      };
    case ITEM_SET_PER_PAGE:
      const perPage = parseInt(action.perPage, 10);
      return {
        ...state,
        perPage: isNaN(perPage) ? 10 : perPage,
        byId: {} // clear cache on rows per page change
      };
    default:
      return state;
  }
};

export default listing;

export const getById = (state, id) => state.byId[id];
export const getAll = state => state.byId;
export const hasPageData = (state, page) => state.pageIds[page];
export const getCurrentPageData = state => {
  const pageItemIds = state.pageIds[state.page];
  if (!pageItemIds || !pageItemIds.length) {
    return [];
  }
  const items = state.byId;
  return pageItemIds.filter(id => items[id]).map(id => items[id]);
};
