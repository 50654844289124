import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const CardInfo = ({ card }) => (
  <table className="ui celled table">
    <thead>
      <tr>
        <th colSpan="2">
          {card.brand} ending in {card.last4}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Expiry</td>
        <td>
          {moment()
            .month(card.exp_month)
            .year(card.exp_year)
            .format("MMMM, YYYY")}
        </td>
      </tr>
      <tr>
        <td>Name on card</td>
        <td>{card.name}</td>
      </tr>
      <tr>
        <td>Funding</td>
        <td>{card.funding}</td>
      </tr>
      {card.country && (
        <tr>
          <td>Country</td>
          <td>{card.country}</td>
        </tr>
      )}
      {card.address_zip && (
        <tr>
          <td>Zip</td>
          <td>{card.address_zip}</td>
        </tr>
      )}
      {card.address_state && (
        <tr>
          <td>Address</td>
          <td>
            {card.address_line1}
            <br />
            {card.address_line2}
            <br />
            {card.address_city}
            <br />
            {card.address_state}
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

CardInfo.propTypes = {
  card: PropTypes.object.isRequired
};

export default CardInfo;
