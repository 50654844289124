export const SHOW_PROGRESS = "SHOW_PROGRESS";
export const HIDE_PROGRESS = "HIDE_PROGRESS";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const showProgress = (label, percent) => ({
  type: SHOW_PROGRESS,
  label,
  percent
});
export const hideProgress = () => ({ type: HIDE_PROGRESS });
export const updateProgress = percent => ({
  type: UPDATE_PROGRESS,
  percent
});
