import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const LeftBar = ({ isAdmin, isAnyStaff }) => (
  <nav className="ui left fixed vertical fluid menu three wide column">
    <NavLink to="/" exact={true} id="navDashboard" className="item">
      Dashboard
    </NavLink>
    <NavLink to="/license" id="navLicense" className="item">
      Licenses
    </NavLink>
    <NavLink to="/order" id="navOrder" className="item">
      Orders
    </NavLink>
    <NavLink to="/invoice" id="navInvoice" className="item">
      Invoices
    </NavLink>
    {!isAnyStaff && (
      <NavLink to="/payment" id="navPayment" className="item">
        Payment options
      </NavLink>
    )}
    {isAnyStaff && (
      <NavLink to="/coupon" id="navCoupon" className="item">
        Coupons
      </NavLink>
    )}
    <NavLink to="/account" id="navAccount" className="item">
      My account
    </NavLink>
    <NavLink to="/agent" id="navAgent" className="item">
      Agents
    </NavLink>
    {isAnyStaff && (
      <NavLink to="/customer" id="navCustomer" className="item">
        Customers
      </NavLink>
    )}
    {isAnyStaff && (
      <NavLink to="/dnd" id="navDnd" className="item">
        DND
      </NavLink>
    )}
    <NavLink to="/credit" id="navCredit" className="item">
      Credits
    </NavLink>
    {isAdmin && (
      <NavLink to="/build" id="navBuild" className="item">
        Builds
      </NavLink>
    )}
    {isAdmin && (
      <NavLink to="/blacklist" id="navBlacklist" className="item">
        Blacklist
      </NavLink>
    )}
    {isAdmin && (
      <NavLink to="/setting" id="navSetting" className="item">
        Settings
      </NavLink>
    )}
    {isAnyStaff && (
      <NavLink to="/liveagent" id="navLiveagent" className="item">
        Live agents
      </NavLink>
    )}
    {isAdmin && (
      <NavLink to="/standalone" id="navStandalone" className="item">
        Standalone
      </NavLink>
    )}
    <a
      id="navKb"
      className="item"
      href="https://ezeelogin.com/kb"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="external alternate icon" />
      Knowledgebase
    </a>
    <a
      id="navHelpdesk"
      className="item"
      href="https://ezeelogin.com/support/en/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="external alternate icon" />
      Helpdesk
    </a>
    <a
      id="navWebsite"
      className="item"
      href="https://ezeelogin.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="external alternate icon" />
      Website
    </a>
  </nav>
);

LeftBar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAnyStaff: PropTypes.bool.isRequired,
};

export default LeftBar;
