import {
  AGENT_READ_REQUEST,
  AGENT_READ_SUCCESS,
  AGENT_READ_FAILURE,
  ITEM_UPDATE_SUCCESS
} from "../actions";

const agent = (
  state = {
    loading: false,
    data: null
  },
  action
) => {
  switch (action.type) {
    case AGENT_READ_REQUEST:
      /* clear previous data if any (fix profile edit after updating another agent) */
      return { ...state, data: null, loading: true };
    case AGENT_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };
    case ITEM_UPDATE_SUCCESS:
      if ("agent" === action.model) {
        return {
          ...state,
          data: action.response.data
        };
      }
      return state;
    case AGENT_READ_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default agent;
