const pick = (source, min, max) => {
  let n,
    chars = "";

  if ("undefined" === typeof max) {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min + 1));
  }

  for (let i = 0; i < n; i++) {
    chars += source.charAt(Math.floor(Math.random() * source.length));
  }

  return chars;
};

const shuffle = source => {
  let array = source.split(""),
    tmp,
    current,
    top = array.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

  return array.join("");
};

/**
 * Checks for repetition of characters in
 * a string
 *
 * @param int rLen Repetition length.
 * @param string str The string to be checked.
 * @return string
 */
const checkRepetition = (rLen, str) => {
  let res = "",
    repeated = false,
    i = 0,
    j = 0;
  for (i = 0; i < str.length; i++) {
    repeated = true;
    for (j = 0; j < rLen && j + i + rLen < str.length; j++) {
      repeated = repeated && str.charAt(j + i) === str.charAt(j + i + rLen);
    }
    if (j < rLen) {
      repeated = false;
    }
    if (repeated) {
      i += rLen - 1;
      repeated = false;
    } else {
      res += str.charAt(i);
    }
  }
  return res;
};

export const generatePassword = () => {
  const specials = "!@#$%^&*()_+{}:\"<>?|[];',./`~";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const all = specials + lowercase + uppercase + numbers;
  let password = "";

  password += pick(specials, 1, 2);
  password += pick(lowercase, 2, 5);
  password += pick(uppercase, 1, 2);
  password += pick(numbers, 1, 2);
  password += all.pick(5, 10);
  return shuffle(password);
};

/**
 * Score a password on a scale of 100.
 * Adapted from https://github.com/elboletaire/password-strength-meter
 */
export const scorePassword = password => {
  let score = 0;
  if (!password || password.length < 8) return score;

  // password length
  score += password.length * 4;
  score += checkRepetition(1, password).length - password.length;
  score += checkRepetition(2, password).length - password.length;
  score += checkRepetition(3, password).length - password.length;
  score += checkRepetition(4, password).length - password.length;

  // password has 3 numbers
  if (password.match(/(.*[0-9].*[0-9].*[0-9])/)) {
    score += 5;
  }

  // password has at least 2 symbols
  var symbols = ".*[!,@,#,$,%,^,&,*,?,_,~]";
  symbols = new RegExp("(" + symbols + symbols + ")");
  if (password.match(symbols)) {
    score += 5;
  }

  // password has Upper and Lower chars
  if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    score += 10;
  }

  // password has number and chars
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    score += 15;
  }

  // password has number and symbol
  if (
    password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) &&
    password.match(/([0-9])/)
  ) {
    score += 15;
  }

  // password has char and symbol
  if (
    password.match(/([!,@,#,$,%,^,&,*,?,_,~])/) &&
    password.match(/([a-zA-Z])/)
  ) {
    score += 15;
  }

  // password is just numbers or chars
  if (password.match(/^\w+$/) || password.match(/^\d+$/)) {
    score -= 10;
  }

  if (score > 100) {
    score = 100;
  }

  return Math.floor(score);
};

export const getScoreMessage = score => {
  let message = "Very weak";
  if (score > 80) {
    message = "Very strong";
  } else if (score > 60) {
    message = "Strong";
  } else if (score > 40) {
    message = "Weak";
  }
  return message;
};
