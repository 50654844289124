import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import agentApi, { agentSocketApi } from "../middleware/agent-api";
import rootReducer from "../reducers";
import { loadState, saveState } from "../lib/localStorage";
import throttle from "lodash/throttle";

const configureStore = (persist = false, initialState = {}) => {
  const persistedState = persist ? loadState() : initialState;
  const store = createStore(
    rootReducer,
    persistedState,
    // Also apply our middleware for navigating
    applyMiddleware(thunk, agentApi, agentSocketApi)
  );

  persist &&
    store.subscribe(
      throttle(() => {
        // NOTE: save only the app state, not the UI state
        const state = store.getState();
        saveState({
          auth: state.auth,
        });
      }, 1000)
    );
  return store;
};

export default configureStore;
