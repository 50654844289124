// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel, confirmDeleteItem } from "../../actions/listing";
import Listing from "../Listing";
import SettingSearch from "./SettingSearch";

class SettingListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    setItemModel: PropTypes.func.isRequired,
  };

  model = "setting";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, confirmDeleteItem } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        name: { title: "Name", sortable: true },
        description: { title: "Description" },
        value: { title: "Value" },
        created: {
          title: "Created",
          type: "date",
          format: "YYYY-MM-DD hh:mm",
          sortable: true,
        },
        modified: {
          title: "Modified",
          type: "date",
          format: "YYYY-MM-DD hh:mm",
          sortable: true,
        },
        actions: { title: "Actions", type: "actions" },
      },
      actions = [
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: (id) => {
            history.push(`/${this.model}/edit/${id}`);
          },
        },
        {
          name: "Delete",
          type: "negative",
          icon: "trash",
          handler: (id) => {
            confirmDeleteItem(id);
          },
        },
      ];
    return (
      <Listing
        title="Settings"
        model={this.model}
        mainField="name"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ name: 1, description: 1 }}
        // search form component
        ItemSearch={SettingSearch}
        onAdd={() => {
          history.push(`/${this.model}/add`);
        }}
        simpleSearchTitle="Name"
        getSimpleSearch={(search) => ({
          name: search,
        })}
      />
    );
  }
}

export default withRouter(
  connect(null, {
    setItemModel,
    confirmDeleteItem,
  })(SettingListing)
);
