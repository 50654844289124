import { CALL_SOCKET_API } from "../middleware/agent-api";

export const CUSTOMER_AUTOCOMPLETE_REQUEST = "CUSTOMER_AUTOCOMPLETE_REQUEST";
export const CUSTOMER_AUTOCOMPLETE_SUCCESS = "CUSTOMER_AUTOCOMPLETE_SUCCESS";
export const CUSTOMER_AUTOCOMPLETE_FAILURE = "CUSTOMER_AUTOCOMPLETE_FAILURE";
export const autocompleteCustomer = (search, count) => ({
  [CALL_SOCKET_API]: {
    types: [
      CUSTOMER_AUTOCOMPLETE_REQUEST,
      CUSTOMER_AUTOCOMPLETE_SUCCESS,
      CUSTOMER_AUTOCOMPLETE_FAILURE
    ],
    endpoint: "readCustomer",
    nonBlocking: true,
    data: {
      search: { company: search },
      fields: { _id: 1, company: 1 },
      limit: count
    }
  }
});

export const CUSTOMER_READ_REQUEST = "CUSTOMER_READ_REQUEST";
export const CUSTOMER_READ_SUCCESS = "CUSTOMER_READ_SUCCESS";
export const CUSTOMER_READ_FAILURE = "CUSTOMER_READ_FAILURE";
export const readCustomer = () => ({
  [CALL_SOCKET_API]: {
    types: [
      CUSTOMER_READ_REQUEST,
      CUSTOMER_READ_SUCCESS,
      CUSTOMER_READ_FAILURE
    ],
    endpoint: "readCustomer",
    nonBlocking: true
  }
});

export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILURE = "CUSTOMER_UPDATE_FAILURE";
export const updateCustomer = data => ({
  [CALL_SOCKET_API]: {
    types: [
      CUSTOMER_UPDATE_REQUEST,
      CUSTOMER_UPDATE_SUCCESS,
      CUSTOMER_UPDATE_FAILURE
    ],
    endpoint: "updateCustomer",
    data
  }
});

export const CUSTOMER_TERMINATE_REQUEST = "CUSTOMER_TERMINATE_REQUEST";
export const CUSTOMER_TERMINATE_SUCCESS = "CUSTOMER_TERMINATE_SUCCESS";
export const CUSTOMER_TERMINATE_FAILURE = "CUSTOMER_TERMINATE_FAILURE";
export const terminateAccount = () => ({
  [CALL_SOCKET_API]: {
    types: [
      CUSTOMER_TERMINATE_REQUEST,
      CUSTOMER_TERMINATE_SUCCESS,
      CUSTOMER_TERMINATE_FAILURE
    ],
    endpoint: "terminateAccount"
  }
});
