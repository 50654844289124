import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  createItem,
  updateItem
} from "../../actions";
import { getById } from "../../reducers/listing";
import { standardText } from "../../lib/validations";
import SimpleHeader from "../../components/SimpleHeader";

class SettingForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired
  };

  model = "setting";

  componentWillMount = () => {
    const {
      history,
      match,
      listing,
      resetStatusMessage,
      formResetError
    } = this.props;
    this.edit = false;
    this.setting = {};
    if (match.params.id) {
      this.setting = getById(listing, match.params.id);
      if ("undefined" === typeof this.setting) {
        history.push("/setting");
      }
      this.edit = true;
    }
    resetStatusMessage();
    formResetError();
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { formSetError, formResetError, createItem, updateItem } = this.props,
      name = this.nameField.value.trim(),
      description = this.descriptionField.value.trim(),
      value = this.valueField.value.trim();
    let hasError = false;
    formResetError();
    if (!name || !standardText(name)) {
      formSetError("name");
      hasError = true;
    }
    if (description && !standardText(description)) {
      formSetError("description");
      hasError = true;
    }
    if (value && !standardText(value)) {
      formSetError("value");
      hasError = true;
    }
    if (!hasError) {
      const setting = {
        _id: this.setting._id,
        name,
        value
      };
      if (description) {
        setting.description = description;
      }
      this.edit
        ? updateItem(this.model, setting)
        : createItem(this.model, setting);
    }
    return false;
  };

  render = () => {
    const { history, form } = this.props,
      setting = this.setting;
    if ("undefined" === typeof setting) {
      return null;
    }
    return (
      <article className="thirteen wide column">
        <SimpleHeader title={this.edit ? "Edit setting" : "Add setting"} />
        <div className="ui">
          {form.hasError && (
            <div className="ui error message">
              <ul className="list">
                {form.errorFields.name && <li>Enter a valid name</li>}
                {form.errorFields.description && (
                  <li>Enter a valid description</li>
                )}
                {form.errorFields.value && <li>Enter a valid value</li>}
              </ul>
            </div>
          )}
          <form className="ui form" onSubmit={this.submit}>
            <div
              className={
                "required field" + (form.errorFields.name ? " error" : "")
              }
            >
              <label htmlFor="name">Name</label>
              <input
                type="text"
                tabIndex="10"
                name="name"
                id="name"
                placeholder="name"
                autoFocus={true}
                ref={input => (this.nameField = input)}
                defaultValue={setting.name}
              />
            </div>
            <div className="field">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                tabIndex="20"
                name="description"
                id="description"
                placeholder="description"
                ref={input => (this.descriptionField = input)}
                defaultValue={setting.description}
              />
            </div>
            <div className="field">
              <label htmlFor="value">Value</label>
              <input
                type="text"
                tabIndex="30"
                name="value"
                id="value"
                placeholder="value"
                ref={input => (this.valueField = input)}
                defaultValue={setting.value}
              />
            </div>
            <button
              id="cancel"
              tabIndex="110"
              className="ui negative button"
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
            <button tabIndex="100" className="ui primary button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </article>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form,
  listing: state.listing
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      resetStatusMessage,
      formSetError,
      formResetError,
      createItem,
      updateItem
    }
  )(SettingForm)
);
