import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import config from "../../config";
import {
  readStripePaymentOption,
  processCreditPayment,
  readCustomer,
  resetStatusMessage,
  setStatusMessage,
  formSetData,
  formResetData,
  formResetError,
  formSetError,
  formResetInfo,
  formToggleInfo
} from "../../actions";
import { standardText } from "../../lib/validations";
import Header from "../../components/Header";

class PayCredit extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    customer: PropTypes.object,
    payment: PropTypes.object.isRequired,
    readStripePaymentOption: PropTypes.func.isRequired,
    processCreditPayment: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    setStatusMessage: PropTypes.func.isRequired,
    formSetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetInfo: PropTypes.func.isRequired,
    formToggleInfo: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const {
      resetStatusMessage,
      formSetData,
      formResetData,
      formResetError,
      formResetInfo
    } = this.props;
    resetStatusMessage();
    formResetData();
    formSetData("cardSave", true);
    formResetError();
    formResetInfo();
    this.refresh();
  };

  refresh = () => {
    const {
      customer,
      payment,
      readStripePaymentOption,
      readCustomer
    } = this.props;
    if (!payment.cards.length) {
      readStripePaymentOption();
    }
    if (!customer) {
      readCustomer();
    }
  };

  getSelectedCard = () => {
    const { form, customer, payment } = this.props,
      defaultCard = customer ? customer.defaultCard : null;
    return "string" === typeof form.dataFields.selectedCard
      ? form.dataFields.selectedCard
      : payment.cards.length
      ? defaultCard
      : "";
  };

  submit = event => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    const {
        stripe,
        processCreditPayment,
        setStatusMessage,
        resetStatusMessage,
        formSetError,
        formResetError
      } = this.props,
      amount = parseInt(this.amountField.value.trim(), 10),
      description = this.descriptionField.value.trim(),
      selectedCard = this.getSelectedCard();
    let hasError = false,
      name = "";
    formResetError();
    if (isNaN(amount) || amount < 1) {
      formSetError("amount");
      hasError = true;
    }
    if (description && !standardText(description)) {
      formSetError("description");
      hasError = true;
    }
    if (this.nameField) {
      name = this.nameField.value.trim();
      if (name && !standardText(name)) {
        formSetError("name");
        hasError = true;
      }
    }
    if (!hasError) {
      let data = { amount };
      if (description) {
        data.description = description;
      }
      if (selectedCard) {
        data.card = selectedCard;
        processCreditPayment(data);
      } else {
        const save = this.saveCardField.checked,
          card = { type: "card" };
        if (name) {
          card.name = name;
        }
        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        stripe
          .createToken(card)
          .then(result => {
            console.log("Stripe result:", result);
            resetStatusMessage();
            if (result.error) {
              formSetError("card", result.error.message);
            } else {
              data.token = result.token.id;
              data.save = save;
              data.default = this.defaultField
                ? this.defaultField.checked
                : false;
              processCreditPayment(data);
            }
          })
          .catch(error => {
            console.error(error);
            setStatusMessage(error.message, "error");
          });
      }
    }
  };

  cardSaveChange = () => {
    this.props.formSetData("cardSave", this.saveCardField.checked);
  };

  render = () => {
    const { history, form, payment, formSetData, formToggleInfo } = this.props,
      selectedCard = this.getSelectedCard();
    return (
      <article className="thirteen wide column">
        <Header title="Add credit" onRefresh={this.refresh} />
        <div className="ui">
          <div className="ui stackable two column grid">
            <div className="column">
              {form.hasError && (
                <div className="ui error message">
                  <ul className="list">
                    {form.errorFields.amount && (
                      <li>Enter a valid amount greater than 1</li>
                    )}
                    {form.errorFields.description && (
                      <li>Enter a valid description</li>
                    )}
                    {form.errorFields.name && <li>Enter a valid name</li>}
                    {!!form.errorFields.card && (
                      <li>{form.errorFields.card}</li>
                    )}
                  </ul>
                </div>
              )}
              <form className="ui form" onSubmit={this.submit}>
                <div
                  className={
                    "required field" + (form.errorFields.amount ? " error" : "")
                  }
                >
                  <label htmlFor="amount">Amount (USD)</label>
                  <input
                    type="number"
                    tabIndex="5"
                    name="amount"
                    id="amount"
                    placeholder="Credit amount"
                    autoComplete="off"
                    ref={input => (this.amountField = input)}
                  />
                </div>
                <div
                  className={
                    "field" + (form.errorFields.description ? " error" : "")
                  }
                >
                  <label htmlFor="description">Description</label>
                  <input
                    type="text"
                    tabIndex="30"
                    name="description"
                    id="description"
                    placeholder="Description"
                    ref={input => (this.descriptionField = input)}
                  />
                </div>
                <button
                  id="cancel"
                  tabIndex="210"
                  className="ui negative button"
                  type="button"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </button>
                <button
                  tabIndex="200"
                  type="submit"
                  className={
                    payment.loading
                      ? "ui primary loading button"
                      : "ui primary button"
                  }
                >
                  Process payment
                </button>
              </form>
            </div>
            <div className="column">
              <div className="ui segment">
                <div
                  className={"ui dimmer" + (payment.loading ? " active" : "")}
                >
                  <div className="ui loader" />
                </div>
                <h3 className="ui header">Payment options</h3>
                <div className="ui middle aligned animated celled selection list">
                  {payment.cards.map(card => (
                    <div
                      className={
                        (selectedCard === card._id ? "disabled " : "") + "item"
                      }
                      key={card._id}
                      onClick={() => {
                        formSetData("selectedCard", card._id);
                      }}
                    >
                      {selectedCard === card._id && (
                        <i className="green check icon" />
                      )}
                      <div className="content">
                        <div className="header">
                          {card.brand} ending in {card.last4}
                        </div>
                        {card.name && (
                          <div className="description">Name: {card.name}</div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className={
                      ("" === selectedCard ? "disabled " : "") + "item"
                    }
                    key="newCard"
                    onClick={() => {
                      formSetData("selectedCard", "");
                    }}
                  >
                    {"" === selectedCard && <i className="green check icon" />}
                    <div className="content">
                      <div className="header">New card</div>
                    </div>
                  </div>
                </div>
                {"" === selectedCard && (
                  <form className="ui form" onSubmit={this.submit}>
                    <div className="field">
                      <label>
                        Credit or debit card{" "}
                        <i
                          className="info circle blue icon"
                          title="Click for more info"
                          onClick={() => formToggleInfo("cardCapture")}
                        />
                      </label>
                      <div
                        className={
                          (form.infoFields.cardCapture ? "" : "hidden ") +
                          "ui info message"
                        }
                      >
                        <i className="lock icon" /> Your card details are
                        captured directly from your web browser by our payment
                        processor{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://stripe.com"
                        >
                          Stripe.com
                        </a>
                        . Your card details are not saved on our server; in fact
                        they don't even pass through our server.
                      </div>
                      <CardElement
                        style={{ base: { fontSize: "18px" } }}
                        onReady={element => element.focus()}
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="name">Name on card</label>
                      <input
                        type="text"
                        tabIndex="10"
                        name="name"
                        id="name"
                        placeholder="Name"
                        autoComplete="off"
                        ref={input => (this.nameField = input)}
                      />
                    </div>
                    <div className="inline field">
                      <div className="ui checkbox">
                        <input
                          id="save"
                          type="checkbox"
                          tabIndex="20"
                          name="save"
                          ref={input => (this.saveCardField = input)}
                          defaultChecked={form.dataFields.cardSave}
                          onChange={this.cardSaveChange}
                        />
                        <label>
                          Save{" "}
                          <i
                            className="info circle blue icon"
                            title="Click for more info"
                            onClick={() => formToggleInfo("cardSave")}
                          />
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        (form.infoFields.cardSave ? "" : "hidden ") +
                        "ui info message"
                      }
                    >
                      <i className="lock icon" /> Securely save your card in
                      your account for future use. Your card details are not
                      saved on our server; in fact they don't even pass through
                      our server. It is only saved securely by{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://stripe.com"
                      >
                        Stripe.com
                      </a>
                      . If you choose not to save, this card shall be used only
                      for this one time.
                    </div>
                    {form.dataFields.cardSave && (
                      <div className="inline field">
                        <div className="ui checkbox">
                          <input
                            id="default"
                            type="checkbox"
                            tabIndex="30"
                            name="default"
                            ref={input => (this.defaultField = input)}
                            defaultChecked={true}
                          />
                          <label>
                            Set as default{" "}
                            <i
                              className="info circle blue icon"
                              title="Click for more info"
                              onClick={() => formToggleInfo("defaultCard")}
                            />
                          </label>
                        </div>
                        <div
                          className={
                            (form.infoFields.defaultCard ? "" : "hidden ") +
                            "ui info message"
                          }
                        >
                          The default card shall be used for processing
                          recurring payments.
                        </div>
                      </div>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  };
}
const injectedForm = injectStripe(PayCredit);

const mapStateToProps = state => ({
  form: state.ui.form,
  customer: state.customer.data,
  payment: state.payment
});

const ConnectedForm = withRouter(
  connect(
    mapStateToProps,
    {
      readStripePaymentOption,
      processCreditPayment,
      readCustomer,
      resetStatusMessage,
      setStatusMessage,
      formSetData,
      formResetData,
      formResetError,
      formSetError,
      formResetInfo,
      formToggleInfo
    }
  )(injectedForm)
);

const Wrapper = props => (
  <StripeProvider apiKey={config.stripeApiKey}>
    <Elements>
      <ConnectedForm {...props} />
    </Elements>
  </StripeProvider>
);

export default Wrapper;
