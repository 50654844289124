import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  formSetPasswordStrength,
  formResetPasswordStrength
} from "../../actions/ui/form";
import { scorePassword } from "../../lib/password";

class PasswordInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    tabIndex: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
    inputRef: PropTypes.func.isRequired,
    // Injected by React Redux
    hasError: PropTypes.bool,
    passwordStrength: PropTypes.shape({
      score: PropTypes.number.isRequired,
      strength: PropTypes.string
    }).isRequired,
    formSetPasswordStrength: PropTypes.func.isRequired,
    formResetPasswordStrength: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    // Reset password strength initially
    this.props.formResetPasswordStrength();
  };

  updateStrength = () => {
    this.props.formSetPasswordStrength(scorePassword(this.input.value));
  };

  render = () => {
    const {
      label,
      tabIndex,
      required,
      inputRef,
      hasError,
      passwordStrength
    } = this.props;
    return (
      <div
        className={
          (required ? "required " : "") + "field" + (hasError ? " error" : "")
        }
      >
        <label htmlFor="newPassword">{label ? label : "Password"}</label>
        <input
          type="password"
          tabIndex={tabIndex}
          name="newPassword"
          id="newPassword"
          placeholder="Password"
          autoComplete="off"
          required={required}
          ref={input => {
            this.input = input;
            inputRef(input);
          }}
          onInput={this.updateStrength}
        />
        {passwordStrength.score > -1 && (
          <div
            className="ui tiny indicating progress"
            data-percent={passwordStrength.score}
          >
            <div
              className="bar"
              style={{
                transitionDuration: "300ms",
                width: passwordStrength.score.toString(10) + "%"
              }}
            >
              <div className="progress" />
            </div>
            <div className="label">{passwordStrength.strength}</div>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  hasError:
    state.ui.form.errorFields.passwordField ||
    state.ui.form.errorFields.passwordIsEmail,
  passwordStrength: state.ui.form.passwordStrength
});

export default connect(
  mapStateToProps,
  {
    formSetPasswordStrength,
    formResetPasswordStrength
  }
)(PasswordInput);
