import React from "react";
import PropTypes from "prop-types";

const Confirmation = ({ message, onNo, onYes }) => (
  <div className="ui negative message container">
    <div className="header">
      <span id="message">{message}?</span>
      <button
        id="no"
        className="ui mini negative right floated button"
        onClick={onNo}
      >
        No
      </button>
      <button
        id="yes"
        className="ui mini positive right floated button"
        onClick={onYes}
      >
        Yes
      </button>
    </div>
  </div>
);

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  onNo: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired
};

export default Confirmation;
