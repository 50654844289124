import {
  AVATAR_SOURCE_READY,
  AVATAR_CROP_UPDATE,
  AVATAR_CROP_READY,
  AVATAR_RESET
} from "../../actions/ui/avatar";

const avatarInput = (
  state = { crop: {}, sourceReady: false, cropReady: false },
  action
) => {
  switch (action.type) {
    case AVATAR_CROP_UPDATE:
      return { ...state, crop: action.crop };
    case AVATAR_SOURCE_READY:
      return { ...state, sourceReady: true };
    case AVATAR_CROP_READY:
      return { ...state, cropReady: true };
    case AVATAR_RESET:
      return { crop: {}, sourceReady: false, cropReady: false };
    default:
      return state;
  }
};

export default avatarInput;
