import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import config from "../../config";
import {
  readInvoice,
  switchTab,
  selectCard,
  setCardSave,
  readStripePaymentOption,
  processInvoicePayment,
  readCustomer,
  resetStatusMessage,
  setStatusMessage,
  formResetError,
  formSetError,
  formUnsetError,
  formResetInfo,
  formToggleInfo,
  formSetData,
  formUnsetData,
  formResetData
} from "../../actions";
import { standardText } from "../../lib/validations";
import Header from "../../components/Header";
import Order from "../../components/Order";
import Invoice from "../../components/Invoice";

class PayInvoice extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    tab: PropTypes.string.isRequired,
    invoice: PropTypes.object.isRequired,
    customer: PropTypes.object,
    payment: PropTypes.object.isRequired,
    readInvoice: PropTypes.func.isRequired,
    switchTab: PropTypes.func.isRequired,
    selectCard: PropTypes.func.isRequired,
    setCardSave: PropTypes.func.isRequired,
    readStripePaymentOption: PropTypes.func.isRequired,
    processInvoicePayment: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    setStatusMessage: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formUnsetError: PropTypes.func.isRequired,
    formResetInfo: PropTypes.func.isRequired,
    formToggleInfo: PropTypes.func.isRequired,
    formSetData: PropTypes.func.isRequired,
    formUnsetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const {
      history,
      invoice,
      match,
      switchTab,
      selectCard,
      setCardSave,
      resetStatusMessage,
      formResetError,
      formResetInfo,
      formResetData
    } = this.props;
    if (!match.params.id) {
      history.push("/invoice");
    } else if (!invoice.invoice || match.params.id !== invoice.invoice._id) {
      this.refresh();
    }
    switchTab("invoice");
    selectCard(null);
    setCardSave(true);
    resetStatusMessage();
    formResetError();
    formResetInfo();
    formResetData();
  };

  refresh = () => {
    const {
      match,
      customer,
      payment,
      readInvoice,
      readStripePaymentOption,
      readCustomer
    } = this.props;
    if (!payment.cards.length) {
      readStripePaymentOption();
    }
    if (!customer) {
      readCustomer();
    }
    readInvoice({
      search: { _id: match.params.id },
      populate: [{ path: "order" }]
    });
  };

  getSelectedCard = () => {
    const { invoice, customer, payment } = this.props,
      defaultCard = customer ? customer.defaultCard : null;
    return "string" === typeof invoice.selectedCard
      ? invoice.selectedCard
      : payment.cards.length
      ? defaultCard
      : "";
  };

  submit = event => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    const {
        invoice,
        customer,
        stripe,
        processInvoicePayment,
        setStatusMessage,
        resetStatusMessage,
        formSetError,
        formResetError,
        formSetData,
        formUnsetData
      } = this.props,
      selectedCard = this.getSelectedCard(),
      autoRenew = this.autoRenewField.value,
      invoiceData = invoice.data,
      credit = customer && customer.credit ? customer.credit : 0;
    formSetData("processing", true);
    formResetError();
    if (invoiceData.amount <= credit) {
      processInvoicePayment({
        invoice: invoiceData._id,
        autoRenew
      });
    } else if (selectedCard) {
      processInvoicePayment({
        invoice: invoiceData._id,
        card: selectedCard,
        autoRenew
      });
    } else {
      const save = this.saveCardField.checked,
        name = this.nameField.value.trim(),
        card = { type: "card" };
      let hasError = false;
      if ("1" === autoRenew && !save) {
        formSetError("save");
        hasError = true;
      }
      if (name) {
        if (!standardText(name)) {
          formSetError("name");
          hasError = true;
        } else {
          card.name = name;
        }
      }
      if (!hasError) {
        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        stripe
          .createToken(card)
          .then(result => {
            console.log("Stripe result:", result);
            resetStatusMessage();
            if (result.error) {
              formSetError("card", result.error.message);
              formUnsetData("processing");
            } else {
              processInvoicePayment({
                invoice: invoiceData._id,
                token: result.token.id,
                save,
                default: this.defaultField ? this.defaultField.checked : false,
                autoRenew
              });
            }
          })
          .catch(error => {
            console.error(error);
            setStatusMessage(error.message, "error");
            formUnsetData("processing");
          });
      } else {
        formUnsetData("processing");
      }
    }
  };

  checkAutoRenewNewCardSave = () => {
    const { formSetError, formUnsetError } = this.props,
      autoRenew = this.autoRenewField.value,
      save = !this.saveCardField || this.saveCardField.checked;
    /* When saveCardField is not present (not a new card),
     it need not be checked */
    if ("1" === autoRenew && !save) {
      formSetError("save");
    } else {
      formUnsetError("save");
    }
  };

  cardSaveChange = () => {
    this.props.setCardSave(this.saveCardField.checked);
    this.checkAutoRenewNewCardSave();
  };

  render = () => {
    const {
        history,
        customer,
        form,
        tab,
        invoice,
        payment,
        switchTab,
        selectCard,
        formToggleInfo
      } = this.props,
      invoiceData = invoice.data,
      selectedCard = this.getSelectedCard(),
      credit = customer && customer.credit ? customer.credit : 0;
    return (
      <article className="thirteen wide column">
        <Header
          title={
            "unpaid" === (invoiceData && invoiceData.status)
              ? "Pay invoice"
              : "View invoice"
          }
          isLoading={invoice.loading}
          onRefresh={this.refresh}
        />
        {invoiceData && (
          <div className="ui">
            <div className="ui top attached tabular menu">
              <div
                className={"item" + ("invoice" === tab ? " active" : "")}
                onClick={() => switchTab("invoice")}
              >
                Invoice
              </div>
              <div
                className={"item" + ("order" === tab ? " active" : "")}
                onClick={() => switchTab("order")}
              >
                Order
              </div>
            </div>
            <div
              className={
                "ui bottom attached tab segment" +
                ("invoice" === tab ? " active" : "")
              }
            >
              <div className="ui stackable two column grid">
                <div className="column">
                  <Invoice invoice={invoiceData} credit={credit} />
                </div>
                <div className="column">
                  {"unpaid" === invoiceData.status &&
                    invoiceData.amount > credit && (
                      <div className="ui segment">
                        <div
                          className={
                            "ui dimmer" + (payment.loading ? " active" : "")
                          }
                        >
                          <div className="ui loader" />
                        </div>
                        <h3 className="ui header">Payment options</h3>
                        <div className="ui middle aligned animated celled selection list">
                          {payment.cards.map(card => (
                            <div
                              className={
                                (selectedCard === card._id ? "disabled " : "") +
                                "item"
                              }
                              key={card._id}
                              onClick={() => {
                                selectCard(card._id);
                              }}
                            >
                              {selectedCard === card._id && (
                                <i className="green check icon" />
                              )}
                              <div className="content">
                                <div className="header">
                                  {card.brand} ending in {card.last4}
                                </div>
                                {card.name && (
                                  <div className="description">
                                    Name: {card.name}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          <div
                            className={
                              ("" === selectedCard ? "disabled " : "") + "item"
                            }
                            key="newCard"
                            onClick={() => {
                              selectCard("");
                            }}
                          >
                            {"" === selectedCard && (
                              <i className="green check icon" />
                            )}
                            <div className="content">
                              <div className="header">New card</div>
                            </div>
                          </div>
                        </div>
                        {"" === selectedCard && (
                          <form className="ui form" onSubmit={this.submit}>
                            <div className="field">
                              <label>
                                Credit or debit card{" "}
                                <i
                                  className="info circle blue icon"
                                  title="Click for more info"
                                  onClick={() => formToggleInfo("cardCapture")}
                                />
                              </label>
                              <div
                                className={
                                  (form.infoFields.cardCapture
                                    ? ""
                                    : "hidden ") + "ui info message"
                                }
                              >
                                <i className="lock icon" /> Your card details
                                are captured directly from your web browser by
                                our payment processor{" "}
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://stripe.com"
                                >
                                  Stripe.com
                                </a>
                                . Your card details are not saved on our server;
                                in fact they don't even pass through our server.
                              </div>
                              <CardElement
                                style={{ base: { fontSize: "18px" } }}
                                onReady={element => element.focus()}
                              />
                            </div>
                            <div className="field">
                              <label htmlFor="name">Name on card</label>
                              <input
                                type="text"
                                tabIndex="10"
                                name="name"
                                id="name"
                                placeholder="Name"
                                autoComplete="off"
                                ref={input => (this.nameField = input)}
                              />
                            </div>
                            <div
                              className={
                                "inline field" +
                                (form.errorFields.save ? " error" : "")
                              }
                            >
                              <div className="ui checkbox">
                                <input
                                  id="save"
                                  type="checkbox"
                                  tabIndex="20"
                                  name="save"
                                  ref={input => (this.saveCardField = input)}
                                  defaultChecked={invoice.cardSave}
                                  onChange={this.cardSaveChange}
                                />
                                <label>
                                  Save{" "}
                                  <i
                                    className="info circle blue icon"
                                    title="Click for more info"
                                    onClick={() => formToggleInfo("cardSave")}
                                  />
                                </label>
                              </div>
                            </div>
                            <div
                              className={
                                (form.infoFields.cardSave ? "" : "hidden ") +
                                "ui info message"
                              }
                            >
                              <i className="lock icon" /> Securely save your
                              card in your account for future use. Your card
                              details are not saved on our server; in fact they
                              don't even pass through our server. It is only
                              saved securely by{" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://stripe.com"
                              >
                                Stripe.com
                              </a>
                              . If you choose not to save, this card shall be
                              used only for this one time.
                            </div>
                            {invoice.cardSave && (
                              <div className="inline field">
                                <div className="ui checkbox">
                                  <input
                                    id="default"
                                    type="checkbox"
                                    tabIndex="30"
                                    name="default"
                                    ref={input => (this.defaultField = input)}
                                    defaultChecked={true}
                                  />
                                  <label>
                                    Set as default{" "}
                                    <i
                                      className="info circle blue icon"
                                      title="Click for more info"
                                      onClick={() =>
                                        formToggleInfo("defaultCard")
                                      }
                                    />
                                  </label>
                                </div>
                                <div
                                  className={
                                    (form.infoFields.defaultCard
                                      ? ""
                                      : "hidden ") + "ui info message"
                                  }
                                >
                                  The default card shall be used for processing
                                  recurring payments.
                                </div>
                              </div>
                            )}
                          </form>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <form className={form.hasError ? "ui form error" : "ui form"}>
                {"unpaid" === invoiceData.status && (
                  <div className="field">
                    <label htmlFor="autoRenew">Auto renew</label>
                    <select
                      id="autoRenew"
                      name="autoRenew"
                      tabIndex="10"
                      ref={input => (this.autoRenewField = input)}
                      onChange={this.checkAutoRenewNewCardSave}
                      defaultValue="2"
                    >
                      <option value="">
                        Disabled - Manually pay future invoices
                      </option>
                      <option value="1">
                        Enabled - With the selected payment option
                      </option>
                      <option value="2">
                        Enabled - With the default payment option
                      </option>
                    </select>
                  </div>
                )}
                {form.hasError && (
                  <div className="ui error message">
                    <ul className="list">
                      {!!form.errorFields.card && (
                        <li>{form.errorFields.card}</li>
                      )}
                      {form.errorFields.save && (
                        <li>Card should be saved for auto renew</li>
                      )}
                    </ul>
                  </div>
                )}
                <div className="ui hidden divider" />
                <button
                  id="cancel"
                  type="button"
                  tabIndex="110"
                  className={
                    "unpaid" === invoiceData.status
                      ? "ui negative button"
                      : "ui primary button"
                  }
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </button>
                {"unpaid" === invoiceData.status && (
                  <button
                    id="process"
                    type="button"
                    tabIndex="100"
                    className={
                      payment.loading
                        ? "ui positive loading button"
                        : "ui positive button"
                    }
                    disabled={!!form.dataFields.processing}
                    onClick={this.submit}
                  >
                    Process payment
                  </button>
                )}
              </form>
            </div>
            <div
              className={
                "ui bottom attached tab segment" +
                ("order" === tab ? " active" : "")
              }
            >
              <Order order={invoiceData.order} />
            </div>
          </div>
        )}
      </article>
    );
  };
}
const injectedForm = injectStripe(PayInvoice);

const mapStateToProps = state => ({
  form: state.ui.form,
  tab: state.ui.tab,
  invoice: state.invoice,
  customer: state.customer.data,
  payment: state.payment
});

const ConnectedForm = withRouter(
  connect(
    mapStateToProps,
    {
      readInvoice,
      switchTab,
      selectCard,
      setCardSave,
      readStripePaymentOption,
      processInvoicePayment,
      readCustomer,
      resetStatusMessage,
      setStatusMessage,
      formResetError,
      formSetError,
      formUnsetError,
      formResetInfo,
      formToggleInfo,
      formSetData,
      formUnsetData,
      formResetData
    }
  )(injectedForm)
);

const Wrapper = props => (
  <StripeProvider apiKey={config.stripeApiKey}>
    <Elements>
      <ConnectedForm {...props} />
    </Elements>
  </StripeProvider>
);

export default Wrapper;
