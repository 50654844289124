import {
  LICENSE_PURCHASE_REQUEST,
  LICENSE_PURCHASE_SUCCESS,
  INVOICE_READ_REQUEST,
  INVOICE_READ_SUCCESS,
  INVOICE_SELECT_CARD,
  INVOICE_SET_CARD_SAVE,
  INVOICE_DOWNLOAD_REQUEST,
  INVOICE_DOWNLOAD_SUCCESS,
  INVOICE_DOWNLOAD_FAILURE,
  INVOICE_SHOW_REFUND,
  PROCESS_REFUND_SUCCESS
} from "../actions";

const invoice = (
  state = {
    loading: false,
    downloading: false,
    data: null,
    selectedCard: null,
    cardSave: true,
    showRefund: 0
  },
  action
) => {
  switch (action.type) {
    case INVOICE_SELECT_CARD:
      return { ...state, selectedCard: action.id };
    case LICENSE_PURCHASE_REQUEST:
    case INVOICE_READ_REQUEST:
      return {
        ...state,
        loading: true,
        data: null
      };
    case LICENSE_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data
      };
    case INVOICE_READ_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.response.data[0]
      };
    case PROCESS_REFUND_SUCCESS:
      return {
        ...state,
        showRefund: 0,
        data: action.response.data.invoice
      };
    case INVOICE_DOWNLOAD_REQUEST:
      return { ...state, downloading: true };
    case INVOICE_DOWNLOAD_SUCCESS:
    case INVOICE_DOWNLOAD_FAILURE:
      return { ...state, downloading: false };
    case INVOICE_SET_CARD_SAVE:
      return { ...state, cardSave: action.status };
    case INVOICE_SHOW_REFUND:
      return { ...state, showRefund: action.status };
    default:
      return state;
  }
};

export default invoice;
