import moment from "moment";
import {
  LICENSE_UPDATE_PRICE,
  LICENSE_UPDATE_UNBILLED,
  LICENSE_COUPON_SUCCESS,
  LICENSE_ORDER_REQUEST,
  LICENSE_ORDER_SUCCESS,
  LICENSE_SET_CURRENT,
  ITEM_GET_SUCCESS,
  CONFIRM_ORDER_CANCEL,
  RESET_ORDER_CANCEL,
  CANCEL_ORDER_SUCCESS,
  CHECK_TRIAL_SUCCESS,
  LICENSE_PURCHASE_SUCCESS,
} from "../actions";
import config from "../config";
import { roundTo } from "../lib/utils";

const license = (
  state = {
    servers: 0,
    unbilledServers: 0,
    billingCycle: 0,
    price: null,
    coupons: [],
    current: null,
    order: null,
    orderLoading: false,
    lastPayment: null,
    appliedCoupon: null,
    ip: "",
    secondaryIp: "",
    credit: 0,
    confirmOrderCancel: false,
    hasTrial: false,
  },
  action
) => {
  switch (action.type) {
    case LICENSE_UPDATE_PRICE:
      const {
        servers,
        unbilledServers,
        billingCycle,
        termPercent,
        coupon,
        ip = "",
        secondaryIp = "",
        credit = 0,
        trial = false,
      } = action.data;
      let price = null,
        appliedCoupon = null,
        billStart = trial ? moment().add(30, "days") : moment();
      if (servers && billingCycle) {
        price = {
          perUnit: config.pricePerServer,
          amount: roundTo(config.pricePerServer * servers * billingCycle, 2),
          deduction: 0,
          trial,
        };
        price.subTotal = price.amount;
        if (termPercent) {
          price.termPercent = termPercent;
          price.termDiscount = roundTo((price.amount * termPercent) / 100, 2);
          price.subTotal = roundTo(price.amount - price.termDiscount, 2);
        }
        price.total = price.subTotal;
        price.recurringAmount = price.total;
        price.firstBillDue = billStart.format("MMMM Do, YYYY");
        price.nextBillDue = billStart
          .add(billingCycle, config.billingPeriod)
          .format("MMMM Do, YYYY");
        if (coupon) {
          const { percentage, amount, recurring } = coupon;
          appliedCoupon = coupon;
          if (percentage) {
            price.couponPercent = percentage;
            price.couponDiscount = roundTo(
              (price.subTotal * percentage) / 100,
              2
            );
          } else if (amount) {
            price.couponDiscount = amount;
          } else {
            price.couponDiscount = 0;
          }
          price.couponRecurring = recurring;
          price.total = roundTo(price.subTotal - price.couponDiscount, 2);
          if (recurring) {
            price.recurringAmount = price.total;
          }
        }
        if (state.lastPayment && state.lastPayment.amountRemaining > 0) {
          price.deduction += state.lastPayment.amountRemaining;
        }
        if (price.deduction) {
          price.deduction = roundTo(price.deduction, 2);
          const payment = roundTo(price.total - price.deduction, 2);
          if (payment >= 0) {
            price.firstBillAmount = payment;
          } else {
            price.firstBillAmount = 0;
            price.credit = -payment;
          }
        } else {
          price.firstBillAmount = price.total;
        }
      }
      return {
        ...state,
        servers,
        unbilledServers,
        billingCycle,
        price,
        appliedCoupon,
        ip,
        secondaryIp,
        credit,
      };
    case LICENSE_UPDATE_UNBILLED:
      return { ...state, unbilledServers: action.servers };
    case ITEM_GET_SUCCESS:
    case LICENSE_SET_CURRENT:
      if (ITEM_GET_SUCCESS === action.type && "license" !== action.model) {
        return state;
      }
      const license =
        ITEM_GET_SUCCESS === action.type
          ? action.response.data[ 0 ]
          : action.data,
        currentIp = license ? license.ip : "",
        currentSecondaryIp =
          license && license.secondaryIp ? license.secondaryIp : "";
      return {
        ...state,
        current: license,
        ip: currentIp,
        secondaryIp: currentSecondaryIp,
        servers: license ? license.servers : 0,
        order: null,
        lastPayment: null,
      };
    case LICENSE_COUPON_SUCCESS:
      return { ...state, coupons: action.response.data };
    case LICENSE_ORDER_REQUEST:
      return {
        ...state,
        order: null,
        orderLoading: true,
        lastPayment: null,
        credit: 0,
      };
    case LICENSE_ORDER_SUCCESS:
      const order = action.response.data[ 0 ],
        invoice = order.lastPaidInvoice;
      if (invoice) {
        const now = moment().utc(),
          periodStart = moment(invoice.dueDate),
          periodEnd = moment(periodStart).add(
            order.billingCycle,
            config.billingPeriod
          );
        if (now.isBefore(periodEnd)) {
          const amount = order.price.total,
            daysUsed = now.isAfter(periodStart)
              ? now.diff(periodStart, "days")
              : 0,
            daysTotal = periodEnd.diff(periodStart, "days"),
            daysRemaining = daysTotal - daysUsed,
            amountRemaining =
              daysRemaining > 0 && daysTotal > 0
                ? roundTo(amount * (daysRemaining / daysTotal), 2)
                : 0;
          return {
            ...state,
            order,
            orderLoading: false,
            billingCycle: order.billingCycle,
            lastPayment: {
              invoiceId: invoice._id,
              amount,
              periodStart,
              periodEnd,
              daysUsed,
              daysTotal,
              daysRemaining,
              amountRemaining,
            },
          };
        }
      }
      return {
        ...state,
        order,
        orderLoading: false,
        billingCycle: order.billingCycle,
      };
    case CONFIRM_ORDER_CANCEL:
      return { ...state, confirmOrderCancel: true };
    case RESET_ORDER_CANCEL:
    case CANCEL_ORDER_SUCCESS:
      return { ...state, confirmOrderCancel: false };
    case CHECK_TRIAL_SUCCESS:
      return { ...state, hasTrial: action.response.data.hasTrial };
    case LICENSE_PURCHASE_SUCCESS:
      return { ...state, hasTrial: false };
    default:
      return state;
  }
};

export default license;
