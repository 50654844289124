import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SimpleHeader from "../../components/SimpleHeader";
import AgentForm from "../AgentForm/Form";

class Staff extends Component {
  static propTypes = {
    // Injected by React-Redux
    agent: PropTypes.object
  };

  render = () => {
    const { agent } = this.props;
    return (
      <article className="thirteen wide column">
        <SimpleHeader title="Profile" />
        <AgentForm agent={agent} edit={true} profile={true} />
      </article>
    );
  };
}

const mapStateToProps = state => ({
  agent: state.agent.data
});

export default connect(mapStateToProps)(Staff);
