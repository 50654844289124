// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel } from "../../actions/listing";
import InvoiceSearch from "./InvoiceSearch";
import Listing from "../Listing";

class InvoiceListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string,
    setItemModel: PropTypes.func.isRequired,
  };

  model = "invoice";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, userGroup } = this.props,
      fields = {
        serial: { title: "#", type: "serial" },
        _id: { title: "ID" },
        amount: { title: "Amount", type: "number", sortable: true },
        dueDate: {
          title: "Due date",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true,
        },
        status: { title: "Status", sortable: true },
        order: { title: "Order ID", link: "/order/view/" },
      },
      actions = [
        {
          name: "View",
          type: "primary",
          icon: "eye",
          handler: (id) => {
            history.push(`/${this.model}/view/${id}`);
          },
        },
      ];
    if ("customer" === userGroup) {
      actions.push({
        name: "Pay",
        description: "Make payment",
        type: "positive",
        handler: (id) => {
          history.push(`/${this.model}/pay/${id}`);
        },
        conditional: (record) => "unpaid" === record.status,
      });
    } else {
      fields.customer = {
        title: "Customer ID",
        link: "/customer/edit/",
      };
    }
    fields.created = {
      title: "Created",
      type: "date",
      format: "YYYY-MM-DD",
      sortable: true,
    };
    fields.actions = { title: "Actions", type: "actions" };
    return (
      <Listing
        title="Invoices"
        model={this.model}
        mainField="_id"
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ _id: 1 }}
        defaultSort={{ created: -1 }}
        // search form component
        ItemSearch={InvoiceSearch}
        simpleSearchTitle="Exact ID"
        getSimpleSearch={(search) => ({
          _id: search,
        })}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userGroup: state.auth.user.group,
});

export default withRouter(
  connect(mapStateToProps, {
    setItemModel,
  })(InvoiceListing)
);
