// cSpell:ignore unmount
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setItemModel } from "../../actions/listing";
import LicenseSearch from "./LicenseSearch";
import Listing from "../Listing";

class LicenseListing extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    userGroup: PropTypes.string.isRequired,
    setItemModel: PropTypes.func.isRequired,
  };

  model = "license";

  componentWillMount = () => {
    this.props.setItemModel(this.model);
  };

  render() {
    const { history, match, userGroup } = this.props,
      customer = match.params.customer,
      build = match.params.build,
      fields = {
        serial: { title: "#", type: "serial" },
        ip: { title: "IP", sortable: true },
        secondaryIp: { title: "Secondary IP", sortable: true },
        servers: { title: "Servers", type: "number", sortable: true },
        expiry: {
          title: "Expiry",
          type: "date",
          format: "YYYY-MM-DD",
          sortable: true,
        },
        status: { title: "Status", sortable: true },
        order: { title: "Order ID", link: "/order/view/" },
      },
      actions = [
        {
          name: "Recover Login",
          icon: "ambulance",
          handler: (id) => {
            history.push(`/${this.model}/recovery/${id}`);
          },
        },
        {
          name: "Edit",
          type: "primary",
          icon: "edit",
          handler: (id) => {
            history.push(`/${this.model}/edit/${id}`);
          },
        },
      ];
    if ("customer" !== userGroup) {
      fields.customer = {
        title: "Customer ID",
        link: "/customer/edit/",
      };
      fields.build = {
        title: "Build ID",
      };
      fields.unbilledServers = {
        title: "Unbilled",
        type: "number",
        sortable: true,
      };
    }
    fields.created = {
      title: "Created",
      type: "date",
      format: "YYYY-MM-DD",
      sortable: true,
    };
    fields.modified = {
      title: "Modified",
      type: "date",
      format: "YYYY-MM-DD",
      sortable: true,
    };
    fields.actions = { title: "Actions", type: "actions" };
    return (
      <Listing
        title={customer ? "Customer licenses" : "Licenses"}
        model={this.model}
        mainField="ip"
        initialSearch={customer ? { customer } : build ? { build } : null}
        // list config
        fields={fields}
        actions={actions}
        highlightFields={{ ip: 1, secondaryIp: 1 }}
        highlightTextPath={{ ip: ["$or", 0], secondaryIp: ["$or", 1] }}
        // search form component
        ItemSearch={LicenseSearch}
        onAdd={
          customer || build
            ? null
            : () => {
                history.push(`/${this.model}/add`);
              }
        }
        simpleSearchTitle="IP Address"
        getSimpleSearch={
          customer || build
            ? null
            : (search) => ({
                $or: [{ ip: search }, { secondaryIp: search }],
              })
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userGroup: state.auth.user.group,
});

export default withRouter(
  connect(mapStateToProps, {
    setItemModel,
  })(LicenseListing)
);
