import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  formSetData,
  formResetData,
  formSetError,
  formResetError,
  switchTab,
  readCustomer,
  terminateAccount
} from "../../actions";
import ScreenTitle from "../../components/ScreenTitle";
import AgentForm from "../AgentForm/Form";
import CustomerForm from "../CustomerForm/Form";
import PrivacyForm from "./PrivacyForm";

class Customer extends Component {
  static propTypes = {
    // Injected by React-Redux
    tab: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    agent: PropTypes.object,
    customer: PropTypes.object,
    formSetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    switchTab: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    terminateAccount: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { customer, switchTab, readCustomer } = this.props;
    if (!customer) {
      readCustomer();
    }
    switchTab("profile");
  };

  terminateAccount = event => {
    event.preventDefault();
    const { formSetError, formResetError, terminateAccount } = this.props;
    const terminateText = this.terminateTextInput.value.trim();
    if ("TERMINATE" === terminateText) {
      // terminate the account
      formResetError();
      terminateAccount();
    } else {
      formSetError("terminateText");
    }
  };

  render = () => {
    const {
      agent,
      customer,
      form,
      tab,
      switchTab,
      formSetData,
      formResetData
    } = this.props;
    return (
      <article className="thirteen wide column">
        <header className="ui dividing header">
          <div className="ui two column grid">
            <ScreenTitle title="Account" className="column" />
            <div className="right floated right aligned column">
              <button
                id="terminate"
                className="ui negative button"
                onClick={() => formSetData("confirmTermination", true)}
              >
                Terminate
              </button>
            </div>
          </div>
        </header>
        {form.dataFields.confirmTermination && (
          <div className="ui">
            <div className="ui attached negative huge message">
              <div className="header">
                Are you sure you want to terminate your account?
              </div>
              <p>
                IMPORTANT NOTE: All your account details, email addresses, agent
                accounts, orders, invoices, licenses, credits and all history
                will be completely removed from our system irrevocably!
              </p>
              <p>
                Type "TERMINATE" in the box below and press the "Yes" button to
                confirm the termination of your account.
              </p>
            </div>
            <form
              className={
                "ui form" +
                (form.errorFields.terminateText ? " error" : "") +
                " attached fluid segment"
              }
              onSubmit={this.terminateAccount}
            >
              <div
                className={
                  "field" + (form.errorFields.terminateText ? " error" : "")
                }
              >
                <input
                  id="terminateTextInput"
                  name="terminateTextInput"
                  type="text"
                  autoComplete="off"
                  autoFocus={false}
                  ref={input => (this.terminateTextInput = input)}
                />
              </div>
              <div className="ui error message">
                <div className="header">Invalid input</div>
              </div>
              <button id="yes" type="submit" className="ui negative button">
                Yes
              </button>
              <button
                id="no"
                type="button"
                className="ui positive button"
                onClick={formResetData}
              >
                No
              </button>
            </form>
          </div>
        )}
        {!form.dataFields.confirmTermination && (
          <div className="ui">
            <div className="ui top attached tabular menu">
              <div
                className={"item" + ("profile" === tab ? " active" : "")}
                onClick={() => switchTab("profile")}
              >
                Profile
              </div>
              <div
                className={"item" + ("company" === tab ? " active" : "")}
                onClick={() => switchTab("company")}
              >
                Company
              </div>
              <div
                className={"item" + ("privacy" === tab ? " active" : "")}
                onClick={() => switchTab("privacy")}
              >
                Privacy
              </div>
            </div>
            <div
              className={
                "ui bottom attached tab segment" +
                ("profile" === tab ? " active" : "")
              }
            >
              {agent ? (
                <AgentForm agent={agent} edit={true} profile={true} />
              ) : (
                <div className="ui segment">
                  <div className="ui hidden divider" />
                  <div className="ui active loader" />
                  <div className="ui hidden divider" />
                </div>
              )}
            </div>
            <div
              className={
                "ui bottom attached tab segment" +
                ("company" === tab ? " active" : "")
              }
            >
              {customer ? (
                <CustomerForm customer={customer} showCredit={false} />
              ) : (
                <div className="ui segment">
                  <div className="ui hidden divider" />
                  <div className="ui active loader" />
                  <div className="ui hidden divider" />
                </div>
              )}
            </div>
            <div
              className={
                "ui bottom attached tab segment" +
                ("privacy" === tab ? " active" : "")
              }
            >
              <PrivacyForm />
            </div>
          </div>
        )}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  tab: state.ui.tab,
  form: state.ui.form,
  agent: state.agent.data,
  customer: state.customer.data
});

export default connect(
  mapStateToProps,
  {
    formSetData,
    formResetData,
    formSetError,
    formResetError,
    switchTab,
    readCustomer,
    terminateAccount
  }
)(Customer);
