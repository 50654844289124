import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginMode, setLoginMessage } from "../../actions/ui/login";
import LoginForm from "./LoginForm";
import OtpForm from "./OtpForm";

class Login extends Component {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    loginMode: PropTypes.func.isRequired,
    setLoginMessage: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { mode, loginMode } = this.props;
    loginMode(mode);
  };

  onTabClick = tab => {
    const { loginMode, setLoginMessage } = this.props;
    setLoginMessage("");
    loginMode(tab);
  };

  render = () => {
    const { login, form } = this.props,
      mode = login.mode;
    return (
      <div className="ui container segment">
        <div className="ui top attached tabular menu">
          <div
            className={"login" === mode ? "active item" : "item"}
            onClick={() => {
              this.onTabClick("login");
            }}
          >
            Login
          </div>
          <div
            className={"register" === mode ? "active item" : "item"}
            onClick={() => {
              this.onTabClick("register");
            }}
          >
            Register
          </div>
          <div
            className={"forgot" === mode ? "active item" : "item"}
            onClick={() => {
              this.onTabClick("forgot");
            }}
          >
            Forgot password
          </div>
        </div>
        <div className="ui bottom attached active tab segment">
          {form.hasError && (
            <div className="ui error message">
              <ul className="list">
                {form.errorFields.email && <li>Enter a valid email address</li>}
                {form.errorFields.password && <li>Password cannot be empty</li>}
                {form.errorFields.weakPassword && (
                  <li>Enter a stronger password</li>
                )}
                {form.errorFields.confirmPassword && (
                  <li>Passwords do not match</li>
                )}
                {form.errorFields.code && <li>Invalid code</li>}
                {form.errorFields.captcha && (
                  <li>Solve the reCAPTCHA challenge</li>
                )}
              </ul>
            </div>
          )}
          {"login" === mode && <LoginForm />}
          {"login" !== mode && <OtpForm />}
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  login: state.ui.login,
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    loginMode,
    setLoginMessage
  }
)(Login);
