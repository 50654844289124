import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import moment from "moment";
import { timeConvert } from "../../lib/utils";
import "./listItem.css";

const ListItem = ({
  fields,
  record,
  actions,
  serial,
  highlightFields,
  highlightTextPath,
  highlightText
}) => (
  <tr>
    {Object.keys(fields).map(field => {
      const hasOnClick = fields[field].onClick;
      let value,
        className = "",
        hasAlert = false;
      if ("serial" === fields[field].type) {
        value = serial;
      } else if (field.indexOf(".") !== -1) {
        const fields = field.split("."),
          length = fields.length;
        value = record;
        for (let i = 0; i < length; i++) {
          if (value[fields[i]]) {
            value = value[fields[i]];
          } else {
            value = "NA";
            break;
          }
        }
      } else if ("time" === fields[field].type) {
        value = timeConvert(record[field]);
      } else {
        value = record[field];
      }
      if (fields[field].hasClass) {
        if (value.alert) {
          hasAlert = true;
        }
        className = value.className;
        value = value.value;
      }
      if (hasOnClick) {
        if ("" !== className) {
          className += " ";
        }
        className += "pointer";
      }
      if ("bool" === fields[field].type) {
        value = value ? "Yes" : "No";
      } else if (
        "date" === fields[field].type &&
        "string" === typeof fields[field].format
      ) {
        value = moment(value).format(fields[field].format);
      } else if ("html" === fields[field].type) {
        value = renderHTML(value);
      } else if ("image" === fields[field].type) {
        if ("undefined" !== typeof value) {
          const tooltipField = fields[field].tooltip,
            tooltip = record[tooltipField];
          if (tooltip) {
            value = (
              <span data-tooltip={tooltip}>
                <img alt={tooltip} className="ui avatar image" src={value} />
              </span>
            );
          } else {
            value = (
              <img alt="avatar" className="ui avatar image" src={value} />
            );
          }
        } else if (fields[field].empty) {
          const { use, length } = fields[field].empty;
          value = length ? record[use].substr(0, length) : record[use];
        }
      } else if ("actions" === fields[field].type && actions) {
        value = actions.map(action => {
          const {
            conditional,
            name,
            type,
            icon,
            description,
            handler
          } = action;
          if (conditional && !conditional(record)) {
            return null;
          }
          return (
            <button
              key={record._id + field + name}
              type="button"
              className={
                "ui " +
                (type ? type : "") +
                (icon ? " compact icon button" : " compact mini button")
              }
              data-tooltip={description ? description : name}
              onClick={() => {
                handler(record._id);
              }}
            >
              {icon ? <i className={icon + " icon"} /> : name}
            </button>
          );
        });
      }
      if (value && highlightFields && highlightFields[field] && highlightText) {
        let text = highlightText[field];
        if (!text && highlightTextPath && highlightTextPath[field]) {
          const pathComponents = highlightTextPath[field];
          if (pathComponents.length) {
            let context = highlightText;
            for (let c of pathComponents) {
              context = context[c];
              if (!context) {
                break;
              }
            }
            if (context && context[field]) {
              text = context[field];
            }
          }
        }
        if (text && "string" === typeof value) {
          const index = value.toLowerCase().indexOf(text.toLowerCase());
          if (-1 !== index) {
            if (-1 !== value.indexOf("<") || -1 !== value.indexOf(">")) {
              value = renderHTML(
                value
                  .substring(0, index)
                  .replace("<", "&lt;")
                  .replace(">", "&gt;") +
                  "<span class='highlight'>" +
                  value
                    .substring(index, index + text.length)
                    .replace("<", "&lt;")
                    .replace(">", "&gt;") +
                  "</span>" +
                  value
                    .substring(index + text.length)
                    .replace("<", "&lt;")
                    .replace(">", "&gt;")
              );
            } else {
              value = renderHTML(
                value.substring(0, index) +
                  "<span class='highlight'>" +
                  value.substring(index, index + text.length) +
                  "</span>" +
                  value.substring(index + text.length)
              );
            }
          }
        }
      }
      return (
        <td
          key={record._id + field}
          className={className}
          onClick={() => {
            if (hasOnClick) {
              fields[field].onClick(record._id);
            }
          }}
        >
          {hasAlert && <i className="attention icon" />}
          {fields[field].link ? (
            <Link to={fields[field].link + value}>{value}</Link>
          ) : (
            value
          )}
        </td>
      );
    })}
  </tr>
);

ListItem.propTypes = {
  fields: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  actions: PropTypes.array,
  serial: PropTypes.number,
  highlightFields: PropTypes.object,
  highlightText: PropTypes.object
};

export default ListItem;
