import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getItem } from "../../actions/item";
import Header from "../../components/Header";
import Form from "./Form";

class AgentForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    item: PropTypes.object.isRequired,
    getItem: PropTypes.func.isRequired
  };

  model = "agent";

  componentWillMount = () => {
    this.edit = false;
    this.refresh();
  };

  refresh = () => {
    const { match, getItem } = this.props;
    if (match.params.id) {
      this.edit = true;
      getItem(this.model, match.params.id);
    }
  };

  render = () => {
    const { history, item } = this.props;
    return (
      <article className="thirteen wide column">
        <Header
          title={this.edit ? "Edit agent" : "Add agent"}
          isLoading={item.loading}
          onRefresh={this.edit ? this.refresh : null}
        />
        {(!this.edit || item.data) && (
          <Form
            agent={item.data}
            edit={this.edit}
            onCancel={() => history.goBack()}
          />
        )}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  item: state.item
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getItem
    }
  )(AgentForm)
);
