import { SETTING_READ_SUCCESS } from "../actions/setting";

const setting = (state = {}, action) => {
  switch (action.type) {
    case SETTING_READ_SUCCESS:
      const setting = {};
      action.response.data.forEach(
        entry => (setting[entry.name] = entry.value)
      );
      return setting;
    default:
      return state;
  }
};

export default setting;
