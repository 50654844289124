import { scorePassword } from "./password";
import { getNetworkClassInfo } from "./ipv4";
import config from "../config";

export const standardText = text => -1 === text.indexOf("<");

/**
 * Validate an email address.
 * Adapted from: https://www.w3schools.com/js/tryit.asp?filename=tryjs_form_validate_email
 * @param {String} email
 */
export const email = email => {
  if (!email || !standardText(email)) {
    return false;
  }
  const atPosition = email.indexOf("@"),
    dotPosition = email.lastIndexOf(".");
  if (
    atPosition < 1 ||
    dotPosition < atPosition + 2 ||
    dotPosition + 2 >= email.length
  ) {
    return false;
  }
  return true;
};

/**
 * Validate a strong password.
 * @param {String} password
 */
export const strongPassword = password => {
  return scorePassword(password) >= config.minPasswordScore;
};

/**
 * Validate an IPv4 address.
 * Adapted from: https://www.w3resource.com/javascript/form/ip-address-validation.php
 * @param {String} ip
 */
export const ipv4 = ip => {
  if (
    !ip ||
    "string" !== typeof ip ||
    !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ip
    )
  ) {
    return false;
  }
  return true;
};

/**
 * Validate a public IPv4 address.
 * @param {String} ip
 */
export const publicIpv4 = ip => {
  if (!ip || !ipv4(ip)) {
    return false;
  }
  const { networkType } = getNetworkClassInfo(ip);
  if ("public" !== networkType) {
    return false;
  }
  return true;
};

/**
 * Validate a number within a range
 * @param {mixed} value
 * @param {Number} min
 * @param {Number} max
 */
export const numberInRange = (value, min, max) => {
  if ("number" !== typeof value) {
    return false;
  }
  if (min && value < min) {
    return false;
  }
  if (max && value > max) {
    return false;
  }
  return true;
};

/**
 * Valid billing cycle
 * @param {Number} value
 */
const billingCycles = [1, 3, 6, 12];
export const billingCycle = value => {
  return -1 !== billingCycles.indexOf(value);
};
