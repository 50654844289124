import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetAutocomplete,
  setSearch,
  setSelected
} from "../../actions/ui/autocomplete";

class AutocompleteInput extends Component {
  static propTypes = {
    count: PropTypes.number,
    title: PropTypes.string,
    tabIndex: PropTypes.number.isRequired,
    selectedItem: PropTypes.string,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
    getSuggestedItem: PropTypes.func.isRequired,
    searchItem: PropTypes.func.isRequired,
    getName: PropTypes.func.isRequired,
    selectItem: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    // injected
    autocomplete: PropTypes.object.isRequired,
    resetAutocomplete: PropTypes.func.isRequired,
    setSearch: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { resetAutocomplete, getSuggestedItem, selectedItem } = this.props;
    resetAutocomplete();
    if (selectedItem) {
      getSuggestedItem(selectedItem);
    }
  };

  search = event => {
    if ("Escape" === event.key) {
      this.reset();
    } else {
      const { setSearch, searchItem, count } = this.props,
        search = this.searchInput.value;
      setSearch(search);
      if (search) {
        searchItem(search, count);
      }
    }
  };

  reset = () => {
    const { resetAutocomplete, selectItem } = this.props;
    resetAutocomplete();
    if (this.searchInput) {
      this.searchInput.value = "";
    }
    selectItem();
  };

  select = item => {
    const { setSelected, selectItem } = this.props;
    setSelected(item);
    selectItem(item._id);
  };

  render = () => {
    const {
        title,
        tabIndex,
        autoFocus,
        autocomplete,
        getName,
        required,
        hasError
      } = this.props,
      loading = autocomplete.loading,
      selected = autocomplete.selected,
      hasSearch = selected ? false : !!autocomplete.search,
      results = autocomplete.results,
      count = selected ? 0 : results.length;
    return (
      <div
        className={
          (required ? "required " : "") + "field" + (hasError ? " error" : "")
        }
      >
        <label htmlFor="item">{title}</label>
        {selected && (
          <div className="item">
            {getName(selected)}
            <i
              id="clear_selection"
              className="remove circle link icon"
              onClick={this.reset}
            />
          </div>
        )}
        {!selected && (
          <div className="ui category search item">
            <div className={"ui icon input" + (loading ? " loading" : "")}>
              <input
                tabIndex={tabIndex}
                className="prompt"
                type="text"
                name="search"
                id={"search_item_" + tabIndex}
                placeholder="Search"
                defaultValue={autocomplete.search}
                ref={input => (this.searchInput = input)}
                onKeyUp={this.search}
                autoFocus={autoFocus}
              />
              {hasSearch && (
                <i
                  id="clear_search"
                  className="remove circle link icon"
                  onClick={this.reset}
                />
              )}
              {!hasSearch && <i className="search icon" />}
            </div>
          </div>
        )}
        {!selected &&
          !hasSearch &&
          count <= 0 && (
            <div id="start_typing" className="ui info message">
              Start typing to search
            </div>
          )}
        {!loading &&
          hasSearch &&
          count <= 0 && (
            <div id="no_match" className="ui red message">
              No match
            </div>
          )}
        {count > 0 && (
          <div className="ui middle aligned divided selection list">
            {results.map(item => {
              const id = item._id;
              return (
                <div
                  id={"item_" + id}
                  className="item"
                  key={id}
                  onClick={() => {
                    this.select(item);
                  }}
                >
                  {getName(item)}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  autocomplete: state.ui.autocomplete
});

export default connect(
  mapStateToProps,
  {
    resetAutocomplete,
    setSearch,
    setSelected
  }
)(AutocompleteInput);
