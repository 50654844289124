import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const Order = ({ order }) => {
  const {
      _id,
      created,
      coupon,
      ip,
      secondaryIp,
      type,
      servers,
      billingCycle,
      price = {},
      autoRenew,
      status
    } = order,
    {
      amount,
      termDiscount,
      termPercent,
      subTotal,
      couponDiscount,
      couponPercent,
      couponRecurring,
      deduction,
      firstBillAmount,
      credit,
      total
    } = price;
  return (
    <table id="orderTable" className="ui celled collapsing table">
      <thead>
        <tr>
          <th>ID</th>
          <th>{_id}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Created</td>
          <td>{moment(created).format("YYYY-MM-DD hh:mm A")}</td>
        </tr>
        {!!coupon && (
          <tr>
            <td>Coupon ID</td>
            <td>{coupon}</td>
          </tr>
        )}
        <tr>
          <td>IP</td>
          <td>{ip}</td>
        </tr>
        {!!secondaryIp && (
          <tr>
            <td>Secondary IP</td>
            <td>{secondaryIp}</td>
          </tr>
        )}
        <tr>
          <td>Type</td>
          <td>{type}</td>
        </tr>
        <tr>
          <td>Servers</td>
          <td>{servers}</td>
        </tr>
        <tr>
          <td>Billing cycle</td>
          <td>{billingCycle} months</td>
        </tr>
        {!!amount && (
          <tr>
            <td>Amount</td>
            <td>{amount} USD</td>
          </tr>
        )}
        {!!termDiscount && (
          <tr>
            <td>Long term discount</td>
            <td>
              -$
              {termDiscount} USD ({termPercent}
              %)
            </td>
          </tr>
        )}
        {!!termDiscount && (
          <tr>
            <td>Sub total</td>
            <td>${subTotal} USD</td>
          </tr>
        )}
        {!!couponDiscount && (
          <tr>
            <td>Coupon discount</td>
            <td>
              -$
              {couponDiscount} USD
              {couponPercent && (
                <span>
                  {" "}
                  ({couponPercent}
                  %)
                </span>
              )}
              {couponRecurring ? ", recurring" : ", one-time"}
            </td>
          </tr>
        )}
        {!!total && (
          <tr>
            <td>
              <strong>Grand total</strong>
            </td>
            <td>
              <strong>${total} USD</strong>
            </td>
          </tr>
        )}
        {!!deduction && (
          <tr>
            <td>Deduction</td>
            <td>
              -$
              {deduction} USD
            </td>
          </tr>
        )}
        {!!firstBillAmount && (
          <tr>
            <td>First bill amount</td>
            <td>${firstBillAmount} USD</td>
          </tr>
        )}
        {!!credit && (
          <tr>
            <td>Credit</td>
            <td>${credit} USD</td>
          </tr>
        )}
        <tr>
          <td>Auto renew</td>
          <td className={autoRenew ? "positive" : "negative"}>
            {autoRenew ? "enabled" : "disabled"}
          </td>
        </tr>
        <tr>
          <td>Status</td>
          <td className={"active" === status ? "positive" : "negative"}>
            {status}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

Order.propTypes = {
  order: PropTypes.object.isRequired
};

export default Order;
