import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  resetStatusMessage,
  setStatusMessage,
  formSetError,
  formResetError,
  formToggleInfo,
  formSetInfo,
  formResetInfo,
  updatePrice,
  updateUnbilled,
  setCurrentLicense,
  readCustomer,
  readCoupon,
  readOrder,
  purchaseLicense,
  autocomplete,
  getSuggestedItem,
  getItem,
} from "../../actions";
import { getById } from "../../reducers/listing";
import * as validations from "../../lib/validations";
import { getTermPercent } from "../../lib/pricing";
import SimpleHeader from "../../components/SimpleHeader";
import PriceInfo from "./PriceInfo";
import Coupon from "./Coupon";
import CurrentBilling from "./CurrentBilling";
import isCouponApplicable from "./couponCheck";
import Order from "../../components/Order";
import AutocompleteInput from "../AutocompleteInput";

class LicenseForm extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    license: PropTypes.object.isRequired,
    customer: PropTypes.object,
    user: PropTypes.object,
    resetStatusMessage: PropTypes.func.isRequired,
    setStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formToggleInfo: PropTypes.func.isRequired,
    formSetInfo: PropTypes.func.isRequired,
    formResetInfo: PropTypes.func.isRequired,
    updatePrice: PropTypes.func.isRequired,
    updateUnbilled: PropTypes.func.isRequired,
    setCurrentLicense: PropTypes.func.isRequired,
    readCustomer: PropTypes.func.isRequired,
    readCoupon: PropTypes.func.isRequired,
    readOrder: PropTypes.func.isRequired,
    purchaseLicense: PropTypes.func.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
  };

  model = "license";
  coupon = null;
  customer = null;
  trialEnabled = false;

  componentWillMount = () => {
    const {
      history,
      match,
      listing,
      user,
      resetStatusMessage,
      formResetError,
      formSetInfo,
      formResetInfo,
      getItem,
      readCoupon,
      readOrder,
      updatePrice,
      setCurrentLicense,
      readCustomer,
    } = this.props;
    let priceInit = false;
    this.isAdmin = user ? "admin" === user.group : false;
    this.isAnyStaff = user ? this.isAdmin || "staff" === user.group : false;
    formResetInfo();
    resetStatusMessage();
    formResetError();
    if (match.params.id) {
      const license = getById(listing, match.params.id);
      if ("undefined" === typeof license) {
        history.push("/license");
      } else {
        getItem(this.model, match.params.id);
        readOrder({
          search: { _id: license.order },
          populate: [
            {
              path: "lastPaidInvoice",
              select: { dueDate: 1 },
            },
          ],
        });
        this.edit = true;
        if (license.customer) {
          this.customer = license.customer;
        }
      }
      const unbilledServers = license.unbilledServers
        ? license.unbilledServers
        : 0;
      if ("active" !== license.status) {
        updatePrice({
          servers: license.servers,
          unbilledServers,
          billingCycle: 1,
          termPercent: 0,
          trial: this.trialEnabled,
        });
        priceInit = true;
      } else {
        updateUnbilled(unbilledServers);
      }
    } else {
      setCurrentLicense(null);
      this.edit = false;
      if (match.params.trial) {
        this.trialEnabled = true;
        formSetInfo("trial", true);
      }
    }
    if (!priceInit) {
      updatePrice({
        servers: 0,
        billingCycle: 0,
        termPercent: 0,
        trial: this.trialEnabled,
      });
    }
    readCustomer();
    if (this.isAnyStaff) {
      if (this.customer) {
        readCoupon({
          search: {
            customer: this.customer,
            active: true,
            $or: [
              { expiry: { $exists: false } },
              { expiry: { $gte: new Date() } },
            ],
          },
        });
      }
    } else {
      readCoupon();
    }
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  getServersAndBillingCycle = () => {
    let billingCycle = parseInt(this.billingCycleField.value, 10),
      servers = parseInt(this.serversField.value.trim(), 10),
      unbilledServers = 0;
    if (isNaN(servers)) {
      servers = 0;
    }
    if (isNaN(billingCycle)) {
      billingCycle = 0;
    }
    if (this.unbilledServersField) {
      const ubs = parseInt(this.unbilledServersField.value.trim(), 10);
      if (!isNaN(ubs)) {
        unbilledServers = ubs;
      }
    }
    return { servers, unbilledServers, billingCycle };
  };

  applyCoupon = (coupon) => {
    const { license, setStatusMessage } = this.props,
      { servers, billingCycle } = this.getServersAndBillingCycle();
    if (coupon) {
      if (
        isCouponApplicable(
          coupon,
          servers,
          billingCycle,
          license.current ? "update" : "new"
        )
      ) {
        this.coupon = coupon;
      } else {
        setStatusMessage("Invalid coupon", "error", 10);
      }
    } else {
      this.coupon = null;
    }
    this.updatePrice();
  };

  updateUnbilled = () => {
    const { updateUnbilled } = this.props;
    let unbilledServers = this.unbilledServersField.value.trim();

    if (unbilledServers) {
      unbilledServers = parseInt(unbilledServers, 10);
      if (isNaN(unbilledServers)) {
        unbilledServers = 0;
      }
    } else {
      unbilledServers = 0;
    }
    updateUnbilled(unbilledServers);
  };

  updatePrice = () => {
    const { customer, license, formSetInfo, updatePrice } = this.props,
      { servers, unbilledServers, billingCycle } = this.getServersAndBillingCycle(),
      termPercent = getTermPercent(billingCycle),
      trial = this.trialField ? this.trialField.checked : false;

    if (this.trialField) {
      formSetInfo("trial", trial);
    }
    if (
      this.coupon &&
      !isCouponApplicable(
        this.coupon,
        servers,
        billingCycle,
        license.current ? "update" : "new"
      )
    ) {
      this.coupon = null;
    }
    updatePrice({
      servers,
      unbilledServers,
      billingCycle,
      termPercent,
      coupon: this.coupon,
      ip: this.ipField.value.trim(),
      secondaryIp: this.secondaryIpField.value.trim(),
      credit: customer ? customer.credit : 0,
      trial,
    });
  };

  submit = (event) => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
      formSetError,
      formResetError,
      purchaseLicense,
      license,
    } = this.props,
      {
        ip,
        secondaryIp,
        servers,
        unbilledServers,
        billingCycle,
        price,
        appliedCoupon,
        current,
      } = license;
    let hasError = false;
    formResetError();
    if (servers <= 0) {
      formSetError("servers");
      hasError = true;
    }
    if (unbilledServers > 9999) {
      formSetError("unbilledServers");
      hasError = true;
    }
    if (billingCycle <= 0) {
      formSetError("billingCycle");
      hasError = true;
    }
    if (
      (!this.isAdmin && !validations.publicIpv4(ip)) ||
      !validations.ipv4(ip)
    ) {
      formSetError("ip");
      hasError = true;
    }
    if (secondaryIp) {
      if (
        (!this.isAdmin && !validations.publicIpv4(secondaryIp)) ||
        !validations.ipv4(secondaryIp)
      ) {
        formSetError("secondaryIp");
        hasError = true;
      }
      if (ip === secondaryIp) {
        formSetError("sameIp");
        hasError = true;
      }
    }
    if (!hasError) {
      const data = {
        servers,
        billingCycle,
        ip,
        price,
      };
      if ("undefined" !== typeof unbilledServers) {
        data.unbilledServers = unbilledServers;
      }
      if (secondaryIp) {
        data.secondaryIp = secondaryIp;
      }
      if (appliedCoupon) {
        data.coupon = appliedCoupon._id;
      }
      if (current) {
        data._id = current._id;
      }
      if (this.customer) {
        data.customer = this.customer;
      }
      if (this.tempExpiryField) {
        const tempExpiry = this.tempExpiryField.value.trim();
        if (tempExpiry) {
          data.tempExpiry = tempExpiry;
        }
      }
      if (this.buildField) {
        data.build = this.buildField.value.trim();
      }
      if (this.allowInstallField) {
        data.allowInstall = this.allowInstallField.checked;
      }
      if (this.trialField) {
        data.trial = this.trialField.checked;
      }
      purchaseLicense(data);
    }
    return false;
  };

  selectCustomer = (id) => {
    this.customer = id;
    this.props.readCoupon({
      search: { customer: id, active: true, expiry: { $gte: new Date() } },
    });
  };

  render = () => {
    const {
      history,
      form,
      item,
      license,
      customer,
      formToggleInfo,
      getSuggestedItem,
    } = this.props,
      edit = this.edit,
      myLicense = license.current,
      order = license.order,
      needPayment =
        edit && order
          ? license.servers !== myLicense.servers ||
          license.billingCycle !== order.billingCycle
          : true,
      proceed = edit
        ? this.isAdmin ||
        needPayment ||
        license.ip !== myLicense.ip ||
        ((license.secondaryIp || myLicense.secondaryIp) &&
          license.secondaryIp !== myLicense.secondaryIp)
        : !!license.price,
      hasCredit = !!(customer && customer.credit),
      showLoading = edit && (item.loading || !myLicense || !order);
    if (edit && !this.customer && license.customer) {
      this.customer = license.customer;
    }
    return (
      <article className="thirteen wide column">
        <SimpleHeader title={edit ? "Edit license" : "New license"} />
        {showLoading && (
          <div className="ui active inline text loader">Loading...</div>
        )}
        {!showLoading && (
          <div className="ui">
            {form.hasError && (
              <div className="ui error message">
                <ul className="list">
                  {(form.errorFields.servers ||
                    form.errorFields.unbilledServers) && (
                      <li>Enter a valid number of servers</li>
                    )}
                  {form.errorFields.billingCycle && (
                    <li>Select a billing cycle</li>
                  )}
                  {(form.errorFields.ip || form.errorFields.secondaryIp) && (
                    <li>Enter a valid public IP (v4) address</li>
                  )}
                  {form.errorFields.sameIp && (
                    <li>Primary and secondary IPs cannot be the same</li>
                  )}
                </ul>
              </div>
            )}
            <form className="ui form" onSubmit={this.submit}>
              {this.isAnyStaff && (
                <AutocompleteInput
                  title="Customer"
                  count={3}
                  tabIndex={5}
                  selectedItem={myLicense ? myLicense.customer : null}
                  getSuggestedItem={(id) => {
                    getSuggestedItem("customer", id, { _id: 1, company: 1 });
                  }}
                  searchItem={this.autocomplete}
                  required={true}
                  hasError={form.errorFields.customer}
                  getName={(customer) => customer.company}
                  selectItem={this.selectCustomer}
                />
              )}
              <div
                className={
                  "required field" + (form.errorFields.servers ? " error" : "")
                }
              >
                <label htmlFor="servers">
                  No. of servers{" "}
                  <i
                    className="info circle blue icon"
                    title="Click for more info"
                    onClick={() => formToggleInfo("servers")}
                  />
                </label>
                <div
                  className={
                    (form.infoFields.servers ? "" : "hidden ") +
                    "ui info message"
                  }
                >
                  The maximum number of servers that need to be managed with
                  Ezeelogin
                </div>
                <input
                  type="number"
                  tabIndex="10"
                  name="servers"
                  id="servers"
                  placeholder="Number of servers that may be managed"
                  autoComplete="off"
                  autoFocus={true}
                  ref={(input) => (this.serversField = input)}
                  defaultValue={edit ? myLicense.servers : ""}
                  onChange={this.updatePrice}
                />
              </div>
              {this.isAdmin && (
                <div
                  className={
                    "required field" +
                    (form.errorFields.servers ? " error" : "")
                  }
                >
                  <label htmlFor="unbilledServers">
                    No. of unbilled servers{" "}
                    <i
                      className="info circle blue icon"
                      title="Click for more info"
                      onClick={() => formToggleInfo("unbilledServers")}
                    />
                  </label>
                  <div
                    className={
                      (form.infoFields.unbilledServers ? "" : "hidden ") +
                      "ui info message"
                    }
                  >
                    Additional unbilled servers from previously owned license
                  </div>
                  <input
                    type="number"
                    tabIndex="11"
                    name="unbilledServers"
                    id="unbilledServers"
                    placeholder="Number of unbilled servers"
                    autoComplete="off"
                    autoFocus={true}
                    ref={(input) => (this.unbilledServersField = input)}
                    defaultValue={edit ? myLicense.unbilledServers : ""}
                    onChange={this.updateUnbilled}
                  />
                </div>
              )}
              <div
                className={
                  "required field" +
                  (form.errorFields.billingCycle ? " error" : "")
                }
              >
                <label htmlFor="billingCycle">Billing cycle</label>
                <select
                  id="billingCycle"
                  name="billingCycle"
                  tabIndex="20"
                  ref={(input) => (this.billingCycleField = input)}
                  onChange={this.updatePrice}
                  defaultValue={edit ? order.billingCycle : ""}
                >
                  <option value="">Select</option>
                  <option value="1">Monthly (no discount)</option>
                  <option value="3">Quarterly (2% discount)</option>
                  <option value="6">Half-yearly (5% discount)</option>
                  <option value="12">Yearly (10% discount)</option>
                </select>
              </div>
              <div
                className={
                  "required field" + (form.errorFields.ip ? " error" : "")
                }
              >
                <label htmlFor="ip">
                  IP address{" "}
                  <i
                    className="info circle blue icon"
                    title="Click for more info"
                    onClick={() => formToggleInfo("ip")}
                  />
                </label>
                <div
                  className={
                    (form.infoFields.ip ? "" : "hidden ") + "ui info message"
                  }
                >
                  The public IPv4 address of the gateway server where Ezeelogin
                  needs to be installed
                </div>
                <input
                  type="text"
                  tabIndex="30"
                  id="ip"
                  name="ip"
                  placeholder="IP address of the gateway server"
                  autoComplete="off"
                  required={true}
                  ref={(input) => (this.ipField = input)}
                  onChange={this.updatePrice}
                  defaultValue={edit ? myLicense.ip : ""}
                />
              </div>
              <div
                className={
                  "field" +
                  (form.errorFields.secondaryIp || form.errorFields.sameIp
                    ? " error"
                    : "")
                }
              >
                <label htmlFor="ip">
                  Secondary IP address (optional){" "}
                  <i
                    className="info circle blue icon"
                    title="Click for more info"
                    onClick={() => formToggleInfo("secondaryIp")}
                  />
                </label>
                <div
                  className={
                    (form.infoFields.secondaryIp ? "" : "hidden ") +
                    "ui info message"
                  }
                >
                  The public IPv4 address of the secondary gateway server for
                  high availability. You may add this any time later, free of
                  cost.
                </div>
                <input
                  type="text"
                  tabIndex="40"
                  id="secondaryIp"
                  name="secondaryIp"
                  placeholder="IP address of the secondary gateway server"
                  autoComplete="off"
                  ref={(input) => (this.secondaryIpField = input)}
                  onChange={this.updatePrice}
                  defaultValue={edit ? myLicense.secondaryIp : ""}
                />
              </div>
              {!edit && (this.isAnyStaff || license.hasTrial) && (
                <div className="inline field">
                  <div className="ui checkbox">
                    <input
                      id="trial"
                      type="checkbox"
                      tabIndex="50"
                      name="trial"
                      ref={(input) => (this.trialField = input)}
                      defaultChecked={this.trialEnabled}
                      onChange={this.updatePrice}
                    />
                    <label>
                      30 days free trial{" "}
                      <i
                        className="info circle blue icon"
                        title="Click for more info"
                        onClick={() => formToggleInfo("trial")}
                      />
                    </label>
                    <div
                      className={
                        (form.infoFields.trial ? "" : "hidden ") +
                        "ui info message"
                      }
                    >
                      The first invoice will be due only when the trial ends,
                      after 30 days. You can pay the invoice on or before due
                      date to continue using the license.
                    </div>
                  </div>
                </div>
              )}
              {this.isAnyStaff && edit && (
                <div className="field">
                  <label htmlFor="expiry">Original Expiry</label>
                  <input
                    type="date"
                    readOnly={true}
                    disabled={true}
                    id="expiry"
                    name="expiry"
                    defaultValue={moment(myLicense.expiry).format("YYYY-MM-DD")}
                  />
                </div>
              )}
              {this.isAnyStaff && edit && (
                <div className="field">
                  <label htmlFor="tempExpiry">Temporary Expiry</label>
                  <input
                    type="date"
                    tabIndex="60"
                    id="tempExpiry"
                    name="tempExpiry"
                    autoComplete="off"
                    ref={(input) => (this.tempExpiryField = input)}
                    min={moment(myLicense.expiry)
                      .add(1, "day")
                      .format("YYYY-MM-DD")}
                    max={moment().add(1, "year").format("YYYY-MM-DD")}
                    defaultValue={
                      myLicense.tempExpiry
                        ? moment(myLicense.tempExpiry).format("YYYY-MM-DD")
                        : ""
                    }
                  />
                </div>
              )}
              {this.isAdmin && edit && (
                <div className="field">
                  <label htmlFor="id">Build ID</label>
                  <input
                    type="text"
                    tabIndex="70"
                    name="build"
                    id="build"
                    placeholder="Exact ID"
                    defaultValue={myLicense.build}
                    ref={(input) => (this.buildField = input)}
                  />
                </div>
              )}
              {this.isAnyStaff && edit && (
                <div className="inline field">
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      tabIndex="80"
                      id="allowInstall"
                      name="allowInstall"
                      ref={(input) => (this.allowInstallField = input)}
                      defaultChecked={myLicense.allowInstall}
                    />
                    <label>Allow install</label>
                  </div>
                </div>
              )}
              {needPayment && (
                <div className="ui stackable two column grid">
                  <div className="column">
                    <PriceInfo />
                  </div>
                  <div className="column">
                    <Coupon applyCoupon={this.applyCoupon} />
                  </div>
                </div>
              )}
              <div className="ui hidden divider" />
              <button
                id="cancel"
                tabIndex="110"
                className="ui negative button"
                type="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </button>
              <button
                tabIndex="100"
                className={
                  proceed ? "ui primary button" : "ui primary disabled button"
                }
                type="submit"
              >
                {edit ? "Update" : "Buy"}
              </button>
            </form>
            {edit && (
              <div className="ui segment">
                <div className="ui stackable two column grid">
                  {"active" === myLicense.status && (
                    <div className="column">
                      <h3 className="ui header">Current billing</h3>
                      <CurrentBilling />
                      {hasCredit && (
                        <div className="ui segment">
                          Credit balance: ${customer.credit} USD
                        </div>
                      )}
                    </div>
                  )}
                  <div className="column">
                    <h3 className="ui header">Order details</h3>
                    <Order order={order} />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </article>
    );
  };
}

const mapStateToProps = (state) => ({
  form: state.ui.form,
  listing: state.listing,
  item: state.item,
  license: state.license,
  customer: state.customer.data,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, {
    resetStatusMessage,
    setStatusMessage,
    formSetError,
    formResetError,
    formToggleInfo,
    formSetInfo,
    formResetInfo,
    updatePrice,
    updateUnbilled,
    setCurrentLicense,
    readCustomer,
    readCoupon,
    readOrder,
    purchaseLicense,
    autocomplete,
    getSuggestedItem,
    getItem,
  })(LicenseForm)
);
