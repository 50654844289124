import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { readPrivacy, syncPrivacy } from "../../actions/privacy";

class PrivacyForm extends Component {
  static propTypes = {
    // Injected by React-Redux
    privacy: PropTypes.object.isRequired,
    agent: PropTypes.object,
    customer: PropTypes.object,
    readPrivacy: PropTypes.func.isRequired,
    syncPrivacy: PropTypes.func.isRequired
  };

  types = {
    invoice: "Invoice",
    invoiceReminder: "Invoice reminder",
    licenseExpiry: "License expiry",
    renewSuccess: "Auto-renew success",
    renewFailure: "Auto-renew failure"
  };

  inputRefs = {};

  componentWillMount = () => {
    this.props.readPrivacy();
  };

  getEmails = () => {
    const { agent, customer } = this.props,
      emails = [];
    emails.push(agent.email);
    if (agent.email2 && !emails.includes(agent.email2)) {
      emails.push(agent.email2);
    }
    if (customer.email && !emails.includes(customer.email)) {
      emails.push(customer.email);
    }
    return emails;
  };

  reset = () => {
    const map = this.props.privacy.map,
      emails = this.getEmails();
    Object.keys(this.types).forEach(type => {
      emails.forEach((email, index) => {
        this.inputRefs[`${type}_${index}`].checked =
          map[type] && map[type][email];
      });
    });
  };

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const { syncPrivacy } = this.props,
      emails = this.getEmails(),
      data = {};
    Object.keys(this.types).forEach(type => {
      data[type] = {};
      emails.forEach((email, index) => {
        data[type][email] = this.inputRefs[`${type}_${index}`].checked;
      });
    });
    syncPrivacy(data);
  };

  render = () => {
    const { privacy, agent, customer } = this.props;
    if (!agent || !customer || privacy.loading) {
      return (
        <div className="ui segment">
          <div className="ui hidden divider" />
          <div className="ui active loader" />
          <div className="ui hidden divider" />
        </div>
      );
    }
    const map = privacy.map,
      emails = this.getEmails();
    return (
      <div className="ui">
        <div className="ui info message">
          Note: If a checkbox is checked, the corresponding type of email will
          not be send to the corresponding email.
        </div>
        <form className="ui form" onSubmit={this.submit}>
          <table className="ui celled definition table">
            <thead>
              <tr>
                <th />
                {emails.map((email, index) => (
                  <th key={`head_${index}`}>{email}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(this.types).map(type => (
                <tr key={type}>
                  <td>{this.types[type]}</td>
                  {emails.map((email, index) => (
                    <td key={`${type}_${index}`}>
                      <input
                        type="checkbox"
                        name={`${type}_${index}`}
                        defaultChecked={map[type] && map[type][email]}
                        ref={input =>
                          (this.inputRefs[`${type}_${index}`] = input)
                        }
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <button
            id="cancel"
            tabIndex="210"
            className="ui negative button"
            type="button"
            onClick={this.reset}
          >
            Cancel
          </button>
          <button tabIndex="200" className="ui primary button" type="submit">
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  privacy: state.privacy,
  agent: state.agent.data,
  customer: state.customer.data
});

export default connect(
  mapStateToProps,
  {
    readPrivacy,
    syncPrivacy
  }
)(PrivacyForm);
