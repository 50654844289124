import React from "react";
import { render } from "react-dom";
import configureStore from "./store/configureStore";
import history from "./history";
import registerServiceWorker from "./registerServiceWorker";
import Root from "./containers/Root";
import "semantic-ui-css/semantic.min.css";
import "./common.css";

const store = configureStore(true);

render(
  <Root store={store} history={history} />,
  document.getElementById("root")
);
registerServiceWorker();
