export const loadState = () => {
  if (typeof localStorage === "undefined") {
    return undefined;
  }
  try {
    const serializedState = localStorage.getItem("state");
    if (null === serializedState) {
      return undefined;
    }
    console.info("Saved state:", serializedState);
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("loadState: ERROR:", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {
    console.error("saveState: ERROR:", err);
  }
};
