import React from "react";
import PropTypes from "prop-types";
import ScreenTitle from "../ScreenTitle";

const SimpleHeader = ({ title }) => (
  <header className="ui dividing header">
    <ScreenTitle title={title} />
  </header>
);

SimpleHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default SimpleHeader;
