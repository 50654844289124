import { CALL_SOCKET_API } from "../middleware/agent-api";

export const LICENSE_PURCHASE_REQUEST = "LICENSE_PURCHASE_REQUEST";
export const LICENSE_PURCHASE_SUCCESS = "LICENSE_PURCHASE_SUCCESS";
export const LICENSE_PURCHASE_FAILURE = "LICENSE_PURCHASE_FAILURE";
export const purchaseLicense = (data) => ({
  [CALL_SOCKET_API]: {
    types: [
      LICENSE_PURCHASE_REQUEST,
      LICENSE_PURCHASE_SUCCESS,
      LICENSE_PURCHASE_FAILURE,
    ],
    endpoint: "purchaseLicense",
    data,
  },
});

export const LICENSE_UPDATE_PRICE = "LICENSE_UPDATE_PRICE";
export const updatePrice = (data) => ({
  type: LICENSE_UPDATE_PRICE,
  data,
});

export const LICENSE_UPDATE_UNBILLED = "LICENSE_UPDATE_UNBILLED";
export const updateUnbilled = (servers) => ({
  type: LICENSE_UPDATE_UNBILLED,
  servers,
});

export const LICENSE_UPDATE_REQUEST = "LICENSE_UPDATE_REQUEST";
export const LICENSE_UPDATE_SUCCESS = "LICENSE_UPDATE_SUCCESS";
export const LICENSE_UPDATE_FAILURE = "LICENSE_UPDATE_FAILURE";
export const updateLicense = (data) => ({
  [CALL_SOCKET_API]: {
    types: [
      LICENSE_UPDATE_REQUEST,
      LICENSE_UPDATE_SUCCESS,
      LICENSE_UPDATE_FAILURE,
    ],
    endpoint: "updateLicense",
    data,
  },
});

export const LICENSE_COUPON_REQUEST = "LICENSE_COUPON_REQUEST";
export const LICENSE_COUPON_SUCCESS = "LICENSE_COUPON_SUCCESS";
export const LICENSE_COUPON_FAILURE = "LICENSE_COUPON_FAILURE";
export const readCoupon = (data) => ({
  [CALL_SOCKET_API]: {
    types: [
      LICENSE_COUPON_REQUEST,
      LICENSE_COUPON_SUCCESS,
      LICENSE_COUPON_FAILURE,
    ],
    endpoint: "readCoupon",
    nonBlocking: true,
    data,
  },
});

export const LICENSE_ORDER_REQUEST = "LICENSE_ORDER_REQUEST";
export const LICENSE_ORDER_SUCCESS = "LICENSE_ORDER_SUCCESS";
export const LICENSE_ORDER_FAILURE = "LICENSE_ORDER_FAILURE";
export const readOrder = (data) => ({
  [CALL_SOCKET_API]: {
    types: [
      LICENSE_ORDER_REQUEST,
      LICENSE_ORDER_SUCCESS,
      LICENSE_ORDER_FAILURE,
    ],
    endpoint: "readOrder",
    nonBlocking: true,
    data,
  },
});

export const LICENSE_SET_CURRENT = "LICENSE_SET_CURRENT";
export const setCurrentLicense = (data) => ({
  type: LICENSE_SET_CURRENT,
  data,
});

export const CONFIRM_ORDER_CANCEL = "CONFIRM_ORDER_CANCEL";
export const confirmOrderCancel = () => ({
  type: CONFIRM_ORDER_CANCEL,
});

export const RESET_ORDER_CANCEL = "RESET_ORDER_CANCEL";
export const resetOrderCancel = () => ({
  type: RESET_ORDER_CANCEL,
});

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";
export const cancelOrder = (id) => ({
  [CALL_SOCKET_API]: {
    types: [CANCEL_ORDER_REQUEST, CANCEL_ORDER_SUCCESS, CANCEL_ORDER_FAILURE],
    endpoint: "cancelOrder",
    data: { id },
  },
});

export const UPDATE_ORDER_AUTORENEW_REQUEST = "UPDATE_ORDER_AUTORENEW_REQUEST";
export const UPDATE_ORDER_AUTORENEW_SUCCESS = "UPDATE_ORDER_AUTORENEW_SUCCESS";
export const UPDATE_ORDER_AUTORENEW_FAILURE = "UPDATE_ORDER_AUTORENEW_FAILURE";
export const updateOrderAutoRenew = (id, autoRenew) => ({
  [CALL_SOCKET_API]: {
    types: [
      UPDATE_ORDER_AUTORENEW_REQUEST,
      UPDATE_ORDER_AUTORENEW_SUCCESS,
      UPDATE_ORDER_AUTORENEW_FAILURE,
    ],
    endpoint: "updateOrderAutoRenew",
    data: { id, autoRenew },
  },
});

export const CHECK_TRIAL_REQUEST = "CHECK_TRIAL_REQUEST";
export const CHECK_TRIAL_SUCCESS = "CHECK_TRIAL_SUCCESS";
export const CHECK_TRIAL_FAILURE = "CHECK_TRIAL_FAILURE";
export const checkTrial = () => ({
  [CALL_SOCKET_API]: {
    types: [CHECK_TRIAL_REQUEST, CHECK_TRIAL_SUCCESS, CHECK_TRIAL_FAILURE],
    endpoint: "hasTrial",
    nonBlocking: true,
  },
});

export const STANDALONE_LICENSE_REQUEST = "STANDALONE_LICENSE_REQUEST";
export const STANDALONE_LICENSE_SUCCESS = "STANDALONE_LICENSE_SUCCESS";
export const STANDALONE_LICENSE_FAILURE = "STANDALONE_LICENSE_FAILURE";
export const getStandaloneLicense = (ip) => ({
  [CALL_SOCKET_API]: {
    types: [
      STANDALONE_LICENSE_REQUEST,
      STANDALONE_LICENSE_SUCCESS,
      STANDALONE_LICENSE_FAILURE,
    ],
    endpoint: "getStandaloneLicense",
    nonBlocking: true,
    data: { ip },
  },
});
