import { LOGIN_MODE } from "../../actions/ui/login";
import {
  RESET_STATUS_MESSAGE,
  SET_STATUS_MESSAGE
} from "../../actions/ui/statusMessage";

// Updates status message to notify the user.
const statusMessage = (state = null, action) => {
  const {
    type,
    error,
    resetStatusMessage,
    message,
    description,
    messageType,
    messageTimeout
  } = action;

  if (
    RESET_STATUS_MESSAGE === type ||
    (resetStatusMessage && state && "success" !== state.type) ||
    LOGIN_MODE === type
  ) {
    return null;
  } else if (type === SET_STATUS_MESSAGE) {
    return { message, description, type: messageType, timeout: messageTimeout };
  } else if (error) {
    return { message: error, type: "error" };
  }

  return state;
};

export default statusMessage;
