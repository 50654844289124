import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import config from "../../config";
import { StripeProvider, Elements } from "react-stripe-elements";
import { resetNewCard } from "../../actions/payment";
import SimpleHeader from "../../components/SimpleHeader";
import CardForm from "./CardForm";
import CardInfo from "../../components/CardInfo";

class AddCard extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    newCard: PropTypes.object,
    resetNewCard: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    this.props.resetNewCard();
  };

  render = () => {
    const { history, newCard, resetNewCard } = this.props;
    return (
      <article className="thirteen wide column">
        <SimpleHeader title="New card" />
        {!newCard && (
          <StripeProvider apiKey={config.stripeApiKey}>
            <Elements>
              <CardForm />
            </Elements>
          </StripeProvider>
        )}
        {newCard && (
          <div className="ui">
            <div className="ui positive message">New card added</div>
            <CardInfo card={newCard} />
            <button
              id="add"
              tabIndex="110"
              className="ui button"
              type="button"
              onClick={resetNewCard}
            >
              Add another
            </button>
            <button
              id="return"
              tabIndex="100"
              className="ui primary button"
              type="button"
              onClick={() => {
                history.push("/payment");
              }}
            >
              Done
            </button>
          </div>
        )}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  newCard: state.payment.newCard
});

export default withRouter(
  connect(
    mapStateToProps,
    { resetNewCard }
  )(AddCard)
);
