import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  resetStatusMessage,
  formSetError,
  formResetError,
  formSetData,
  formResetData,
  formResetPasswordStrength,
  createItem,
  updateItem,
  autocomplete,
  getSuggestedItem
} from "../../actions";
import * as validations from "../../lib/validations";
import PasswordInput from "../PasswordInput";
import AvatarInput from "../AvatarInput";
import AutocompleteInput from "../AutocompleteInput";

class Form extends Component {
  static propTypes = {
    agent: PropTypes.object,
    edit: PropTypes.bool,
    profile: PropTypes.bool,
    onCancel: PropTypes.func,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetData: PropTypes.func.isRequired,
    formResetData: PropTypes.func.isRequired,
    formResetPasswordStrength: PropTypes.func.isRequired,
    createItem: PropTypes.func.isRequired,
    updateItem: PropTypes.func.isRequired,
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  model = "agent";

  componentWillMount = () => {
    const {
      agent,
      profile,
      user,
      resetStatusMessage,
      formResetError,
      formResetData,
      formSetData
    } = this.props;
    resetStatusMessage();
    formResetError();
    formResetData();
    if (
      !profile &&
      ("staff" === user.group || (agent && "customer" === agent.group))
    ) {
      formSetData("group", "customer");
    }
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  setAvatar = avatar => {
    this.avatar = avatar;
    this.isNewAvatar = false;
  };

  unsetAvatar = () => {
    this.avatar = undefined;
    this.isNewAvatar = false;
  };

  newAvatar = () => (this.isNewAvatar = true);

  newAvatarCancel = () => (this.isNewAvatar = false);

  submit = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const {
        agent,
        edit,
        profile,
        user,
        formSetError,
        formResetError,
        createItem,
        updateItem
      } = this.props,
      firstName = this.firstNameField.value.trim(),
      lastName = this.lastNameField.value.trim(),
      email = this.emailField.value.trim(),
      password = this.passwordInput.value.trim(),
      group = this.groupField
        ? this.groupField.value
        : !profile && "staff" === user.group
          ? "customer"
          : "";
    let hasError = false;
    formResetError();
    if (!validations.standardText(firstName)) {
      formSetError("firstName");
      hasError = true;
    }
    if (!validations.standardText(lastName)) {
      formSetError("lastName");
      hasError = true;
    }
    if (!validations.email(email)) {
      formSetError("email");
      hasError = true;
    }
    if (!edit || password) {
      if (password === email) {
        formSetError("passwordIsEmail");
        hasError = true;
      } else if (!validations.strongPassword(password)) {
        formSetError("password");
        hasError = true;
      }
      if (this.confirmPasswordField.value !== password) {
        formSetError("confirmPassword");
        hasError = true;
      }
    }
    if (this.groupField) {
      if (!group) {
        formSetError("group");
        hasError = true;
      } else {
        if (
          edit &&
          (("customer" === agent.group && "customer" !== group) ||
            ("customer" !== agent.group && "customer" === group))
        ) {
          formSetError("customerGroupChange");
          hasError = true;
        }
        if ("customer" === group && !this.customer) {
          formSetError("customer");
          hasError = true;
        }
      }
    } else if (!profile && edit && "staff" === agent.group && !this.customer) {
      formSetError("customer");
      hasError = true;
    }
    if (this.isNewAvatar) {
      formSetError("avatar");
      hasError = true;
    }
    if (!hasError) {
      const data = {
        email,
        avatar: this.avatar
      };
      if (edit) {
        data._id = agent._id;
      }
      if (firstName) {
        data.firstName = firstName;
      }
      if (lastName) {
        data.lastName = lastName;
      }
      if (password) {
        data.password = password;
      }
      if (group) {
        data.group = group;
        if ("customer" === group) {
          data.customer = this.customer;
        }
      }
      if (this.activeField) {
        data.active = this.activeField.checked;
      }
      edit ? updateItem(this.model, data) : createItem(this.model, data);
    }
    return false;
  };

  reset = () => {
    const { agent, formResetPasswordStrength } = this.props;
    if (agent) {
      this.firstNameField.value = agent.firstName ? agent.firstName : "";
      this.lastNameField.value = agent.lastName ? agent.lastName : "";
      this.emailField.value = agent.email ? agent.email : "";
      if (this.activeField) {
        this.activeField.checked = !!agent.active;
      }
      if (this.groupField) {
        this.groupField.value = agent.group;
      }
      this.avatarReInit(agent.avatar);
    } else {
      this.firstNameField.value = "";
      this.lastNameField.value = "";
      this.emailField.value = "";
      if (this.activeField) {
        this.activeField.checked = false;
      }
      if (this.groupField) {
        this.groupField.value = "";
      }
      this.avatarReInit();
    }
    this.passwordInput.value = "";
    this.confirmPasswordField.value = "";
    formResetPasswordStrength();
  };

  render = () => {
    const {
        agent,
        edit,
        profile,
        form,
        user,
        onCancel,
        formSetData,
        getSuggestedItem
      } = this.props,
      myAgent = edit ? agent : {};
    if (!myAgent) {
      return null;
    } else if (myAgent.customer) {
      this.customer = myAgent.customer;
    }
    this.avatar = myAgent.avatar;
    return (
      <div className="ui">
        {!edit &&
          "customer" === user.group && (
            <div className="ui warning message">
              <strong>NOTE:</strong> All your agents have equal privilege and
              can manage your company account including terminate the account as
              you can. Only create agent accounts for trusted people.
            </div>
          )}
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.firstName && <li>Enter a valid first name</li>}
              {form.errorFields.lastName && <li>Enter a valid last name</li>}
              {form.errorFields.email && <li>Enter a valid email address</li>}
              {form.errorFields.passwordIsEmail && (
                <li>Password cannot be the same as the email address</li>
              )}
              {form.errorFields.password && <li>Enter a stronger password</li>}
              {form.errorFields.confirmPassword && (
                <li>Passwords do not match</li>
              )}
              {form.errorFields.avatar && (
                <li>Crop the image to set the avatar</li>
              )}
              {form.errorFields.customer && <li>Select the customer</li>}
              {form.errorFields.customerGroupChange && (
                <li>Agent cannot be changed from/to customer group</li>
              )}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div className="field">
            <label htmlFor="newFirstName">First name</label>
            <input
              type="text"
              tabIndex="10"
              name="newFirstName"
              id="newFirstName"
              placeholder="First name"
              autoComplete="off"
              autoFocus={true}
              ref={input => (this.firstNameField = input)}
              defaultValue={myAgent.firstName}
            />
          </div>
          <div className="field">
            <label htmlFor="newLastName">Last name</label>
            <input
              type="text"
              tabIndex="20"
              name="newLastName"
              id="newLastName"
              placeholder="Last name"
              autoComplete="off"
              ref={input => (this.lastNameField = input)}
              defaultValue={myAgent.lastName}
            />
          </div>
          <div
            className={
              "required field" + (form.errorFields.email ? " error" : "")
            }
          >
            <label htmlFor="newEmail">Email</label>
            <input
              type="email"
              tabIndex="30"
              name="newEmail"
              id="newEmail"
              placeholder="Email address"
              autoComplete="off"
              required={true}
              ref={input => (this.emailField = input)}
              defaultValue={myAgent.email}
            />
          </div>
          <PasswordInput
            tabIndex={40}
            required={!edit}
            inputRef={input => (this.passwordInput = input)}
          />
          <div
            className={
              (edit ? "" : "required ") +
              "field" +
              (form.errorFields.confirmPassword ? " error" : "")
            }
          >
            <label htmlFor="confirmPassword">Confirm password</label>
            <input
              type="password"
              tabIndex="50"
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Password"
              autoComplete="off"
              required={!edit}
              ref={input => (this.confirmPasswordField = input)}
            />
          </div>
          {!profile &&
            (!edit || user._id !== myAgent._id) && (
              <div className="inline field">
                <div className="ui checkbox">
                  <input
                    id="active"
                    type="checkbox"
                    tabIndex="60"
                    name="active"
                    ref={input => (this.activeField = input)}
                    defaultChecked={myAgent.active}
                  />
                  <label>Active</label>
                </div>
              </div>
            )}
          {!profile &&
            "admin" === user.group && (
              <div
                className={
                  "required field" +
                  (form.errorFields.group ||
                  form.errorFields.customerGroupChange
                    ? " error"
                    : "")
                }
              >
                <label htmlFor="group">Group</label>
                <select
                  id="group"
                  name="group"
                  tabIndex="70"
                  ref={input => (this.groupField = input)}
                  defaultValue={myAgent.group}
                  onChange={() => {
                    formSetData("group", this.groupField.value);
                  }}
                >
                  <option value="">Select</option>
                  {["admin", "staff", "customer"].map(item => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                  ;
                </select>
              </div>
            )}
          {"customer" === form.dataFields.group && (
            <AutocompleteInput
              title="Customer"
              count={3}
              tabIndex={80}
              selectedItem={myAgent.customer}
              getSuggestedItem={id => {
                getSuggestedItem("customer", id, { _id: 1, company: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              hasError={form.errorFields.customer}
              getName={customer => customer.company}
              selectItem={id => {
                this.customer = id;
              }}
            />
          )}
          <AvatarInput
            tabIndex={90}
            setAvatar={this.setAvatar}
            unsetAvatar={this.unsetAvatar}
            newAvatar={this.newAvatar}
            newAvatarCancel={this.newAvatarCancel}
            defaultValue={myAgent.avatar}
            hasError={form.errorFields.avatar}
            reInitRef={reInit => (this.avatarReInit = reInit)}
          />
          <button
            id="cancelAgent"
            tabIndex="110"
            className="ui negative button"
            type="button"
            onClick={onCancel ? onCancel : this.reset}
          >
            Cancel
          </button>
          <button
            id="submit"
            tabIndex="100"
            className="ui primary button"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  form: state.ui.form,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  {
    resetStatusMessage,
    formSetError,
    formResetError,
    formSetData,
    formResetData,
    formResetPasswordStrength,
    createItem,
    updateItem,
    autocomplete,
    getSuggestedItem
  }
)(Form);
