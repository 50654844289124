import { CALL_SOCKET_API } from "../../middleware/agent-api";
import { ucFirst } from "../../lib/strings";

export const AUTOCOMPLETE_RESET = "AUTOCOMPLETE_RESET";
export const resetAutocomplete = () => ({
  type: AUTOCOMPLETE_RESET
});

export const AUTOCOMPLETE_SELECTED = "AUTOCOMPLETE_SELECTED";
export const setSelected = item => ({
  type: AUTOCOMPLETE_SELECTED,
  item
});

export const AUTOCOMPLETE_SEARCH = "AUTOCOMPLETE_SEARCH";
export const setSearch = search => ({
  type: AUTOCOMPLETE_SEARCH,
  search
});

export const AUTOCOMPLETE_REQUEST = "AUTOCOMPLETE_REQUEST";
export const AUTOCOMPLETE_SUCCESS = "AUTOCOMPLETE_SUCCESS";
export const AUTOCOMPLETE_FAILURE = "AUTOCOMPLETE_FAILURE";
export const autocomplete = (model, search, fields, limit) => ({
  [CALL_SOCKET_API]: {
    types: [AUTOCOMPLETE_REQUEST, AUTOCOMPLETE_SUCCESS, AUTOCOMPLETE_FAILURE],
    endpoint: "read" + ucFirst(model),
    nonBlocking: true,
    data: {
      search,
      fields,
      limit
    }
  }
});

export const AUTOCOMPLETE_GET_REQUEST = "AUTOCOMPLETE_GET_REQUEST";
export const AUTOCOMPLETE_GET_SUCCESS = "AUTOCOMPLETE_GET_SUCCESS";
export const AUTOCOMPLETE_GET_FAILURE = "AUTOCOMPLETE_GET_FAILURE";
export const getSuggestedItem = (model, id, fields) => ({
  [CALL_SOCKET_API]: {
    types: [
      AUTOCOMPLETE_GET_REQUEST,
      AUTOCOMPLETE_GET_SUCCESS,
      AUTOCOMPLETE_GET_FAILURE
    ],
    endpoint: "read" + ucFirst(model),
    nonBlocking: true,
    data: {
      search: { _id: id },
      fields
    }
  }
});
