import React, { Component } from "react";
import PropTypes from "prop-types";

class CustomerSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
  };

  search = (event) => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const id = this.idField.value.trim(),
      company = this.companyField.value.trim(),
      email = this.emailField.value.trim(),
      status = this.statusField.value,
      params = {};
    if (id) {
      params._id = id;
    }
    if (company) {
      params.company = company;
    }
    if (email) {
      params.email = email;
    }
    if (status) {
      switch (status) {
        case "active":
          params.active = true;
          break;
        case "inactive":
          params.active = false;
          break;
        default:
          break;
      }
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.idField.value = "";
    this.companyField.value = "";
    this.emailField.value = "";
    this.statusField.value = "";
    this.props.onSearch({});
  };

  render = () => {
    const { search } = this.props,
      status =
        true === search.active
          ? "active"
          : false === search.active
          ? "inactive"
          : "";
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="four fields">
          <div className="field">
            <label htmlFor="id">ID</label>
            <input
              type="text"
              tabIndex="5"
              name="id"
              id="id"
              placeholder="Exact ID"
              autoComplete="off"
              defaultValue={search._id}
              ref={(input) => (this.idField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              tabIndex="10"
              name="company"
              id="company"
              placeholder="Company name"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.company}
              ref={(input) => (this.companyField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              tabIndex="20"
              name="email"
              id="email"
              placeholder="Email address"
              autoComplete="off"
              defaultValue={search.email}
              ref={(input) => (this.emailField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="30"
              defaultValue={status}
              ref={(input) => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["active", "inactive"].map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
              ;
            </select>
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default CustomerSearch;
