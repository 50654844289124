import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  formResetError,
  formSetError,
  showRefund,
  processRefund,
} from "../../actions";
import { roundTo } from "../../lib/utils";
import { standardText } from "../../lib/validations";
import config from "../../config";
import SimpleHeader from "../../components/SimpleHeader";

class RefundForm extends Component {
  static propTypes = {
    // Injected by React-Redux
    invoice: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    showRefund: PropTypes.func.isRequired,
    processRefund: PropTypes.func.isRequired,
  };

  submit = (event) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    const {
      invoice,
      showRefund,
      formSetError,
      formResetError,
      processRefund,
    } = this.props,
      amount = parseFloat(this.amountField.value.trim(), 10),
      reason = this.reasonField.value.trim();
    let hasError = false;
    formResetError();
    if (isNaN(amount) || amount < 0) {
      formSetError("amount");
      hasError = true;
    }
    if (!reason || !standardText(reason)) {
      formSetError("reason");
      hasError = true;
    }
    if (!hasError) {
      processRefund({
        invoice: invoice.data._id,
        to: 1 === showRefund ? "card" : "credit",
        amount,
        reason,
      });
    }
  };

  render = () => {
    const { invoice, form } = this.props,
      { data: invoiceData } = invoice;
    if (!invoiceData) {
      return null;
    }
    const order = invoiceData.order,
      dueDate = moment(invoiceData.dueDate),
      endDate = moment(dueDate).add(order.billingCycle, config.billingPeriod);
    const now = moment().utc(),
      daysUsed = now.diff(dueDate, "days"),
      daysTotal = endDate.diff(dueDate, "days"),
      daysRemaining = daysTotal - daysUsed,
      refundAmount =
        daysRemaining > 0 && daysTotal > 0
          ? roundTo(invoiceData.amount * (daysRemaining / daysTotal), 2)
          : 0;
    console.log("Days used:", daysUsed);
    console.log("Days total:", daysTotal);
    console.log("Days remaining:", daysRemaining);
    console.log("Refund amount:", refundAmount);
    return (
      <div className="ui segment">
        <SimpleHeader title="Refund" />
        <div className="ui">
          {form.hasError && (
            <div className="ui error message">
              <ul className="list">
                {form.errorFields.amount && (
                  <li>
                    Enter a valid amount greater than 0 and less than the
                    invoice total.
                  </li>
                )}
                {form.errorFields.reason && <li>Enter a valid reason</li>}
              </ul>
            </div>
          )}
          <form className="ui form" onSubmit={this.submit}>
            <div
              className={
                "required field" + (form.errorFields.amount ? " error" : "")
              }
            >
              <label htmlFor="amount">Amount (USD)</label>
              <input
                type="number"
                step=".01"
                tabIndex="10"
                name="amount"
                id="amount"
                placeholder="Refund amount"
                autoComplete="off"
                ref={(input) => (this.amountField = input)}
                defaultValue={refundAmount ? refundAmount : ""}
              />
            </div>
            <div
              className={
                "required field" + (form.errorFields.reason ? " error" : "")
              }
            >
              <label htmlFor="reason">Reason</label>
              <input
                type="text"
                tabIndex="20"
                name="reason"
                id="reason"
                placeholder="Refund reason"
                autoFocus={true}
                ref={(input) => (this.reasonField = input)}
              />
            </div>
            <button
              id="cancel"
              tabIndex="210"
              className="ui negative button"
              type="button"
              onClick={() => this.props.showRefund(0)}
            >
              Cancel
            </button>
            <button tabIndex="200" type="submit" className="ui primary button">
              Process refund
            </button>
          </form>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  invoice: state.invoice,
  form: state.ui.form,
});

export default connect(mapStateToProps, {
  formResetError,
  formSetError,
  showRefund,
  processRefund,
})(RefundForm);
