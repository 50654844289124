import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { autocomplete, getSuggestedItem } from "../../actions/ui/autocomplete";
import AutocompleteInput from "../AutocompleteInput";

class CouponSearch extends Component {
  static propTypes = {
    search: PropTypes.object.isRequired,
    onSearch: PropTypes.func.isRequired,
    // Injected by React-Redux
    autocomplete: PropTypes.func.isRequired,
    getSuggestedItem: PropTypes.func.isRequired
  };

  search = event => {
    event.preventDefault(); // this should be first line in order to prevent the form from being submitted if there is an error
    const description = this.descriptionField.value.trim(),
      percentage = this.percentageField.value.trim(),
      amount = this.amountField.value.trim(),
      newOnly = this.newOnlyField.value,
      recurring = this.recurringField.value,
      status = this.statusField.value,
      params = {};
    if (description) {
      params.description = description;
    }
    if (percentage) {
      params.percentage = percentage;
    }
    if (amount) {
      params.amount = amount;
    }
    if (newOnly) {
      switch (newOnly) {
        case "enabled":
          params.newOnly = true;
          break;
        case "disabled":
          params.newOnly = false;
          break;
        default:
          break;
      }
    }
    if (recurring) {
      switch (recurring) {
        case "enabled":
          params.recurring = true;
          break;
        case "disabled":
          params.recurring = false;
          break;
        default:
          break;
      }
    }
    if (status) {
      switch (status) {
        case "active":
          params.active = true;
          break;
        case "inactive":
          params.active = false;
          break;
        default:
          break;
      }
    }
    if (this.customer) {
      params.customer = this.customer;
    }
    this.props.onSearch(params);
    return false;
  };

  clear = () => {
    this.descriptionField.value = "";
    this.percentageField.value = "";
    this.amountField.value = "";
    this.newOnlyField.value = "";
    this.recurringField.value = "";
    this.statusField.value = "";
    this.props.onSearch({});
  };

  autocomplete = (search, count) => {
    this.props.autocomplete(
      "customer",
      { company: search, active: true },
      {},
      count
    );
  };

  render = () => {
    const { search, getSuggestedItem } = this.props,
      status =
        true === search.active
          ? "active"
          : false === search.active
            ? "inactive"
            : "";
    return (
      <form className="ui form" onSubmit={this.search}>
        <div className="six fields">
          <div className="field">
            <label htmlFor="description">Description</label>
            <input
              type="text"
              tabIndex="10"
              name="description"
              id="description"
              placeholder="Description"
              autoComplete="off"
              autoFocus={true}
              defaultValue={search.description}
              ref={input => (this.descriptionField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="percentage">Percent</label>
            <input
              type="number"
              tabIndex="20"
              name="percentage"
              id="percentage"
              placeholder="Percentage"
              autoComplete="off"
              defaultValue={search.percentage}
              ref={input => (this.percentageField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              tabIndex="30"
              name="amount"
              id="amount"
              placeholder="Amount"
              autoComplete="off"
              defaultValue={search.amount}
              ref={input => (this.amountField = input)}
            />
          </div>
          <div className="field">
            <label htmlFor="newOnly">New only</label>
            <select
              id="newOnly"
              name="newOnly"
              tabIndex="40"
              defaultValue={search.newOnly}
              ref={input => (this.newOnlyField = input)}
            >
              <option value="">Select</option>
              {["enabled", "disabled"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          <div className="field">
            <label htmlFor="recurring">Recurring</label>
            <select
              id="recurring"
              name="recurring"
              tabIndex="50"
              defaultValue={search.recurring}
              ref={input => (this.recurringField = input)}
            >
              <option value="">Select</option>
              {["enabled", "disabled"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          <div className="field">
            <label htmlFor="status">Status</label>
            <select
              id="status"
              name="status"
              tabIndex="60"
              defaultValue={status}
              ref={input => (this.statusField = input)}
            >
              <option value="">Select</option>
              {["active", "inactive"].map(item => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))};
            </select>
          </div>
          <div className="field">
            <AutocompleteInput
              title="Customer"
              count={3}
              tabIndex={70}
              getSuggestedItem={id => {
                getSuggestedItem("customer", id, { _id: 1, company: 1 });
              }}
              searchItem={this.autocomplete}
              required={true}
              getName={customer => customer.company}
              selectItem={id => {
                this.customer = id;
              }}
            />
          </div>
        </div>
        <button
          id="clear"
          tabIndex="110"
          className="ui negative button"
          type="button"
          onClick={this.clear}
        >
          Clear
        </button>
        <button
          id="search"
          tabIndex="100"
          className="ui primary button"
          type="submit"
        >
          Search
        </button>
      </form>
    );
  };
}

export default connect(
  null,
  {
    autocomplete,
    getSuggestedItem
  }
)(CouponSearch);
