import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import PrintProvider, { Print } from "react-easy-print";
import { readInvoice, downloadPdfInvoice, showRefund } from "../../actions";
import { roundTo } from "../../lib/utils";
import { ucFirst } from "../../lib/strings";
import config from "../../config";
import SimpleHeader from "../../components/SimpleHeader";
import RefundForm from "./RefundForm";

class InvoiceView extends Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Injected by React-Redux
    invoice: PropTypes.object.isRequired,
    userGroup: PropTypes.string.isRequired,
    readInvoice: PropTypes.func.isRequired,
    downloadPdfInvoice: PropTypes.func.isRequired,
    showRefund: PropTypes.func.isRequired
  };

  assetURL = config.apiUrl || "/";

  componentWillMount = () => {
    const { history, match, readInvoice, showRefund } = this.props;
    if (match.params.id) {
      readInvoice({
        search: { _id: match.params.id },
        populate: [
          {
            path: "order",
            select: { _id: 1, servers: 1, billingCycle: 1, price: 1 }
          },
          { path: "customer", select: { _id: 0, company: 1, address: 1 } }
        ]
      });
    } else {
      history.push("/invoice");
    }
    showRefund(false);
  };

  printInvoice = () => {
    if (this.printTimer) {
      clearTimeout(this.printTimer);
    }
    this.printTimer = setTimeout(() => {
      this.printTimer = null;
      window.print();
    }, 100);
  };

  downloadInvoice = () => {
    const { invoice, downloadPdfInvoice } = this.props;
    downloadPdfInvoice(invoice.data._id);
  };

  render = () => {
    const { history, invoice, userGroup } = this.props,
      { data: invoiceData, downloading, showRefund } = invoice;
    if (!invoiceData) {
      return null;
    }
    const order = invoiceData.order,
      customer = invoiceData.customer,
      dueDate = moment(invoiceData.dueDate),
      endDate = moment(dueDate).add(order.billingCycle, config.billingPeriod);
    return (
      <article className="thirteen wide column">
        <PrintProvider>
          <div className="ui segment">
            <Print name="invoicePrint">
              <div className="ui stackable grid">
                <div className="left floated eight wide column">
                  AdMod Technologies Pvt. Ltd.
                  <br />
                  Building #27/309
                  <br />
                  Maveli Nagar
                  <br />
                  CUSAT P O<br />
                  Kochi – 682022, India
                </div>
                <div className="right floated four wide column">
                  <img
                    src={this.assetURL + "assets/admod-logo.svg"}
                    alt="Admod logo"
                  />
                </div>
                <div className="right floated four wide column">
                  <img
                    src={this.assetURL + "assets/ezeelogin-logo.svg"}
                    alt="Ezeelogin logo"
                  />
                </div>
              </div>
              <SimpleHeader title="Invoice" />
              <div className="ui two column stackable grid">
                <div className="column">
                  <div className="ui segment">
                    <strong>To:</strong>
                    <br />
                    {customer.company}
                    <br />
                    {customer.address && (
                      <div>
                        {customer.address.line1}
                        <br />
                        {customer.address.line2}
                        <br />
                        {customer.address.city}, {customer.address.state} -{" "}
                        {customer.address.zip}
                        <br />
                        {customer.address.country}
                      </div>
                    )}
                  </div>
                </div>
                <div className="column">
                  <table className="ui basic table">
                    <tbody>
                      <tr>
                        <td className="right aligned">
                          <strong>Invoice #</strong>
                        </td>
                        <td>{invoiceData._id}</td>
                      </tr>
                      <tr>
                        <td className="right aligned">
                          <strong>Date</strong>
                        </td>
                        <td>
                          {moment(invoiceData.created).format("YYYY-MM-DD")}
                        </td>
                      </tr>
                      <tr>
                        <td className="right aligned">
                          <strong>Due date</strong>
                        </td>
                        <td>{dueDate.format("YYYY-MM-DD")}</td>
                      </tr>
                      <tr>
                        <td className="right aligned">
                          <strong>Period</strong>
                        </td>
                        <td>
                          {dueDate.format("YYYY-MM-DD")} to{" "}
                          {dueDate
                            .add(order.billingCycle, config.billingPeriod)
                            .format("YYYY-MM-DD")}
                        </td>
                      </tr>
                      <tr>
                        <td className="right aligned">
                          <strong>Status</strong>
                        </td>
                        <td>{ucFirst(invoiceData.status)}</td>
                      </tr>
                      {"paid" === invoiceData.status && (
                        <tr>
                          <td className="right aligned">
                            <strong>Paid on</strong>
                          </td>
                          <td>
                            {moment(invoiceData.paymentDate).format(
                              "YYYY-MM-DD"
                            )}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td className="right aligned">
                          <strong>P.O #</strong>
                        </td>
                        <td>{order._id}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table className="ui basic table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Duration</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ezeelogin license subscription</td>
                    <td>{order.price.perUnit} / month</td>
                    <td>{order.servers}</td>
                    <td>
                      {order.billingCycle}{" "}
                      {order.billingCycle > 1 ? "months" : "month"}
                    </td>
                    <td>{order.price.amount}</td>
                  </tr>
                  {!!order.price.termDiscount && (
                    <tr>
                      <td colSpan="3" />
                      <td>
                        <strong>Long term discount</strong>
                      </td>
                      <td>-{order.price.termDiscount}</td>
                    </tr>
                  )}
                  {!!order.price.couponDiscount && (
                    <tr>
                      <td colSpan="3" />
                      <td>
                        <strong>Discount</strong>
                      </td>
                      <td>-{order.price.couponDiscount}</td>
                    </tr>
                  )}
                  {"renew" !== invoiceData.type && !!order.price.deduction && (
                    <tr>
                      <td colSpan="3" />
                      <td>
                        <strong>Deductions</strong>
                      </td>
                      <td>
                        -
                        {order.price.deduction > order.price.total
                          ? order.price.total
                          : order.price.deduction}
                      </td>
                    </tr>
                  )}
                  {!!invoiceData.refundAmount && (
                    <tr>
                      <td colSpan="3" />
                      <td>
                        <strong>Refund</strong>
                      </td>
                      <td>-{invoiceData.refundAmount}</td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="3" />
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>
                        $
                        {invoiceData.refundAmount
                          ? roundTo(
                              invoiceData.amount - invoiceData.refundAmount,
                              2
                            )
                          : invoiceData.amount}{" "}
                        USD
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Print>
            <div className="ui hidden divider" />
            <button
              id="goBack"
              type="button"
              tabIndex="20"
              className="ui primary button"
              onClick={() => {
                history.push("/invoice");
              }}
            >
              Go back
            </button>
            <button
              id="download"
              type="button"
              tabIndex="30"
              className={"ui button" + (downloading ? " loading" : "")}
              onClick={this.downloadInvoice}
            >
              Download
            </button>
            <button
              id="print"
              type="button"
              tabIndex="10"
              className="ui positive button"
              onClick={this.printInvoice}
            >
              Print
            </button>
            {"unpaid" === invoiceData.status && "customer" === userGroup && (
              <button
                id="pay"
                type="button"
                tabIndex="5"
                className="ui positive button"
                onClick={() => {
                  history.push(`/invoice/pay/${invoiceData._id}`);
                }}
              >
                Pay
              </button>
            )}
            {"admin" === userGroup &&
              "paid" === invoiceData.status &&
              invoice.stripeChargeId &&
              !invoiceData.refundAmount &&
              !showRefund &&
              moment().isBefore(endDate) && (
                <button
                  id="refund"
                  type="button"
                  tabIndex="40"
                  className="ui negative button"
                  onClick={() => this.props.showRefund(1)}
                >
                  Refund to card
                </button>
              )}
            {"admin" === userGroup &&
              "paid" === invoiceData.status &&
              !invoiceData.refundAmount &&
              !showRefund &&
              moment().isBefore(endDate) && (
                <button
                  id="refund"
                  type="button"
                  tabIndex="50"
                  className="ui negative button"
                  onClick={() => this.props.showRefund(2)}
                >
                  Refund to credit
                </button>
              )}
            <div className="ui info message">
              <i className="flag blue icon" />
              Print works best in Chrome and Firefox.
            </div>
          </div>
        </PrintProvider>
        {showRefund && <RefundForm />}
      </article>
    );
  };
}

const mapStateToProps = state => ({
  invoice: state.invoice,
  userGroup: state.auth.user.group
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      readInvoice,
      downloadPdfInvoice,
      showRefund
    }
  )(InvoiceView)
);
