import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../../containers/Dashboard";
import LiveAgentListing from "../../containers/LiveAgentListing";
import AgentListing from "../../containers/AgentListing";
import AgentForm from "../../containers/AgentForm";
import Account from "../../containers/Account";
import CouponListing from "../../containers/CouponListing";
import CouponForm from "../../containers/CouponForm";
import CustomerListing from "../../containers/CustomerListing";
import CustomerForm from "../../containers/CustomerForm";
import LicenseListing from "../../containers/LicenseListing";
import LicenseForm from "../../containers/LicenseForm";
import OrderListing from "../../containers/OrderListing";
import OrderView from "../../containers/OrderView";
import PayInvoice from "../../containers/PayInvoice";
import InvoiceView from "../../containers/InvoiceView";
import InvoiceListing from "../../containers/InvoiceListing";
import PaymentListing from "../../containers/PaymentListing";
import ViewCard from "../../containers/ViewCard";
import AddCard from "../../containers/AddCard";
import CreditListing from "../../containers/CreditListing";
import CreditForm from "../../containers/CreditForm";
import PayCredit from "../../containers/PayCredit";
import SettingListing from "../../containers/SettingListing";
import SettingForm from "../../containers/SettingForm";
import DndListing from "../../containers/DndListing";
import DndForm from "../../containers/DndForm";
import BuildListing from "../../containers/BuildListing";
import BuildForm from "../../containers/BuildForm";
import BlacklistListing from "../../containers/BlacklistListing";
import BlacklistForm from "../../containers/BlacklistForm";
import NoMatch from "../NoMatch";
import Recovery from "../../containers/Recovery";
import StandaloneLicense from "../../containers/StandaloneLicense";

const Routes = ({ isAdmin, isStaff, isCustomer }) => {
  const isAnyStaff = isAdmin || isStaff;
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      {isAnyStaff && <Route exact path="/coupon/add" component={CouponForm} />}
      {isAnyStaff && <Route path="/coupon/edit/:id" component={CouponForm} />}
      {isAnyStaff && (
        <Route exact path="/coupon/:page?" component={CouponListing} />
      )}
      {isAnyStaff && <Route path="/customer/add" component={CustomerForm} />}
      {isAnyStaff && (
        <Route path="/customer/edit/:id" component={CustomerForm} />
      )}
      {isAnyStaff && (
        <Route exact path="/customer/:page?" component={CustomerListing} />
      )}
      {isAnyStaff && <Route path="/dnd/add" component={DndForm} />}
      {isAnyStaff && <Route exact path="/dnd/:page?" component={DndListing} />}
      {isAnyStaff && (
        <Route exact path="/liveagent/:page?" component={LiveAgentListing} />
      )}
      {isAnyStaff && (
        <Route
          exact
          path="/customer/license/:customer"
          component={LicenseListing}
        />
      )}
      <Route exact path="/license/add/:trial?" component={LicenseForm} />
      <Route exact path="/license/edit/:id" component={LicenseForm} />
      <Route exact path="/license/recovery/:id" component={Recovery} />
      <Route exact path="/license/:page?" component={LicenseListing} />
      <Route exact path="/order/view/:id" component={OrderView} />
      <Route exact path="/order/:page?" component={OrderListing} />
      {isCustomer && (
        <Route exact path="/invoice/pay/:id" component={PayInvoice} />
      )}
      <Route exact path="/invoice/view/:id" component={InvoiceView} />
      <Route exact path="/invoice/:page?" component={InvoiceListing} />
      {isCustomer && (
        <Route exact path="/payment/view/:id" component={ViewCard} />
      )}
      {isCustomer && <Route exact path="/payment/add" component={AddCard} />}
      {isCustomer && (
        <Route exact path="/payment/:page?" component={PaymentListing} />
      )}
      <Route exact path="/account" component={Account} />
      <Route exact path="/agent/add" component={AgentForm} />
      <Route path="/agent/edit/:id" component={AgentForm} />
      <Route exact path="/agent/:page?" component={AgentListing} />
      {isAdmin && <Route exact path="/credit/add" component={CreditForm} />}
      {isCustomer && <Route exact path="/credit/add" component={PayCredit} />}
      <Route exact path="/credit/:page?" component={CreditListing} />
      {isAdmin && <Route exact path="/build/add" component={BuildForm} />}
      {isAdmin && (
        <Route exact path="/build/license/:build" component={LicenseListing} />
      )}
      {isAdmin && <Route exact path="/build/:page?" component={BuildListing} />}
      {isAdmin && (
        <Route exact path="/blacklist/add" component={BlacklistForm} />
      )}
      {isAdmin && (
        <Route exact path="/blacklist/edit/:id" component={BlacklistForm} />
      )}
      {isAdmin && (
        <Route exact path="/blacklist/:page?" component={BlacklistListing} />
      )}
      {isAdmin && <Route exact path="/setting/add" component={SettingForm} />}
      {isAdmin && (
        <Route exact path="/setting/edit/:id" component={SettingForm} />
      )}
      {isAdmin && (
        <Route exact path="/setting/:page?" component={SettingListing} />
      )}
      {isAdmin && (
        <Route exact path="/standalone" component={StandaloneLicense} />
      )}
      <Route component={NoMatch} />
    </Switch>
  );
};

Routes.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isStaff: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired
};

export default Routes;
