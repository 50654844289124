import { CALL_SOCKET_API } from "../middleware/agent-api";

export const READ_PRIVACY_REQUEST = "READ_PRIVACY_REQUEST";
export const READ_PRIVACY_SUCCESS = "READ_PRIVACY_SUCCESS";
export const READ_PRIVACY_FAILURE = "READ_PRIVACY_FAILURE";
export const readPrivacy = () => ({
  [CALL_SOCKET_API]: {
    types: [READ_PRIVACY_REQUEST, READ_PRIVACY_SUCCESS, READ_PRIVACY_FAILURE],
    endpoint: "readPrivacy",
    nonBlocking: true
  }
});

export const SYNC_PRIVACY_REQUEST = "SYNC_PRIVACY_REQUEST";
export const SYNC_PRIVACY_SUCCESS = "SYNC_PRIVACY_SUCCESS";
export const SYNC_PRIVACY_FAILURE = "SYNC_PRIVACY_FAILURE";
export const syncPrivacy = data => ({
  [CALL_SOCKET_API]: {
    types: [SYNC_PRIVACY_REQUEST, SYNC_PRIVACY_SUCCESS, SYNC_PRIVACY_FAILURE],
    endpoint: "syncPrivacy",
    data,
    successMessage: "Updated"
  }
});
