const isCouponApplicable = (coupon, servers, billingCycle, type) => {
  const { minServers, maxServers, minTerm, maxTerm, newOnly } = coupon,
    satisfyMinServers = minServers ? minServers <= servers : true,
    satisfyMaxServers = maxServers ? maxServers >= servers : true,
    satisfyMinTerm = minTerm ? minTerm <= billingCycle : true,
    satisfyMaxTerm = maxTerm ? maxTerm >= billingCycle : true;
  return (
    satisfyMinServers &&
    satisfyMaxServers &&
    satisfyMinTerm &&
    satisfyMaxTerm &&
    (!newOnly || "new" === type)
  );
};

export default isCouponApplicable;
