import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { formToggleInfo } from "../../actions/ui/form";
import { roundTo } from "../../lib/utils";

const PriceInfo = ({ price, customer, form, formToggleInfo }) => {
  if (!price) {
    return null;
  }
  return (
    <table className="ui celled collapsing table">
      <thead>
        <tr>
          <th colSpan="2">Price info</th>
        </tr>
      </thead>
      <tbody>
        {!!(price.couponDiscount || price.termDiscount) && (
          <tr>
            <td>Amount</td>
            <td>${price.amount} USD</td>
          </tr>
        )}
        {!!price.termDiscount && (
          <tr>
            <td>Long term discount</td>
            <td>
              -$
              {price.termDiscount} USD ({price.termPercent}
              %)
            </td>
          </tr>
        )}
        {!!(price.termDiscount && price.couponDiscount) && (
          <tr>
            <td>Sub total</td>
            <td>${price.subTotal} USD</td>
          </tr>
        )}
        {!!price.couponDiscount && (
          <tr>
            <td>Coupon discount</td>
            <td>
              -$
              {price.couponDiscount} USD
              {price.couponPercent && (
                <span>
                  {" "}
                  ({price.couponPercent}
                  %)
                </span>
              )}
              {price.couponRecurring ? ", recurring" : ", one-time"}
            </td>
          </tr>
        )}
        <tr>
          <td>
            <strong>Grand total</strong>
          </td>
          <td>
            <strong>${price.total} USD</strong>
          </td>
        </tr>
        {!!price.deduction && (
          <tr>
            <td>Deductions</td>
            <td>
              -$
              {price.deduction} USD
            </td>
          </tr>
        )}
        <tr>
          <td>First invoice amount</td>
          <td>${price.firstBillAmount} USD</td>
        </tr>
        <tr>
          <td>First invoice due</td>
          <td>{price.firstBillDue}</td>
        </tr>
        {!!(price.firstBillAmount > 0 && customer && customer.credit) && (
          <tr>
            <td>Credit balance</td>
            <td>
              -$
              {customer.credit} USD
            </td>
          </tr>
        )}
        {!!(price.firstBillAmount > 0 && customer && customer.credit) && (
          <tr>
            <td>First payment</td>
            <td>
              $
              {price.firstBillAmount <= customer.credit
                ? 0
                : roundTo(price.firstBillAmount - customer.credit, 2)}{" "}
              USD
            </td>
          </tr>
        )}
        {!!price.credit && (
          <tr>
            <td>
              Credit
              <i
                className="info circle blue icon"
                title="Click for more info"
                onClick={() => formToggleInfo("credit")}
              />
              <div
                className={
                  (form.infoFields.credit ? "" : "hidden ") + "ui info message"
                }
              >
                The amount will be saved in your account and deducted from the
                future invoices.
              </div>
            </td>
            <td>${price.credit} USD</td>
          </tr>
        )}
        <tr>
          <td>Next invoice amount</td>
          <td>${price.recurringAmount} USD</td>
        </tr>
        <tr>
          <td>Next invoice due</td>
          <td>{price.nextBillDue}</td>
        </tr>
      </tbody>
    </table>
  );
};

PriceInfo.propTypes = {
  // Injected by React-Redux
  price: PropTypes.object,
  customer: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formToggleInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  price: state.license.price,
  customer: state.customer.data,
  form: state.ui.form
});

export default connect(
  mapStateToProps,
  {
    formToggleInfo
  }
)(PriceInfo);
