import { CALL_SOCKET_API } from "../middleware/agent-api";
import { ucFirst } from "../lib/strings";

export const ITEM_GET_REQUEST = "ITEM_GET_REQUEST";
export const ITEM_GET_SUCCESS = "ITEM_GET_SUCCESS";
export const ITEM_GET_FAILURE = "ITEM_GET_FAILURE";
export const getItem = (model, id) => ({
  [CALL_SOCKET_API]: {
    types: [ITEM_GET_REQUEST, ITEM_GET_SUCCESS, ITEM_GET_FAILURE],
    endpoint: "read" + ucFirst(model),
    nonBlocking: true,
    data: { search: { _id: id } }
  },
  model
});
