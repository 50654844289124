import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CardElement, injectStripe } from "react-stripe-elements";
import {
  resetStatusMessage,
  setStatusMessage,
  formResetError,
  formSetError,
  formResetInfo,
  formToggleInfo,
  createStripePaymentOption
} from "../../actions";
import { standardText } from "../../lib/validations";

class CardForm extends React.Component {
  static propTypes = {
    // Injected by React-Router
    history: PropTypes.object.isRequired,
    // Injected by React-Redux
    form: PropTypes.object.isRequired,
    resetStatusMessage: PropTypes.func.isRequired,
    setStatusMessage: PropTypes.func.isRequired,
    formResetError: PropTypes.func.isRequired,
    formSetError: PropTypes.func.isRequired,
    formResetInfo: PropTypes.func.isRequired,
    formToggleInfo: PropTypes.func.isRequired,
    createStripePaymentOption: PropTypes.func.isRequired
  };

  componentWillMount = () => {
    const { resetStatusMessage, formResetError, formResetInfo } = this.props;
    resetStatusMessage();
    formResetError();
    formResetInfo();
  };

  submit = event => {
    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault();

    const {
        stripe,
        createStripePaymentOption,
        setStatusMessage,
        formSetError
      } = this.props,
      card = { type: "card" },
      name = this.nameField.value.trim();
    let hasError = false;
    if (name) {
      if (!standardText(name)) {
        formSetError("name");
        hasError = true;
      } else {
        card.name = name;
      }
    }

    if (!hasError) {
      // Within the context of `Elements`, this call to createToken knows which Element to
      // tokenize, since there's only one in this group.
      stripe
        .createToken(card)
        .then(result => {
          console.log("Stripe result:", result);
          if (result.error) {
            formSetError("card", result.error.message);
          } else {
            createStripePaymentOption({
              token: result.token.id,
              default: this.defaultField ? this.defaultField.checked : false
            });
          }
        })
        .catch(error => {
          console.error(error);
          setStatusMessage(error.message, "error");
        });
    }
  };

  render = () => {
    const { history, form, formToggleInfo } = this.props;
    return (
      <div className="ui">
        {form.hasError && (
          <div className="ui error message">
            <ul className="list">
              {form.errorFields.card && <li>{form.errorFields.card}</li>}
              {form.errorFields.name && <li>Enter a valid name</li>}
            </ul>
          </div>
        )}
        <form className="ui form" onSubmit={this.submit}>
          <div className="field">
            <label>
              Credit or debit card{" "}
              <i
                className="info circle blue icon"
                title="Click for more info"
                onClick={() => formToggleInfo("cardCapture")}
              />
            </label>
            <div
              className={
                (form.infoFields.cardCapture ? "" : "hidden ") +
                "ui info message"
              }
            >
              <i className="lock icon" /> Your card details are captured
              directly from your web browser by our payment processor{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://stripe.com"
              >
                Stripe.com
              </a>
              . Your card details are not saved on our server; in fact they
              don't even pass through our server.
            </div>
            <CardElement
              style={{ base: { fontSize: "18px" } }}
              onReady={element => element.focus()}
            />
          </div>
          <div className="field">
            <label htmlFor="name">Name on card</label>
            <input
              type="text"
              tabIndex="10"
              name="name"
              id="name"
              placeholder="Name"
              autoComplete="off"
              ref={input => (this.nameField = input)}
            />
          </div>
          <div className="inline field">
            <div className="ui checkbox">
              <input
                id="default"
                type="checkbox"
                tabIndex="20"
                name="default"
                ref={input => (this.defaultField = input)}
                defaultChecked={true}
              />
              <label>
                Set as default{" "}
                <i
                  className="info circle blue icon"
                  title="Click for more info"
                  onClick={() => formToggleInfo("defaultCard")}
                />
              </label>
            </div>
          </div>
          <div
            className={
              (form.infoFields.defaultCard ? "" : "hidden ") + "ui info message"
            }
          >
            The default card shall be used for processing recurring payments.
          </div>
          <button
            id="cancel"
            tabIndex="110"
            className="ui negative button"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </button>
          <button
            id="add"
            tabIndex="100"
            className="ui primary button"
            type="submit"
          >
            Add
          </button>
        </form>
      </div>
    );
  };
}

const injectedCardForm = injectStripe(CardForm);

const mapStateToProps = state => ({
  form: state.ui.form
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      resetStatusMessage,
      setStatusMessage,
      formResetError,
      formSetError,
      formResetInfo,
      formToggleInfo,
      createStripePaymentOption
    }
  )(injectedCardForm)
);
